import React, {useEffect, useRef, useState} from 'react';
import {AreaChart, Area, XAxis, YAxis, Tooltip, CartesianAxis, CartesianGrid} from "recharts";
import {useStoreActions, useStoreState} from "easy-peasy";
import axios from 'axios';
import EvoshareSignupPopup from "../../../components/popups/evoshareSignupPopup";
import EvoshareResultPopup from "../../../components/popups/evoshareResultPopup";
import InfoPopup from "../../../components/popups/infoPopup";

export default function Evoshare({accentColor}){

	const chartHolderRef = useRef();
	const setUser = useStoreActions(actions=>actions.user.login);
	const user = useStoreState(state=>state.user.user);
	const activeOrganization = useStoreState(state=>state.user.activeOrganization);
	const [chartWidth,setChartWidth] = useState(0);
	const [chartVertLines,setChartVertLines] = useState([]);
	const setActiveOrganization = useStoreActions(actions=>actions.user.setActiveOrganization);
	const [showSignupPopup,setShowSignupPopup] = useState(false);
	const showMessage = useStoreActions(actions=>actions.alertMessage.setMessage);
	const [errorMessage,setErrorMessage] = useState(null);
	const [loginUrl,setLoginUrl] = useState('');
	const [showSuccessPopup,setShowSuccessPopup] = useState(false);
	const [registerError,setRegisterError] = useState('');
	const [chartData,setChartData] = useState({key:'',values:[]});
	const [updateKey,setUpdateKey] = useState(Math.random());
	const [lastDeposit,setLastDeposit] = useState(0);
	const [isLoading,setIsLoading] = useState(true);
	const evoshareDelayText = 'Once you enroll in Evoshare it may take up to 24 hours for this page to update.'
	const getChartWidth = () =>{
		let width = chartHolderRef.current?chartHolderRef.current.offsetWidth:0;
		setChartWidth(width);
		if(width>0){
			let linePoints = [];
			let measuredWidth = width-10;
			while (measuredWidth>(0) ){
				linePoints.push(measuredWidth);
				measuredWidth -= 20 + ((((10+width)%20))/((10+width)/20));
			}
			setChartVertLines(linePoints);
		}
	};
	const getEvoshareStats = (cancelToken) =>{
		axios.get('/organizations/' + activeOrganization.organization_id + '/evoshare/stats', {cancelToken: cancelToken.token}).then(response => {

			let deposits = [...response.data.cashbackHistory.data,...response.data.superchargesHistory.data,...response.data.familyCashbackHistory.data].map(entry=>{
				let date = entry.date ? new Date(entry.date.split(' ')[0]):'';
				return {name:date.toLocaleDateString('en-US', {timeZone: 'UTC'}),value:parseFloat(entry.amount),sortby:date.valueOf()};
			}).sort((a,b)=>{
				if(a.sortby>b.sortby) return 1;
				if(a.sortby<b.sortby) return -1;
				return 0;
			});
			if(deposits.length<=0){
				setIsLoading(false);
				chartData.values = [{name:'',value:0}];
				setChartData(chartData);
				setUpdateKey(Math.random());
				return;
			}
			setLastDeposit(deposits[deposits.length-1].value);
			let lastDate = deposits[0].name;
			let totalValue = deposits[0].value;
			let combinedDeposits = [deposits.shift()];

			deposits.forEach((entry,index)=>{
				if(entry.name !== lastDate){
					combinedDeposits[combinedDeposits.length-1].value = parseFloat(totalValue.toFixed(2));

					combinedDeposits.push(entry);
					lastDate = entry.name;
				}
				totalValue += entry.value;
				if(index===deposits.length-1){
					combinedDeposits[combinedDeposits.length-1].value = parseFloat(totalValue.toFixed(2));
				}

			});
			if(combinedDeposits.length<=0){
				combinedDeposits = [{name:'',value:0}];
			}
			chartData.values = combinedDeposits;
			setChartData(chartData);
			setUpdateKey(Math.random());
			setIsLoading(false);

		}).catch(e => {
			setIsLoading(false);
			setErrorMessage('Could not retrieve deposit information' + ((e.response && e.response.data && e.response.data.meta)?' - '+e.response.data.meta.message:'.'));
			setUpdateKey(Math.random());

		});
	};
	const updateUser = (cancelToken) => {
		axios.post('/auth/validate', {cancelToken:cancelToken.token}).then(response=>{
			setUser(response.data.user)
			let newActiveOrg = response.data.user.user_organization_roles.filter(role=>role.user_organization_role_id === activeOrganization.user_organization_role_id)[0]
			setActiveOrganization(newActiveOrg);
			if(newActiveOrg.user_organization_evoshare_token) {
				getEvoshareStats(cancelToken);
			}else{
				setIsLoading(false);
				chartData.values = [{name:'',value:0}];
				setChartData(chartData);
				setUpdateKey(Math.random());
			}

		}).catch(e=>{})
	};
	useEffect(()=>{
		const cancelToken = axios.CancelToken.source();
		if(activeOrganization.user_organization_evoshare_token) {
			getEvoshareStats(cancelToken);
		}else{
			updateUser(cancelToken);
		}

		getChartWidth();
		window.addEventListener('resize',getChartWidth);
		return()=>{
			cancelToken.cancel('');
			window.removeEventListener('resize',getChartWidth);
		}
	},[]);


	const registerHandler = ()=>{
		setRegisterError('');
		let dob =  user.profile_dob?new Date(user.profile_dob.replace(' ','T')):'';

		let registerUrl = 'https://evoshare.com/Dashboard/Account/RegisterProfile/thrive?fname='
			+ user.profile_first_name
			+ '&lname='+ user.profile_last_name
			+ '&email=' + user.user_email + '&dob='
			+ dob.toLocaleDateString('en-US',{year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/[^0-9\-.]/g,'')
			+'&zip=' + user.address_zip;
		window.open(registerUrl,'_blank');
		setShowSignupPopup(true);
	};

	const loginLink = (e)=>{
		e.preventDefault();
		if(loginUrl){
			window.open(loginUrl,'_blank');
			return;
		}
		axios.post('/organizations/'+activeOrganization.organization_id+'/evoshare/login-url').then(response=>{
			if(response.data.login_url) {
				window.open(response.data.login_url, '_blank');
				setLoginUrl(response.data.login_url)
			}else{
				showMessage({message:'Unable to get login link', type:'error'});
			}
		}).catch(e=>{});
	};

	return (
		<>
			{showSignupPopup && <InfoPopup closeHandler={()=>{setShowSignupPopup(false)}} info={evoshareDelayText} title={'Enrolling in Evoshare'} />}
		<div className={'padded-inner-page evoshare-dashboard'}>
			{activeOrganization.user_organization_evoshare_token &&
				<div className={'inner-card'}>
					<h3>You are Earning Cash Back!</h3>
					{isLoading &&
						<p>Retrieving deposits...</p>
					}

					{(!isLoading && errorMessage) &&
						<p>{errorMessage}</p>
					}

					{(!isLoading && !errorMessage) &&
						<p>
							Thrive and EvoShare have joined forces to offer you cash-back rewards that will be paid directly to your Student Loan, Emergency Savings Account or 529 College Savings Account from over 10,000+ businesses nationwide!
						</p>
					}
					<div className={'buttons'}>
						<a href={'#'} onClick={loginLink} target={'_blank'} rel={'noopener noreferrer'} className={'learn'}><span>Log in to Evoshare</span></a>
					</div>
				</div>
			}
			{!activeOrganization.user_organization_evoshare_token &&
				<div className={'inner-card'}>
					<h3>Start Earning Cash Back Today!</h3>
					<p>
						Thrive and EvoShare have joined forces to offer you cash-back rewards that will be paid directly to your Student Loan, Emergency Savings Account or 529 College Savings Account from over 10,000+ businesses nationwide!
					</p>
					<div className={'buttons'}>
						<button onClick={()=>{registerHandler()}}  className={'simple-rounded-button green enroll'}><span>Enroll in Evoshare</span></button>
						<a href={'https://evoshare.com/individuals/thrive'} target={'_blank'} rel={'noopener noreferrer'} className={'learn'}><span>Learn More</span></a>
					</div>

					<div className={'evoshare-features'}>
						<div className={'evoshare-feature-card'}>
							<img src={'/images/evoshare/signup.svg'} />
							<div className={'description'}>
								<h3>Sign Up</h3>
								<p>
									Link any of your debit or credit cards
								</p>
							</div>
						</div>
						<div className={'evoshare-feature-card'}>
							<img src={'/images/evoshare/cashback.svg'} />
							<div className={'description'}>
								<h3>Shop as usual</h3>
								<p>
									Online, locally, and at 700,000+ hotels through Evoshare Travel
								</p>
							</div>
						</div>
						<div className={'evoshare-feature-card'}>
							<img src={'/images/evoshare/shop.svg'} />
							<div className={'description'}>
								<h3>Get cash-back</h3>
								<p>
									Automatically towards your financial goal
								</p>
							</div>
						</div>
					</div>

					<div className={'evoshare-clients'}>
						<div className={'evoshare-client-holder small'}>
							<img src={'/images/evoshare/target%20logo@2x.png'} />
						</div>
						<div className={'evoshare-client-holder'}>
							<img src={'/images/evoshare/walmart-logos-lockupwtag-horiz-blu-rgb@2x.png'} />
						</div>
						<div className={'evoshare-client-holder small'}>
							<img src={'/images/evoshare/brass%20pro%20shop@2x.png'} />
						</div>
						<div className={'evoshare-client-holder'}>
							<img src={'/images/evoshare/Kohl\'s@2x.png'} />
						</div>
						<div className={'evoshare-client-holder small'}>
							<img src={'/images/evoshare/best buy@2x.png'} />
						</div>
						<div className={'evoshare-client-holder'}>
							<img src={'/images/evoshare/nordstrom@2x.png'} />
						</div>
					</div>

				</div>
			}

				<>
			<div className={'evoshare-amounts'}>
				<div>
					{isLoading &&
					<div className={'placeholder'}>
						<span></span>
					</div>
					}
					{!isLoading &&
					<>
						<span>Last Deposit</span>
						<h1>${lastDeposit || 0}</h1>
					</>
					}
				</div>
				<div>
					{isLoading &&
					<div className={'placeholder'}>
						<span></span>
					</div>
					}
					{!isLoading &&
					<>
						<span>Total saved all-time</span>
						<h1>${chartData.values.length>0?chartData.values[chartData.values.length-1].value:0}</h1>
					</>
					}
				</div>
			</div>
			<div className={'evoshare-chart'} ref={chartHolderRef}>
				<div className={'evoshare-wrapper'} style={{height: Math.max(chartWidth * .43, 250)}}>
					{chartData.values.length <= 0 &&
					<div className={'chart-placeholder placeholder-holder'}>
					</div>
					}
					{chartData.values.length > 0 &&
						<AreaChart
						width={chartWidth + 10}
						height={Math.max(chartWidth * .43, 250)}
						data={chartData.values}
						>
						<XAxis hide={true} dataKey={'name'}/>
						<CartesianGrid horizontal={false} verticalPoints={chartVertLines} strokeWidth={2}
						strokeOpacity={.2}/>
						<Area isAnimationActive={false} dataKey="value" stroke={'rgb(198,83,47)'} strokeOpacity={1}
						fill={'#FC825C67'} fillOpacity={.8} strokeWidth={6}/>
						<Tooltip/>
						</AreaChart>
					}
				</div>
				{chartData.values.length > 0 &&
					<>
					<span className={'evoshare-chart-date start'}>{chartData.values[0].name}</span>
					<span className={'evoshare-chart-date end'}>{chartData.values[chartData.values.length - 1].name}</span>
				</>
				}
			</div>
			</>

		</div>
		</>
	)
}

import React, {useEffect, useState} from 'react';
import axios from "axios";
import {useStoreActions} from "easy-peasy";
import {ADMIN_USER_TYPES, EMPLOYEE_TYPE, EMPLOYEE_TYPE_NO_EVOSHARE} from "../../_constants";
import PasswordField from "../auth/passwordField";

export default function AccountSetupSlide({user,errorHandler,submit,authToken, nextHandler, showContinueButton,continueAction, accountType}){

	const [password,setPassword] = useState('');
	const [confirmPassword,setConfirmPassword] = useState('');
	const setAlert = useStoreActions(actions=>actions.alertMessage.setMessage);
	const login = useStoreActions(actions=>actions.user.login);
	const setToken = useStoreActions(actions=>actions.user.updateToken);

	const submitOnEnter = (e) =>{
		if(e.keyCode === 13 &&  password.trim().length>0 && confirmPassword.trim().length>0){
			submitHandler();
		}
	}

	const submitHandler = () =>{
		if (user && !user.user_needs_password) {
			// setToken(authToken);
			nextHandler();
			return;
		}

		if(password !== confirmPassword){
			setAlert({message:'Passwords must match',type:'error'});
			errorHandler(false);
			return;
		}
		let postData = {user_password:password};
		axios.post('/account',{user:postData},{headers: {'Auth-token': authToken}}).then(response=>{
			if(!!response.data.user){
				// login(response.data.user);
				setToken(authToken);
				nextHandler();
			}else{
				setAlert({message:response.data.meta.message,type:'error'});
				errorHandler(false);
			}
		}).catch(e=>{})
	};

	useEffect(()=>{

		if(!submit){
			return;
		}
		submitHandler();
	},[submit,user]);

	useEffect(()=>{
		if (user && !user.user_needs_password) {
			// setToken(authToken);
			nextHandler();
		}
	},[user]);
	return (
		<div className={'slide welcome-slide'}>
			<div>
				<img className={'thrive-logo'} src={'/images/logo-no-text.svg'} alt={'thrive'} />
				<h2>Complete Account</h2>
				<span className={'details'}>
					{(accountType === EMPLOYEE_TYPE || accountType === EMPLOYEE_TYPE_NO_EVOSHARE) &&
						<>
							Create a password to access your Participant Activity Page at <a href={'http://go.thrivematching.com'} target={'_blank'} rel={'noreferrer noopener'}>Go.ThriveMatching.com</a>
						</>
					}
					{ADMIN_USER_TYPES.includes(accountType) &&
						<>
							Create a password to access your Employer Portal at <a href={'http://admin.thrivematching.com'} target={'_blank'} rel={'noreferrer noopener'}>Admin.ThriveMatching.com</a>
						</>
					}
				</span>
			</div>
			<div className={'small-form create-account'}>
			<label>
				Email
				<div className={'read-only'}>{user.user_email}</div>
			</label>
			<label>
				New Password
				<PasswordField keyupHandler={submitOnEnter} value={password} changeHandler={setPassword} />
			</label>
			<label>
				Confirm Password
				<PasswordField keyupHandler={submitOnEnter} value={confirmPassword} changeHandler={setConfirmPassword} />
			</label>

			</div>
			{showContinueButton &&
			<div style={{marginTop:'24px'}}>
				<button onClick={()=>{continueAction(true)}} className={'simple-rounded-button small fixed green'}><span>Confirm Password</span></button>
			</div>
			}
		</div>
	)

}

import React, {useEffect, useState} from 'react';
import Popup from "./popup";

export default function PatriotActDisclaimerPopup({closeHandler}){


	return (
		<Popup closeHandler={closeHandler} >
			<div className={'popup-content thrive-description'} >
				<h2><img src={'/images/thrive-contribution.svg'} alt={''} />Requirements</h2>
				<div>
					<p>
						All Merchant Applications must be fully completed, signed and guaranteed by greater than 50% shareholder(s), or an individual authorized to enter into a binding contract on behalf of the Corporation, LLC, 501(c)3 Non-Profit, LLP (Partnership), or Sole Proprietorship.
					</p>
				</div>
				<h2>Why do we require this information?</h2>
				<div>
					<p>
						Federal banking regulations and the Patriot Act require all financial institutions to verify and keep a record of information that identifies an individual or entity that opens an account, with a few exceptions (see below). As part of our Customer Identification Program to comply with these Federal regulations, we verify the identity of all authorized signers on new Merchant accounts for all types of business entities excluding the following:
					</p>
					<p>
						<strong>Exempt Organizations:</strong>  a. a financial institution regulated by a Federal functional regulator b. a bank regulated by a state bank regulator; c. governmental agencies and instrumentalities, d. companies that are publicly traded and only to the extent of their domestic operations; our standard identity verification procedures will apply for any foreign offices, affiliates, or subsidiaries of such entities.
					</p>
					<p>
						For all other types of business entities, we require identification from at least one individual person on behalf of the prospective merchant, including that person’s name, address, date of birth and a taxpayer identification number (social security number or other government-issued ID number). We may also ask to see additional documentation such as driver’s license or passport if needed. Please be aware that unless the business type falls within the exceptions listed above, we will not be able to process an application for a merchant account without identifying information from the person signing the application
					</p>
				</div>
			</div>
		</Popup>
	)
}

import React, {useEffect, useState} from 'react';
import Popup from "./popup";
import axios from 'axios';

export default function LoadingPopup({closeHandler,loadingMessage, responseMessage}){

	return (
		<Popup bodyClass={'evoshare-result'} closeHandler={!!responseMessage?closeHandler:null} >
			<div className={'popup-content evoshare-result-popup'} >
				<div className={'body'}>
				<h2>{!!responseMessage?(responseMessage.type==='error'?'Error':'Complete'):(loadingMessage || 'Connecting')}</h2>
				{!responseMessage && <img src={"/images/103.gif"} alt={'connecting'} />}
				{!!responseMessage && responseMessage.message}
				</div>
			</div>
		</Popup>
	)
}

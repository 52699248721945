import React, {useEffect, useState} from 'react';
import Popup from "./popup";
import axios from 'axios';

export default function WhatIsThrivePopup({closeHandler}){

	const [thriveDesc,setThriveDesc] = useState({});

	useEffect(()=>{
		const cancelToken = axios.CancelToken.source();
		axios.get('/settings/what-is-thrive',{cancelToken:cancelToken.token}).then(response=>{
			setThriveDesc(response.data.setting);
		});
		return ()=>{
			cancelToken.cancel('')
		}
	},[]);

	return (
		<Popup closeHandler={closeHandler} >
			<div className={'popup-content thrive-description'} >
				<h2><img src={'/images/thrive-contribution.svg'} alt={''} />What is Thrive Contribution?</h2>
				{thriveDesc.setting_value &&
					<div dangerouslySetInnerHTML={{__html: thriveDesc.setting_value}}/>
				}
			</div>
		</Popup>
	)
}

import React, {useEffect, useState} from 'react';
import SingleSelector from "../../components/singleSelector";
import {CSSTransition} from "react-transition-group";
import ReportsPlaceholder from "../../components/placeholders/reportsPlaceholder";
import axios from 'axios';
import {useStoreState} from "easy-peasy";
import NoResults from "../../components/noResults";

export default function Reports(){
    const activeOrganization = useStoreState(state=>state.user.activeOrganization);
    const [notSignedUp,setNotSignedUp] = useState([]);
    const [waiting,setWaiting] = useState([]);
    const [gettingPaid,setGettingPaid] = useState([]);
    const [totalEmployees, setTotalEmployees] = useState(0);
    const [mostRowsInColumn,setMostRowsInColumn] = useState(7);
    const [selectedMonth,setSelectedMonth] = useState(new Date().getMonth());
    const [selectedYear,setSelectedYear] = useState(new Date().getFullYear());
    const rowHeight = 44;
    const months = [
        {label:'January',value:0, selected:new Date().getMonth() === 0},
        {label:'February',value:1, selected:new Date().getMonth() === 1},
        {label:'March',value:2, selected:new Date().getMonth() === 2},
        {label:'April',value:3, selected:new Date().getMonth() === 3},
        {label:'May',value:4, selected:new Date().getMonth() === 4},
        {label:'June',value:5, selected:new Date().getMonth() === 5},
        {label:'July',value:6, selected:new Date().getMonth() === 6},
        {label:'August',value:7, selected:new Date().getMonth() === 7},
        {label:'September',value:8, selected:new Date().getMonth() === 8},
        {label:'October',value:9, selected:new Date().getMonth() === 9},
        {label:'November',value:10, selected:new Date().getMonth() === 10},
        {label:'December',value:11, selected:new Date().getMonth() === 11}
        ];

    const getYears = () =>{
        let years = [];
        const curYear = new Date().getFullYear();
        for(let i=0;i<10;i++){
            years.push({label:curYear - i,value:curYear - i, selected:new Date().getFullYear()===curYear - i});
        }
        return years;
    };

    const [loading,setLoading] = useState(true);

    useEffect(()=>{
        axios.get('/organizations/'+activeOrganization.organization_id+'/reports').then(response=>{

            setNotSignedUp(response.data.not_signed_up);
            setWaiting(response.data.waiting);
            setGettingPaid(response.data.with_payments);
            setTotalEmployees(response.data.not_signed_up.length + response.data.waiting.length + response.data.with_payments.length);
            setMostRowsInColumn(Math.min(25,Math.max(response.data.not_signed_up.length, response.data.waiting.length, response.data.with_payments.length)))
            setLoading(false);
        }).catch(e=>{});
    },[]);

    useEffect(()=>{
        setLoading(true);
        let endDate = Math.floor(new Date(selectedYear,selectedMonth + 1 ).getTime() / 1000);
        let startDate = Math.floor(new Date(selectedYear,selectedMonth ).getTime() / 1000);
        axios.get('/organizations/'+activeOrganization.organization_id+'/reports?start_time='+startDate+'&end_time='+endDate).then(response=>{

            setNotSignedUp(response.data.not_signed_up);
            setWaiting(response.data.waiting);
            setGettingPaid(response.data.with_payments);
            setTotalEmployees(response.data.not_signed_up.length + response.data.waiting.length + response.data.with_payments.length);
            setMostRowsInColumn(Math.min(25,Math.max(response.data.not_signed_up.length, response.data.waiting.length, response.data.with_payments.length)))
            setLoading(false);
        }).catch(e=>{});

    },[selectedMonth,selectedYear]);

    const getPercent = (count) =>{
        return Math.floor((count / totalEmployees) * 100) + '%';
    };

    return (
        <div className={'full-width page'}>
            <div className={'header'}>
                <div>
                    <h1 className={'heading'} >
                        Reports
                    </h1>
                </div>
                <div className={'button-holder'}>
                    <SingleSelector options={months} changeHandler={setSelectedMonth} simple={true} />
                    <SingleSelector options={getYears()} changeHandler={setSelectedYear} simple={true}/>
                </div>
            </div>
            <div className={'list-section'}>
                <div className={'body'}>

                    <CSSTransition
                        in={loading}
                        timeout={{
                            appear: 500,
                            enter: 300,
                            exit: 0,
                        }}
                        classNames="fade"
                        unmountOnExit
                    >
                        <ReportsPlaceholder />
                    </CSSTransition>
                    <CSSTransition
                        in={!loading && totalEmployees>0}
                        timeout={{
                            enter: 300,
                            exit: 0,
                        }}
                        classNames="fade"
                        unmountOnExit
                    >

                        <div className={'report-holder'}>
                            <div className={'report-column'}>
                                <h5>{getPercent(notSignedUp.length)} ({notSignedUp.length}) have not signed up</h5>
                                <div style={{height: (mostRowsInColumn + 1) * rowHeight}}>
                                    {
                                        notSignedUp.map(user=>
                                            <span key={user.user_id}>{user.display_name}</span>
                                        )
                                    }
                                </div>
                            </div>
                            <div className={'report-column'}>
                                <h5>{getPercent(waiting.length)} ({waiting.length}) Are waiting for their first payment</h5>
                                <div style={{height: (mostRowsInColumn + 1) * rowHeight}}>
                                    {
                                        waiting.map(user=>
                                            <span key={user.user_id}>{user.display_name}</span>
                                        )
                                    }
                                </div>
                            </div>
                            <div className={'report-column'}>
                                <h5>{getPercent(gettingPaid.length)} ({gettingPaid.length}) are receiving payments!</h5>
                                <div style={{height: (mostRowsInColumn + 1) * rowHeight}}>
                                    {
                                        gettingPaid.map(user=>
                                            <span key={user.user_id}>{user.display_name}</span>
                                        )
                                    }
                                </div>
                            </div>
                        </div>

                    </CSSTransition>
                    <CSSTransition
                        in={!loading && totalEmployees<=0}
                        timeout={{
                            enter: 300,
                            exit: 0,
                        }}
                        classNames="fade"
                        unmountOnExit
                    >
                        <NoResults />
                    </CSSTransition>
                </div>
            </div>
        </div>
    )
}

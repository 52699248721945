import React, {useEffect, useState} from 'react';
import {Colors} from "../../_constants";
import {useStoreActions, useStoreState} from "easy-peasy";
import axios from 'axios';
import PasswordField from "../../components/auth/passwordField";
export default function AccountInfo(){

    const userInfo = useStoreState(state=>state.user);
    const showMessage = useStoreActions(actions=>actions.alertMessage.setMessage)
    const [firstName,setFirstName] = useState('');
    const [lastName,setLastName] = useState('');
    const [email,setEmail] = useState('');
    const [newPassword,setNewPassword] = useState('');
    const [confirmPassword,setConfirmPassword] = useState('');
    const setUser = useStoreActions(actions=>actions.user.login)


    const getUpdatedUserInfo = () =>{
        axios.post('/auth/validate').then(response=> {
            setUser(response.data.user)
        }).catch(e=>{});
    }

    const updateContactInfo = () =>{
      let postData = {
          user:{
              user_email:email.trim(),
          }
      };
      axios.post('/account',postData).then(response=>{
          showMessage({message:'Account info updated'});
          getUpdatedUserInfo();
      }).catch(e=>{});
    };

    const updatePassword = () =>{
        if(newPassword !== confirmPassword){
            showMessage({message:'Passwords do not match',type:'error'});
            return;
        }

        let postData = {
            user:{
                user_password:newPassword
            }
        };
        axios.post('/account',postData).then(response=>{
            showMessage({message:'Password updated'});
        }).catch(e=>{});
    };

    useEffect(()=>{
        setFirstName(userInfo.displayName.split(' ')[0] || '');
        setLastName(userInfo.displayName.split(' ')[1] || '');
        setEmail(userInfo.email);
    },[userInfo]);

    useEffect(()=>{
        getUpdatedUserInfo();
    },[]);

    return (
        <div className={'full-width page'}>
            <div className={'header'}>
                <div>
                    <h1 className={'heading'} >
                        Account Information
                    </h1>
                </div>
            </div>
            <div className={'list-section'}>
                <div className={'body'}>
                    <div className={'sub-header'}>
                        <div>
                            <h2 className={'heading'} >
                                Account
                            </h2>
                        </div>
                    </div>
                    <div className={'popup-form'}>
                        <label>
                            First Name
                            <input
                                disabled={true}
                                value={firstName}
                                onChange={(e) => {
                                    setFirstName(e.target.value);
                                }}/>
                        </label>
                        <label>
                            Last Name
                            <input
                                disabled={true}
                                value={lastName}
                                onChange={(e) => {
                                    setLastName(e.target.value);
                                }}/>
                        </label>
                        <label >
                            Email
                            <input
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}/>
                        </label>
                        <div className={'save-row'}>
                            <button style={{backgroundColor:Colors.blue,color:'#FFF'}} className={'simple-rounded-button'} onClick={updateContactInfo}><span>Save Information</span></button>
                        </div>
                    </div>
                    <div className={'sub-header'}>
                        <div>
                            <h2 className={'heading'} >
                                Password
                            </h2>
                            <p>
                                Password must be at least 6 characters & include one uppercase letter, number & special character
                            </p>
                        </div>
                    </div>
                        <div className={'popup-form invite'}>
                            <label>
                                New Password
                                <PasswordField value={newPassword} changeHandler={setNewPassword} />
                            </label>
                            <label >
                                Confirm New Password
                                <PasswordField value={confirmPassword} changeHandler={setConfirmPassword} />
                            </label>
                            <div className={'save-row'}>
                                <button style={{backgroundColor:Colors.blue,color:'#FFF'}} className={'simple-rounded-button'} onClick={updatePassword}><span>Update Password</span></button>
                            </div>
                        </div>
            </div>
        </div>
        </div>
    )
}

import React, {useEffect, useState} from 'react';
import Popup from "./popup";
export default function ManualLoanOptionPopup({closeHandler})
{

	return (
		<Popup closeHandler={closeHandler} bodyClass={'register-accounts'}>
			<div className={'popup-content register-account-screen manual-loan-message'}>
				<span>It Looks like you are having trouble connecting an account</span>
				<span>Have an account that isn't listed? <a href={'#'} onClick={e => {
					e.preventDefault();
					closeHandler(true)
				}}>Add it manually.</a> </span>
			</div>
		</Popup>
	)
}

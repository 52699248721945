import React, {useEffect, useState} from 'react';
import Popup from "./popup";

export default function CipExemptionInfoPopup({closeHandler}){


	return (
		<Popup closeHandler={closeHandler} >
			<div className={'popup-content thrive-description'} >
				<h2><img src={'/images/thrive-contribution.svg'} alt={''} />Exempt Organizations:</h2>
				<div>
					<p>
						a. a financial institution regulated by a Federal functional regulator <br/>
						b. a bank regulated by a state bank regulator; <br/>
						c. governmental agencies and instrumentalities, <br/>
						d. companies that are publicly traded and only to the extent of their domestic operations; <br/><br/>

						our standard identity verification procedures will apply for any foreign offices, affiliates, or subsidiaries of such entities.
					</p>
				</div>

			</div>
		</Popup>
	)
}

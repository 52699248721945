import React, {useEffect, useState} from 'react';
import NumberFormat from "react-number-format";
import {useStoreActions} from "easy-peasy";
import axios from 'axios';

export default function EvoshareSignup({nextHandler,user,organization,authToken}){

	const [birthday,setBirthday] = useState('');
	const showMessage = useStoreActions(actions=>actions.alertMessage.setMessage);
	const [loginUrl,setLoginUrl] = useState('');

	const registerHandler = ()=>{
		let dob =  user.profile_dob?new Date(user.profile_dob.replace(' ','T')):'';

		let registerUrl = 'https://evoshare.com/Dashboard/Account/RegisterProfile/thrive?fname='
			+ user.profile_first_name
			+ '&lname='+ user.profile_last_name
			+ '&email=' + user.user_email + '&dob='
			+ dob.toLocaleDateString('en-US',{year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/[^0-9\-.]/g,'')
			+'&zip=' + user.address_zip;
		window.open(registerUrl,'_blank');
		nextHandler();
	};


	const loginLink = (e)=>{
		e.preventDefault();
		if(loginUrl){
			window.open(loginUrl,'_blank');
			return;
		}
		axios.post('/organizations/'+organization.organization_id+'/evoshare/login-url',null,{headers: {'Auth-token': authToken}}).then(response=>{
			if(response.data.login_url) {
				window.open(response.data.login_url, '_blank');
				setLoginUrl(response.data.login_url)
			}else{
				showMessage({message:'Unable to get login link', type:'error'});
			}
		}).catch(e=>{});
	};

	return (
		<>
				<div className={'slide evoshare-signup'}>
					<div className={' evoshare-signup-slide evoshare-dashboard'}>
						<div className={'title'}>
							<h3>Sign Up Now For</h3>
							<img src={'/images/once_coin_animation.gif'} alt={'evoshare'} />
						</div>
						<h3>Start Earning Cash Back Today!</h3>
						<p>
							Thrive and EvoShare have joined forces to offer you cash-back rewards that will be paid directly to your Student Loan, Emergency Savings Account and/or 529 College Savings Account from over 10,000+ businesses nationwide!
						</p>
						<div className={'buttons'}>
							<button onClick={()=>{registerHandler()}}  className={'simple-rounded-button green enroll'}><span>Enroll in Evoshare</span></button>
							<button onClick={()=>{nextHandler()}}  className={'simple-rounded-button skip'}><span>Skip for Now</span></button>
							<a href={'https://evoshare.com/individuals/thrive'} target={'_blank'} rel={'noopener noreferrer'} className={'learn'}><span>Learn More</span></a>
						</div>

						<div className={'evoshare-features'}>
							<div className={'evoshare-feature-card'}>
								<img src={'/images/evoshare/signup.svg'} />
								<div className={'description'}>
									<h3>Sign Up</h3>
									<p>
										Link any of your debit or credit cards
									</p>
								</div>
							</div>
							<div className={'evoshare-feature-card'}>
								<img src={'/images/evoshare/cashback.svg'} />
								<div className={'description'}>
									<h3>Shop as usual</h3>
									<p>
										Online, locally, and at 700,000+ hotels through Evoshare Travel
									</p>
								</div>
							</div>
							<div className={'evoshare-feature-card'}>
								<img src={'/images/evoshare/shop.svg'} />
								<div className={'description'}>
									<h3>Get cash-back</h3>
									<p>
										Automatically towards your financial goal
									</p>
								</div>
							</div>
						</div>

						<div className={'evoshare-clients'}>
							<div className={'evoshare-client-holder small'}>
								<img src={'/images/evoshare/target%20logo@2x.png'} />
							</div>
							<div className={'evoshare-client-holder'}>
								<img src={'/images/evoshare/walmart-logos-lockupwtag-horiz-blu-rgb@2x.png'} />
							</div>
							<div className={'evoshare-client-holder small'}>
								<img src={'/images/evoshare/brass%20pro%20shop@2x.png'} />
							</div>
							<div className={'evoshare-client-holder'}>
								<img src={'/images/evoshare/Kohl\'s@2x.png'} />
							</div>
							<div className={'evoshare-client-holder small'}>
								<img src={'/images/evoshare/best buy@2x.png'} />
							</div>
							<div className={'evoshare-client-holder'}>
								<img src={'/images/evoshare/nordstrom@2x.png'} />
							</div>
						</div>

						{organization &&
							<div className={'contribution-disclosure evoshare in-slide'}>
								<p>
									Already Have an Account? <a onClick={(e)=>{loginLink(e)}} href={'#'} target={'_blank'} rel={'noopener noreferrer'}>Log In</a>
								</p>
							</div>
						}
					</div>
				</div>
		</>
	)
}

import React, {useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import Popup from "../popups/popup";

export default function SubNav({menu,baseUrl}){

    const location = useLocation();
    const [showPopup,setShowPopup] = useState(false);
    const openPopup = (e) =>{
        if(location.state.layout==='mobileMax') {
            e.preventDefault();
            setShowPopup(true)
        }
    };
    const closePopup = () =>{
        setShowPopup(false);
    };

    return (
        <>
            {showPopup &&
                    <Popup closeHandler={closePopup} simple={true} >
                        <div className={'mobile-subnav-holder'}>
                        {
                            menu.map((item,index)=>(
                                    <Link
                                        className={item.classN + ' button ' + (location.pathname.indexOf(item.to)>=0?'active':'')}
                                        key={index}
                                        onClick={()=>{setShowPopup(false)}}
                                        to={baseUrl + item.to}>
                                        <span className={'mdi mdi-'+item.icon} />{item.title}
                                    </Link>
                                )
                            )
                        }
                        </div>
                    </Popup>
            }
            <div className={'sub-nav full-width'}>
                <div className={'sub-nav-holder desktop-nav'}>
                    {
                        menu.map((item,index)=>(
                            <Link
                                style={{minWidth:'calc((100% / '+menu.length+') - 20px)'}}
                                className={item.classN + ' button ' + (location.pathname.indexOf(item.to)>=0?'active':'')}
                                key={index}
                                onClick={(e)=>{openPopup(e)}}
                                to={baseUrl + item.to}>
                                <span className={'mdi mdi-'+item.icon} />{item.title}
                            </Link>
                            )
                        )
                    }
                </div>
            </div>
        </>
    )

}

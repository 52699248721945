import React, {useEffect, useState} from 'react';
import Popup from "./popup";
import axios from 'axios';
import {getHttpErrorMessage} from "../../_helpers";
import NumberFormat from "react-number-format";
export default function SavingsMicroDepositsPopup({sucessHandler,closeHandler,authToken, organization, user, accountId})
{
	const [updateToken,setUpdateToken] = useState(null);
	const [depositOne,setDepositOne] = useState('');
	const [depositTwo,setDepositTwo] = useState('');
	const [errorFields,setErrorFields] = useState([]);
	const [message,setMessage] = useState('');


	const hasErrors = () =>{
		let errors = [];

		if(depositOne.trim().length<=0){
			errors.push('depositOne');
			setMessage('Both Deposits Required.');
		}

		if(depositTwo.trim().length<=0){
			errors.push('depositTwo');
			setMessage('Both Deposits Required.');
		}

		setErrorFields(errors);
		return errors.length>0;
	};

	const saveHandler = () =>{
		if(hasErrors()){
			return;
		}
		let postData = {
			account: {
				account_id:accountId,
				deposit_one:depositOne,
				deposit_two:depositTwo
			}
		};
		let config = {};
		if(authToken){
			config.headers = {'Auth-token': authToken}
		}
		axios.post('/organizations/'+organization['organization_id']+'/users/'+user['user_id']+'/verify-savings',postData, config).then(response=>{
			sucessHandler(response);
		}).catch(e=>{
			setMessage(getHttpErrorMessage(e));
		});
	};

	return (
		<Popup closeHandler={closeHandler}>
			<div className={'large-form popup-content form-slide'}>
				<div className={'title'}>
					<h2><img className={'thrive-logo'} src={'/images/thrive-contribution.svg'} alt={'thrive'} />Verify Your micro-deposits</h2>
				</div>
				<div className={'form-container no-border'}>

					<div className={'form-inner field-list'}>

						<label className={'full-row'}>
							Confirm 1st Deposit:
							<NumberFormat
								className={(errorFields.includes('depositOne') ? 'invalid' : '')}
								placeholder={'$0.00'}
								decimalScale={2}
								prefix={'$'}
								thousandSeparator={false}
								value={depositOne}
								onValueChange={(values) => {
									const {value} = values;
									setDepositOne(value);

								}} />
						</label>
						<label className={'full-row'}>
							Confirm 2nd Deposit:
							<NumberFormat
								className={(errorFields.includes('depositTwo') ? 'invalid' : '')}
								placeholder={'$0.00'}
								decimalScale={2}
								prefix={'$'}
								thousandSeparator={false}
								value={depositTwo}
								onValueChange={(values) => {
									const {value} = values;
									setDepositTwo(value);

								}} />
						</label>

						<div className={'save-row full-row'}>
							<button  className={'simple-rounded-button blue'} onClick={(e)=>{saveHandler()}}><span>Verify Account</span></button>
							{message && <p className={'popup-form-error'}>{message}</p>}
						</div>
					</div>
				</div>
			</div>
		</Popup>
	)
}

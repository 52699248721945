import Popup from "./popup";
import React, {useEffect, useState} from "react";
import {useStoreActions, useStoreState} from "easy-peasy";
import axios from "axios";
import NumberFormat from "react-number-format";
import {ownerTitleOptions} from "../../_constants";


export default function OwnerInformationPopup({closeHandler}) {

	const showMessage = useStoreActions(actions=>actions.alertMessage.setMessage);
	const [updateKey,setUpdateKey] = useState(Math.random);
	const activeOrganization = useStoreState(state=>state.user.activeOrganization);
	const [formOptions,setFormOptions] = useState({});
	const [ownerInfo,setOwnerInfo] = useState({
			contact_first_name:{label:'First Name',value:''},
			contact_last_name:{label:'Last Name',value:''},
			organization_owner_title:{label:'Title',value:'', optionsKey:'owner_titles'},
			address_street_1:{label:'Address',value:''},
			address_city:{label:'City',value:''},
			state_id:{label:'State',value:'', optionsKey:'states'},
			address_zip:{label:'Postal Code',value:''},
			country_id:{label:'Country',value:'',optionsKey:'countries'},
			citizenship_type_id:{label:'Citizenship',value:'',optionsKey:'citizenship_types'},
			contact_email:{label:'Email Address',value:''},
			contact_phone:{label:'Mobile Phone',value:''},
			organization_owner_ssn:{label:'SSN (Last 4 digits)',value:'',type:'number',format:'####',mask:'#'},
			organization_owner_dob:{label:'Date of Birth',value:'',type:'number',format:'##/##/####',mask:['M','m','D','d','Y','e','a','r']},
			organization_owner_percentage:{label:'Percentage',value:'',type:'number',suffix:'%'}
		}
	);

	const updateOwnerInfo = (key,value) =>{
		ownerInfo[key].value = value;
		setOwnerInfo(ownerInfo);
		setUpdateKey(Math.random())
	};

	const mapFormForPost = (formData) =>{
		let data = {};
		Object.entries(formData).forEach((entry)=>{
			data[entry[0]] = entry[1].value;
		});
		return data;
	};

	const submitHandler = () =>{
		let missingFields = false;
		const dobRegex = /(((0[1-9]|1[0-2])\/(0|1)[0-9]|2[0-9]|3[0-1])\/((19|20)\d\d))$/;
		Object.entries(ownerInfo).forEach((entry)=>{
			if(!entry[1].value){
				ownerInfo[entry[0]].error = true;
				missingFields = true;
			}else if(entry[0].indexOf('dob')>-1){
				if(!dobRegex.test(entry[1].value)){
					ownerInfo[entry[0]].error = true;
					missingFields = true;
				}else{
					ownerInfo[entry[0]].error = false;
				}
			}else{
				ownerInfo[entry[0]].error = false;
			}
		});
		setOwnerInfo(ownerInfo);
		setUpdateKey(Math.random());
		if(missingFields) {
			showMessage({message: 'All Fields are Required.', type: 'error'})
			return;
		}

		let postData = {
			owner:mapFormForPost(ownerInfo)
		};

		axios.post('/organizations/'+activeOrganization.organization_id+'/owner-billing',postData).then(response=>{
		}).catch(e=>{})
	};

	const remapFormOptions = (options) =>{
		return options.map((option)=>{
			let labelKey = '';
			let valueKey = '';
			for(const propKey in option){
				if(propKey.indexOf('_title')>-1 || propKey.indexOf('_name')>-1){
					labelKey = propKey;
				}else if(propKey.indexOf('_id')>-1){
					valueKey = propKey;
				}
			}
			option.label = option[labelKey];
			option.value = option[valueKey];
			return option;
		})
	};

	useEffect(()=>{
		const cancelToken = axios.CancelToken.source();
		axios.get('/form-options',{cancelToken:cancelToken.token}).then(response=>{
			let businessTypes = remapFormOptions(response.data.business_types);
			let bankTypes = remapFormOptions(response.data.bank_account_types);
			let citizenshipTypes = remapFormOptions(response.data.countries);
			let countries = remapFormOptions(response.data.countries);
			let states = remapFormOptions(response.data.states);
			let legalStructures = remapFormOptions(response.data.business_legal_structure_types);
			setFormOptions({
				business_types : businessTypes,
				bank_account_types:bankTypes,
				citizenship_types:citizenshipTypes,
				countries:countries,
				states:states,
				business_legal_structure_types:legalStructures,
				owner_titles:ownerTitleOptions
			});
		}).catch(e=>{});

		axios.get('/organizations/'+activeOrganization.organization_id+'/owner', {cancelToken:cancelToken.token}).then(response=>{
			if(response.data.owner){
				Object.entries(response.data.owner).forEach((entry)=>{
					if(ownerInfo[entry[0]]) {
						ownerInfo[entry[0]].value = entry[1];
					}
				});
				setOwnerInfo(ownerInfo);
				setUpdateKey(Math.random());
			}
		}).catch(e=>{});

		return ()=>{
			cancelToken.cancel('');
		}
	},[]);

	return (
		<Popup closeHandler={closeHandler} tabbed={false}>
			<div className={'large-form popup-content form-slide'}>
				<div className={'title'}>
					<h2><img className={'thrive-logo'} src={'/images/thrive-contribution.svg'} alt={'thrive'} />Company Information</h2>
				</div>
				<div className={'form-container no-border'}>

					<div className={'form-inner field-list'}>
						{Object.entries(ownerInfo).map((entry,index)=>{
							if(entry[1].optionsKey){
								return (
									<label key={'ow'+index}>
										{entry[1].label}
										<select className={(entry[1].value?'filled ':' ') + (entry[1].error?'invalid':'')} value={entry[1].value} onChange={(e)=>{updateOwnerInfo(entry[0],e.target.value)}}>
											<option value={''}>Select {entry[1].label}</option>
											{formOptions[entry[1].optionsKey] && formOptions[entry[1].optionsKey].map((option,index)=>(
												<option  style={{color:'#000'}} key={option.value+'-'+index} value={option.value}>{option.label}</option>
											))}
										</select>
									</label>
								)
							}else if(entry[1].type && entry[1].type==='number'){
								return (
									<label key={'ow'+index}>
										{entry[1].label}
										<NumberFormat
											className={(entry[1].error?'invalid':'')}
											placeholder={entry[1].label}
											format={entry[1].format}
											mask={entry[1].mask}
											decimalScale={entry[1].decimals || 0}
											prefix={entry[1].prefix || ''}
											suffix={entry[1].suffix || ''}
											value={entry[1].value}
											onValueChange={(values) => {
												const {formattedValue} = values;
												updateOwnerInfo(entry[0],formattedValue);
											}} />
									</label>
								)
							}else{
								return(
									<label key={'ow'+index}>
										{entry[1].label}
										<input className={(entry[1].error?'invalid':'')} type={'text'} value={entry[1].value} placeholder={entry[1].label} onChange={(e)=>{updateOwnerInfo(entry[0],e.target.value)}}/>
									</label>
								)
							}
						})}
						<div className={'save-row'}>
							<button  className={'simple-rounded-button blue'} onClick={(e)=>{}}><span>Save Changes</span></button>
						</div>
					</div>
				</div>

			</div>
		</Popup>
	)
}

import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useStoreActions,useStoreState} from "easy-peasy";
import {loginUrl} from "../../_constants";
import {useLocation} from "react-router";

export default function Enroll({responseHandler}){

	const location = useLocation();
	const [email,setEmail] = useState('');

	const setMessage = useStoreActions(actions=>actions.alertMessage.setMessage);

	const handleSubmit = () =>{
			axios.post('/pending-invites',{email:email}).then(response => {
				responseHandler(response,email);
			}).catch(e => {});
	};

	useEffect(()=>{
		let params = new URLSearchParams(location.search);
		if(params.get('email')){
			setEmail(params.get('email'))
			setTimeout(()=>{handleSubmit()},100)
		}
	},[location])

	const loginKeyUpHandler = (e) =>{
		if(e.keyCode === 13 && email.trim().length>0){
			handleSubmit();
		}
	};
	return (
		<div className={'slide welcome-slide'}>
			<div>
				<img className={'thrive-logo'} src={'/images/logo-no-text.svg'} alt={'thrive'} />
				<h2>Enter your email to find your invitation.</h2>
			</div>
			<div className={'small-form'}>
				<>
					<label>
						Email
						<input onKeyUp={(e)=>{loginKeyUpHandler(e)}} type={'email'} value={email} onChange={(e) => {
							setEmail(e.target.value)
						}}/>
					</label>

					<div className={'full-row'}>
						<button className={'simple-rounded-button green'} onClick={() => {
							handleSubmit()
						}}><span>Submit</span></button>
					</div>

				</>

			</div>
			<a className={'standard-link green'} href={'/login'}>Log in to existing account</a>
		</div>
	)
}

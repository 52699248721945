import React from 'react';

export default function NoResults({clearHandler}){

	return(
		<div className={'no-results'}>
			<img src={'/images/search.svg'} />
			<div>No Results Found</div>
			{!!clearHandler && <button onClick={()=>clearHandler()} className={'simple-rounded-button purple'}><span>Clear Search</span></button> }
		</div>
	)
}

import React, {useEffect, useState} from 'react';
import {useStoreActions} from "easy-peasy";
import axios from "axios";

export default function EmployeeTermsAndConditionsSlide({nextHandler,organization, authToken}){

	const showMessage = useStoreActions(actions=>actions.alertMessage.setMessage);
	const [confirmed,setConfirmed] = useState(null);
	const [termsText,setTermsText] = useState('');
	const [checkNeeded,setCheckNeeded] = useState(false);
	const continueHandler = () =>{
		if(!confirmed){
			setCheckNeeded(true);
			showMessage({message:'Terms and Conditions must be Accepted.',type:'error'});
			return;
		}
		setCheckNeeded(false);
		nextHandler(true)
	};

	useEffect(()=>{
		setCheckNeeded(confirmed === false);
	},[confirmed])

	useEffect(()=>{

		const cancelToken = axios.CancelToken.source();
		axios.get('/organizations/'+organization.organization_id+'/employee-terms',{cancelToken:cancelToken.token, headers:{'Auth-token': authToken}}).then(response=>{
			if(response.data.terms) {
				setTermsText(response.data.terms);
			}else{
				showMessage({message:'Terms and Conditions could not be retrieved.',type:'error'})
			}
		});
		return ()=>{
			cancelToken.cancel('')
		}
	},[]);

	return (
		<div className={'slide welcome-slide terms-conditions-slide'}>
			<div className={'title'}>

				<h2><img className={'thrive-logo'} src={'/images/thrive-contribution.svg'} alt={'thrive'} />Terms & Conditions</h2>
			</div>
			<div className={'content-holder'}>
				<div className={'scrollBox'} dangerouslySetInnerHTML={{__html:termsText}}/>
			</div>
			{termsText &&
				<div className={'confirmation'}>
					<div className={'check-holder ' + (checkNeeded?'invalid':'')} onClick={() => {
						setConfirmed(!confirmed)
					}}>
						<span className={'checkbox ' + (confirmed ? 'checked ' : ' ')+ (checkNeeded?'invalid':'')}/>
						I have read and accept the Terms and Conditions
					</div>
					<button onClick={() => {
						continueHandler()
					}} className={'simple-rounded-button small fixed green'}><span>Continue</span></button>
				</div>
			}
		</div>
	)
}

import React, {useEffect, useState} from 'react';
import Popup from "./popup";

export default function DocumentDetailsPopup({closeHandler, descriptions, title}){


	return (
		<Popup closeHandler={closeHandler} bodyClass={'evoshare-result'} >
			<div className={'popup-content evoshare-result-popup document-details'} >
				<div>
					<h2>{title||'Document Options'}</h2>
					<ul>
						{descriptions.map(desc=>
							<li key={desc.document_description_id}>{desc.document_description_description}</li>
						)}
					</ul>
				</div>
			</div>
		</Popup>
	)
}

import React, {useEffect, useState} from 'react';
import {useStoreActions, useStoreState} from "easy-peasy";
import SmallOrganizationCard from "./smallOrganizationCard";
import {useHistory} from "react-router";
import axios from "axios";

export default function SwitchOrganization(){
	const history = useHistory();
	const [screenState,setScreenState] = useState('allAccounts');
	const setUser = useStoreActions(actions=>actions.user.login);
	const user = useStoreState(state=>state.user.user);
	const organizations = useStoreState(state=>state.user.organizations);
	const activeOrganization = useStoreState(state=>state.user.activeOrganization);
	const [selectedOrganization,setSelectedOrganization] = useState(null);
	const setToken = useStoreActions(actions => actions.user.updateToken);
	const setActiveOrganization = useStoreActions(actions=>actions.user.setActiveOrganization);
	const resetUploadResponse = useStoreActions(actions=>actions.fileUploadResponse.reset);
	const resetNewestFile = useStoreActions(actions=>actions.newestFileForDownload.reset);
	const resetHeaderInfo = useStoreActions(actions=>actions.headerInfo.reset);
	const [hasPendingInvites,setHasPendingInvites] = useState(false);
	const selectOrganization = (organization) =>{
			setSelectedOrganization(organization);
			setScreenState('selectedOrganization');
	};

	const resetHandler = () =>{
		setSelectedOrganization(null);
		setScreenState('allAccounts');
	};
	useEffect(()=>{
		setActiveOrganization(null);
		resetHeaderInfo();
		resetNewestFile();
		resetUploadResponse();
		axios.post('/auth/validate').then(response=>{
			setUser(response.data.user)
			if(response.data.user.user_organization_roles.filter(role=>role.user_organization_role_status === 'active').length === 1){
				setActiveOrganization(response.data.user.user_organization_roles.filter(role=>role.user_organization_role_status === 'active')[0])
			}
			setHasPendingInvites(response.data.user.user_organization_roles.filter(role=>role.user_organization_role_status === 'inactive').length>0);
		}).catch(e=>{})
	},[]);

	useEffect(()=>{
		if(activeOrganization){
			history.push('/');
		}
	},[activeOrganization])
	return (
		<div className={'slide welcome-slide'}>
			<div>
				<img className={'thrive-logo'} src={'/images/logo-no-text.svg'} alt={'thrive'} />
				<h2>{!!activeOrganization ? 'Switch Accounts' : 'Choose Account'}</h2>
			</div>
			<div className={'small-form switch-accounts'}>
				{screenState === 'allAccounts' &&
					<>
						{organizations.map(organization=> {
							if(organization.role_token) {
								if(organization.user_organization_role_status !== 'active'){
									return null;
								}
								return (<SmallOrganizationCard key={organization.organization_id + organization.role_token}
								                       clickHandler={selectOrganization} organization={organization}/>)
							}
						})}
						{(hasPendingInvites && organizations.filter(org=>org.user_organization_role_status === 'active').length===0) &&
							<p>
								You Have pending invites that need to be accepted before logging in.<br/><br/> <a href={'/enroll?email='+encodeURIComponent(user.user_email)}>Click here to resend them.</a>
							</p>
						}
						{(!hasPendingInvites && organizations.filter(org=>org.user_organization_role_status === 'active').length===0) &&
							<p>
								No Active or Pending accounts found for this user.
							</p>
						}
						{!!activeOrganization ?
							<span onClick={()=>{history.goBack()}} className={'clickable'}>Close</span> :
							<span onClick={()=>{setToken()}} className={'clickable'}>Log out</span>
						}
					</>
				}
				{screenState === 'selectedOrganization' &&
					<>
						<SmallOrganizationCard organization={selectedOrganization} clickHandler={()=>{}} viewing={true} />
						<div className={'full-row'}>
							<span onClick={()=>{resetHandler()}} className={'clickable'}>Back to All Accounts</span>
							<button className={'simple-rounded-button green'} onClick={() => {
								setActiveOrganization(selectedOrganization);
							}}><span>{!!activeOrganization?'Switch Account':'Continue'}</span></button>
						</div>
					</>
				}
			</div>

		</div>
	)
}

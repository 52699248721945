import { useState, useEffect } from 'react';

export default function useAlertOnUnsaved(hasUnsavedChanges) {
    const [unsavedChanges,setUnsavedChances] = useState(null);

    useEffect(()=>{setUnsavedChances(hasUnsavedChanges)},[hasUnsavedChanges])

    useEffect(()=>{
        if(unsavedChanges){
            window.addEventListener('beforeunload', alertUser)
        }else{
            window.removeEventListener('beforeunload', alertUser)
        }
        return () => {
            window.removeEventListener('beforeunload', alertUser)
        }
    },[unsavedChanges])

    const alertUser = e => {
        e.preventDefault()
        e.returnValue = ''
    }
}
import React, {useEffect, useState} from 'react';
import Popup from "./popup";

export default function InfoPopup({closeHandler, title, info, isPlainText}){



	return (
		<Popup closeHandler={closeHandler} bodyClass={'evoshare-result'} >
			<div className={'popup-content thrive-description'} >
				<h2><img src={'/images/thrive-contribution.svg'} alt={''} />{title}</h2>
				{!isPlainText &&
					<div style={{paddingLeft:'30px'}} className={'body'} dangerouslySetInnerHTML={{__html: info}} />
				}
				{isPlainText &&
					<div style={{paddingLeft:'30px'}} className={'body'}>
						<p>
							{info}
						</p>
					</div>
				}
			</div>
		</Popup>
	)
}

import React, {useState,useEffect} from 'react';
import {useLocation} from "react-router";
import Popup from "./popups/popup";
import { CSSTransition } from 'react-transition-group';

export default function SingleSelector({options,changeHandler,showStatusMarkers,inputDisabled, simple,fieldKey}){
    const location = useLocation();
    const [showOptions,setShowOptions] = useState(false);
    const [selectedOption,setSelectedOption] = useState(null);
    const [allOptions,setAllOptions] = useState([]);
    const [updated,setUpdated] = useState('');
    const clickHandler = (e) =>{
        if(inputDisabled){
            return;
        }
        if(e.target.hasAttribute('data-index')){
            let selected = allOptions[parseInt(e.target.getAttribute('data-index'))];
            setSelectedOption(selected);
            if(fieldKey) {
                changeHandler(fieldKey,selected.value);
            }else{
                changeHandler(selected.value);
            }
            setShowOptions(false);
            setUpdated(Math.random());
        }else{
            setShowOptions(!showOptions);
        }
    };
    const closeOptions = () =>{
        setShowOptions(false)
    }

    useEffect(()=>{
        let selected = null;
        setAllOptions(options.map(option=>{
            if(!option){
                return null;
            }
            if(!option.hasOwnProperty('selected')) {
                option.selected = false;
            }else if(option.selected){
                selected = option;
            }
            return option;
        }));
        if(!selectedOption) {
            if (!selected) {
                selected = options[0];
            }
            if(selected) {
                setSelectedOption(selected);
                if (fieldKey) {
                    changeHandler(fieldKey, selected.value);
                } else {
                    changeHandler(selected.value);
                }
            }
        }
        setUpdated(Math.random());
    },[options]);

    return (
        <>

            {!!selectedOption &&

                    <div className={'single-selector ' + (inputDisabled?'disabled ':' ') + (simple?'simple':'')} onClick={(e)=>clickHandler(e)}>
                        <span className={'single '+(showStatusMarkers?('status ' + (selectedOption.value === 'active'?'active':'inactive')):'')} >{selectedOption.label}</span>
                        <CSSTransition in={showOptions &&  location.state.layout !== 'mobileMax'}
                                       timeout={100}
                                       classNames="options-slide-down"
                                       unmountOnExit >
                            <div key={'optionlist'} className='option-list single'>
                                {allOptions.length>0 && allOptions.map((option, index) => {
                                    return (
                                        <span key={option.label+option.value}
                                              data-index={index}
                                              className={(option.value === selectedOption.value? 'selected ' : '')+(showStatusMarkers?(option.value==='active'?'status active':'status inactive'):'')}
                                        >
                                            {option.label}
                                        </span>
                                    )
                                })}
                            </div>
                        </CSSTransition>
                        {(showOptions && location.state.layout === 'mobileMax') &&
                            <Popup closeHandler={closeOptions} simple={true}>
                                <div className='option-list single'>
                                    {allOptions.length > 0 && allOptions.map((option, index) => {
                                        return (
                                            <span key={option.label + option.value}
                                                  data-index={index}
                                                  className={(option.value === selectedOption.value ? 'selected ' : '') + (showStatusMarkers ? (option.value === 'active' ? 'status active' : 'status inactive') : '')}
                                            >
                                                {option.label}
                                            </span>
                                        )
                                    })}
                                </div>
                            </Popup>
                        }
                    </div>
            }
            {showOptions && <div onClick={(e)=>{clickHandler(e)}} className='click-catcher'/>}
        </>
    )
}

import React, {useEffect, useState} from 'react';
import MobileEmployeeRow from "../mobileEmployeeRow";
import {useLocation} from "react-router";
import NoResults from "../noResults";
import {baseApiUrl} from "../../_constants";
import {useStoreState} from "easy-peasy";

export default function EmployeeList({employees,accentColor,viewHandler, clearHandler, statusChangeHandler, sortHandler}){

    const location = useLocation();

    const [allChecked,setAllChecked] = useState(false);
    const [selectedEmployees,setSelectedEmployees] = useState([]);
    const [updateKey,setUpdateKey] = useState(Math.random());

    const getEmployeeStatus = (employee)=>{
        if(!employee || !employee.roles || employee.roles.length<=0){
            return;
        }
        let roleStatus = '';
        employee.roles.forEach(role=>{

            if(role.role_token==='employee'){
                roleStatus = role.user_organization_role_status;
            }
        });
        return roleStatus;
    };

    const getEmployeeAcceptDate = (employee)=>{
        if(!employee || !employee.roles || employee.roles.length<=0){
            return;
        }
        let acceptDate = '';
        employee.roles.forEach(role=>{

            if(role.role_token==='employee' && role.user_organization_role_timestamp_accepted){
                acceptDate = new Date(role.user_organization_role_timestamp_accepted.replace(' ','T')).toLocaleDateString();
            }
        });
        return acceptDate;
    };

    const toggleEmployeeSelection = (employee) =>{
        if(selectedEmployees.some(emp=>emp.user_id === employee.user_id)){
            setSelectedEmployees(selectedEmployees.filter(emp=>emp.user_id !== employee.user_id))
        }else{
            setSelectedEmployees([...selectedEmployees,...[employee]]);
        }
        setUpdateKey(Math.random());
    };

    const updateSelectedStatus = (status) =>{
      let employeesNeedUpdated = selectedEmployees.filter(employee=>getEmployeeStatus(employee) !== status);
      statusChangeHandler(status,employeesNeedUpdated);
      setSelectedEmployees([]);
    };

  const toggleSelectAll = () =>{
      if(!allChecked){
          setAllChecked(true);
          setSelectedEmployees(employees);
      }else{
          setAllChecked(false);
          setSelectedEmployees([]);
      }
      setUpdateKey(Math.random());
  };

    useEffect(()=>{
        if(selectedEmployees.length !== 0 && selectedEmployees.length === employees.length){
            setAllChecked(true);
        }else{
            setAllChecked(false);
        }
        setUpdateKey(Math.random());
    },[selectedEmployees])
    return (
        <div className={'employee-list dashboard-list drawer-list'}>

            <div className={'table-row employee-row tablet with-selector bulk-actions'}>

                <div className={'select-holder'} onClick={() => {
                    toggleSelectAll()
                }}>
                    <span className={'checkbox ' + (allChecked ? 'checked ' : ' ')}/>
                    <span className={'label'}>Select All</span>
                </div>
                <button onClick={()=>{updateSelectedStatus('active')}} className={'simple-rounded-button approved-only'}><span>Activate Selected</span></button>
                <button onClick={()=>{updateSelectedStatus('inactive')}} className={'simple-rounded-button approved-only'}><span>Deactivate Selected</span></button>


            </div>
            {location.state.layout === 'desktop' &&
            <div className={'table-row employee-row header with-selector'}>
                <span />
                <span onClick={()=>{sortHandler('profile_last_name')}} className={'small clickable'}>Name</span>
                <span onClick={()=>{sortHandler('user_email')}} className={'large clickable'}>Email</span>
                <span onClick={()=>{sortHandler('user_organization_number')}} className={'small clickable'}>ID No</span>
                <span onClick={()=>{sortHandler('user_organization_start_date')}} className={'small clickable'}>Start Date</span>
                <span onClick={()=>{sortHandler('user_organization_role_timestamp_accepted')}} className={'small clickable'}>Sign Up Date</span>
                <span onClick={()=>{sortHandler('user_organization_role_timestamp')}} className={'small clickable'}>Invite Date</span>
                <span className={'small '}>Status</span>
                <span className={'med'}>Details</span>
            </div>
            }
            {location.state.layout==='desktop' && employees.map((employee,index)=>{
                return (
                    <div key={index} className={'table-row employee-row with-selector'}>
                        <div onClick={() => {
                            toggleEmployeeSelection(employee)
                        }}>
                            <span className={'checkbox ' + (selectedEmployees.some(emp=>emp.user_id === employee.user_id) ? 'checked ' : ' ')}/>
                        </div>
                        <span className={'small'}>{employee.user_display_name}</span>
                        <span className={'large'}>{employee.user_email}</span>
                        <span className={'small'}>{employee.user_organization_number}</span>
                        <span className={'small'}>{employee.user_organization_start_date?new Date((employee.user_organization_start_date + 'T11:00:00').replace(' ','T')).toLocaleDateString():''}</span>
                        <span className={'small'}>{getEmployeeAcceptDate(employee) || 'N/A'}</span>
                        <span className={'small'}>{new Date(employee.user_organization_timestamp).toLocaleDateString()}</span>
                        <span className={'small status ' + (getEmployeeStatus(employee) === 'active'?'active':'inactive')}>{getEmployeeStatus(employee)}</span>
                        <span className={'med'}><button onClick={()=>{viewHandler(employee)}} className={'employee'} style={{color:accentColor}}> View Employee </button></span>
                    </div>
                )
            })}
            {(location.state.layout==='tabletMax' || location.state.layout==='tabletMed')  &&
                <>

                    { employees.map((employee,index)=>{
                return (
                    <div key={index} className={'table-row employee-row tablet with-selector'}>
                        <div className={'select-holder'} onClick={() => {
                            toggleEmployeeSelection(employee)
                        }}>
                            <span className={'checkbox ' + (selectedEmployees.some(emp=>emp.user_id === employee.user_id) ? 'checked ' : ' ')}/>
                        </div>
                        <div className={'name'}>
                            <span className={'title'}>Name</span>
                            <span>{employee.profile_first_name + ' ' + employee.profile_last_name}</span>
                        </div>
                        <div>
                            <span className={'title'}>Email</span>
                            <span>{employee.user_email}</span>
                        </div>
                        <div>
                            <span className={'title'}>ID No</span>
                            <span>{employee.user_organization_number}</span>
                        </div>
                        <div>
                            <span className={'title'}>Start Date</span>
                            <span>{employee.user_organization_start_date?new Date((employee.user_organization_start_date + 'T11:00:00').replace(' ','T')).toLocaleDateString():''}</span>
                        </div>
                        <div>
                            <span className={'title'}>Sign Up Date</span>
                            <span>{getEmployeeAcceptDate(employee) || 'N/A'}</span>
                        </div>
                        <div>
                            <span className={'title'}>Invite Date</span>
                            <span>{new Date(employee.user_organization_timestamp.replace(' ','T')).toLocaleDateString()}</span>
                        </div>
                        <div>
                            <span className={'title'}>Status</span>
                            <span className={ 'status ' + (getEmployeeStatus(employee) === 'active'?'active':'inactive')} >{getEmployeeStatus(employee)}</span>
                        </div>
                        <div>
                            <span ><button onClick={()=>{viewHandler(employee)}} className={'employee'} style={{color:accentColor}}>View More</button></span>
                        </div>
                    </div>

                )

            })}
                </>
            }
            {location.state.layout === 'mobileMax' &&
                employees.map((employee,index)=> <MobileEmployeeRow selectedEmployees={selectedEmployees} selectToggler={toggleEmployeeSelection} viewHandler={viewHandler} key={index} employee={employee} employeeAcceptDate={getEmployeeAcceptDate(employee)} employeeStatus={getEmployeeStatus(employee)} accentColor={accentColor}/>)
            }
            {employees.length<=0 && <NoResults clearHandler={clearHandler} />}
        </div>
    )
}

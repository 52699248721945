import React from 'react';

export default function SignupSteps({curStep}){



	return (
		<div className={'full-width signup-steps'}>
			<div className={'max-width-container signup-steps desktop-up'}>
				<div className={'step '+ (curStep>3?'complete':curStep<4?'active':'')}>
					<span className={curStep>3?'complete':curStep<4?'active':''}>1</span>
					Begin Enrollment
				</div>
				<div className={'step '+(curStep>5?'complete':[4,5].includes(curStep)?'active':'')}>
					<span className={curStep>5?'complete':[4,5].includes(curStep)?'active':''}>2</span>
					Elect Your Contributions
				</div>
				<div className={'step ' + (curStep>6?'complete':curStep===6?'active':'')}>
					<span className={curStep>6?'complete':curStep===6?'active':''}>3</span>
					Register Accounts
				</div>
				<div className={'step ' + (curStep>7?'complete':curStep===7?'active':'')}>
					<span className={curStep>7?'complete':curStep===7?'active':''}>4</span>
					Connect Evoshare
				</div>
			</div>
			<div className={'max-width-container signup-steps tablet'}>
				{
					{
						0:<div className={'mobile-step'}><strong>1 / 4</strong> Begin Enrollment</div>,
						1:<div className={'mobile-step'}><strong>1 / 4</strong> Begin Enrollment</div>,
						2:<div className={'mobile-step'}><strong>1 / 4</strong> Begin Enrollment</div>,
						3:<div className={'mobile-step'}><strong>1 / 4</strong> Begin Enrollment</div>,
						4:<div className={'mobile-step'}><strong>2 / 4</strong> Elect Your Contributions</div>,
						5:<div className={'mobile-step'}><strong>2 / 4</strong> Elect Your Contributions</div>,
						6:<div className={'mobile-step'}><strong>3 / 4</strong> Register Accounts</div>,
						7:<div className={'mobile-step'}><strong>4 / 4</strong> Connect Evoshare</div>
					}[curStep]
				}

			</div>
		</div>
	)
}

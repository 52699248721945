import React, {useEffect, useRef, useState} from 'react';
import {CSSTransition, TransitionGroup} from "react-transition-group";
export default function MultiAutoComplete({options,preselectedOptions,updateHandler,labelSelector,valueSelector,category,disabled}){

    const [allOptions,setAllOptions] = useState(options||[]);
    const [remainingOptions,setRemainingOptions] = useState(options || []);
    const [selectedOptions,setSelectedOptions] = useState(preselectedOptions && options?
        options.filter(option=>preselectedOptions.filter(pre=>pre===option.user_id).length>0)
        :[]);
    const [searchTerm,setSearchTerm] = useState('');
    const [showOptions,setShowOptions] = useState(false);
    const [filteredOptions,setFilteredOptions] = useState([]);
    const [updated,setUpdated] = useState('');
    const mainRef = useRef();

    const removeSelectedOption = (index) => {
        if(disabled){
            return;
        }
        let newSelected = selectedOptions;
        newSelected.splice(index,1);
        setSelectedOptions(newSelected);
        setUpdated(Math.random());
    };
    useEffect(()=>{
        setAllOptions(options);
        setRemainingOptions(options);
        if(category) {
            setSelectedOptions(selectedOptions.filter(option => {
                return option.roles.filter(role => role.role_id === category).length > 0
            }));
        }
        setSearchTerm('');
    },[options,category]);

    useEffect(()=>{
        setShowOptions(false);
        setSearchTerm('');
        setRemainingOptions(allOptions.filter(a=>selectedOptions.filter(b=>b[valueSelector]===a[valueSelector]).length<=0));
        updateHandler(selectedOptions);
    },[selectedOptions]);

    useEffect(()=>{
        if(searchTerm.trim().length === 0){
            setFilteredOptions(remainingOptions);
            setShowOptions(false);
            return;
        }
        setShowOptions(true);
        let newFilteredOptions = remainingOptions.filter(option=>{

                return(
                    (option[labelSelector]?option[labelSelector].toLowerCase().indexOf(searchTerm.toLowerCase())>-1:false) ||
                    (option[valueSelector]?option[valueSelector].toLowerCase().indexOf(searchTerm.toLowerCase())>-1:false)
                )
            }
        )
        setFilteredOptions(newFilteredOptions);

    },[searchTerm]);

    const keyDownHandler = (e) =>{
        if(e.keyCode === 9 || e.keyCode === 13){
            e.preventDefault();
            setSelectedOptions([...selectedOptions,filteredOptions[0]]);
            setShowOptions(false);
        }
    };


    return (
        <div ref={mainRef} className={'multi-autocomplete active'}>
            {showOptions && <div onClickCapture={()=>{setShowOptions(false)}} className='click-catcher'/>}
            {!disabled &&
            <div className={'input-holder'}>
                <input data-isInput={true} value={searchTerm} onKeyDown={(e) => {
                    keyDownHandler(e)
                }} onChange={e => {
                    setSearchTerm(e.target.value)
                }}/>

                {(showOptions && filteredOptions.length > 0) &&
                <div className='option-list single autocomplete'>
                    {filteredOptions.length > 0 && filteredOptions.map((option) => {
                        return (
                            <span key={option[labelSelector] + option[valueSelector]}
                                  onClick={() => {
                                      setSelectedOptions([...selectedOptions, option])
                                  }}
                            >
                                        {option[labelSelector] + ', ' + option[valueSelector]}
                                    </span>
                        )
                    })}
                </div>
                }
            </div>
            }
            <TransitionGroup  className={'selected-holder'}>

                {selectedOptions.map((option,index)=>(
                    <CSSTransition
                        key={option[labelSelector]+option[valueSelector]}
                        timeout={500}
                        classNames="fade"
                    >
                        <div key={option[labelSelector]+option[valueSelector]+option[valueSelector]} className={'selected-option'}>{option[labelSelector]}<span onClick={()=>{removeSelectedOption(index)}} className={'mdi mdi-close-circle'} /></div>
                    </CSSTransition>
                ))}
            </TransitionGroup>
        </div>
    )
}

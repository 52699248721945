import React, {useEffect, useState} from 'react';
import Popup from "./popup";
import axios from 'axios';
import {getHttpErrorMessage} from "../../_helpers";
import NumberFormat from "react-number-format";
export default function ManualSavingsPopup({sucessHandler,closeHandler,authToken, organization, user})
{
	const [updateToken,setUpdateToken] = useState(null);
	const [accountRoutingNumber,setAccountRoutingNumber] = useState('');
	const [accountNumber,setAccountNumber] = useState('');
	const [accountNumberConfirmation,setAccountNumberConfirmation] = useState('');
	const [errorFields,setErrorFields] = useState([]);
	const [message,setMessage] = useState('');
	const [accountName,setAccountName] = useState('');
	const [bank,setBank] = useState('')


	const hasErrors = () =>{
		let errors = [];

		if(accountName.trim().length<=0){
			errors.push('accountName');
			setMessage('Account Name required.');
		}
		if(accountRoutingNumber.trim().length<=0){
			errors.push('accountRoutingNumber');
			setMessage('Account Routing Number required.');
		}
		if(bank.trim().length<=0){
			errors.push('bank');
			setMessage('Institution Name required.');
		}

		if(accountNumberConfirmation !== accountNumber){
			errors.push('accountNumberConfirmation');
			setMessage('Account Numbers Must Match.');
		}
		if(accountNumberConfirmation.trim().length<=0){
			errors.push('accountNumberConfirmation');
			setMessage('Confirm Account Number.');
		}
		if(accountNumber.trim().length<=0){
			errors.push('accountNumber');
			setMessage('Account Number Required.');
		}

		setErrorFields(errors);
		return errors.length>0;
	};

	const saveHandler = () =>{
		if(hasErrors()){
			return;
		}
		let postData = {
			account: {
				financial_institute:bank,
				savings_title:accountName,
				savings_account_number: accountNumber,
				savings_account_routing_number: accountRoutingNumber,
				account_type_token:'emergency',
				account_deposits_required:'yes'
			}
		};
		let config = {};
		if(authToken){
			config.headers = {'Auth-token': authToken}
		}
		axios.post('/organizations/'+organization['organization_id']+'/users/'+user['user_id']+'/add-account',postData, config).then(response=>{
			sucessHandler(response);
		}).catch(e=>{
			setMessage(getHttpErrorMessage(e));
		});
	};

	return (
		<Popup closeHandler={closeHandler}>
			<div className={'large-form popup-content form-slide'}>
				<div className={'title'}>
					<h2><img className={'thrive-logo'} src={'/images/thrive-contribution.svg'} alt={'thrive'} />Add Your Emergency Savings Account</h2>

				</div>
				<div style={{padding:'20px', textAlign:'center', marginBottom:'20px'}} className={'body'}>
					After Saving your account information two deposits between $0.01 and $0.20 will be made to the account. Come back to this screen after receiving them to verify the account. Payments will not be made to the account until this is done.
				</div>
				<div className={'form-container no-border'}>

					<div className={'form-inner field-list'}>

						<label>
							Financial Institution Name:
							<input className={(errorFields.includes('bank')?'invalid':'')} value={bank} onChange={(e)=>{setBank(e.target.value)}}/>
						</label>

						<label>
							Account Name:
							<input className={(errorFields.includes('accountName')?'invalid':'')} value={accountName} onChange={(e)=>{setAccountName(e.target.value)}}/>
						</label>
						< label className={'full-row'}>
							Account Number:
							<NumberFormat
								className={(errorFields.includes('accountNumber') ? 'invalid' : '')}
								placeholder={'#'}
								decimalScale={0}
								thousandSeparator={false}
								value={accountNumber}
								onValueChange={(values) => {
									const {value} = values;
									setAccountNumber(value);

								}} />
						</label>
						<label className={'full-row'}>
							Confirm Account Number:
							<NumberFormat
								className={(errorFields.includes('accountNumberConfirmation') ? 'invalid' : '')}
								placeholder={'#'}
								decimalScale={0}
								thousandSeparator={false}
								value={accountNumberConfirmation}
								onValueChange={(values) => {
									const {value} = values;
									setAccountNumberConfirmation(value);

								}} />
						</label>
						<label className={'full-row'}>
							Routing Number:
							<NumberFormat
								className={(errorFields.includes('accountRoutingNumber') ? 'invalid' : '')}
								placeholder={'#'}
								decimalScale={0}
								thousandSeparator={false}
								value={accountRoutingNumber}
								onValueChange={(values) => {
									const {value} = values;
									setAccountRoutingNumber(value);

								}} />
						</label>
						<div className={'save-row full-row'}>
							<button  className={'simple-rounded-button blue'} onClick={(e)=>{saveHandler()}}><span>Add Account</span></button>
							{message && <p className={'popup-form-error'}>{message}</p>}
						</div>
					</div>
				</div>
			</div>
		</Popup>
	)
}

import React, {useState, useEffect, useCallback} from 'react';
import {useStoreActions} from "easy-peasy";
import axios from "axios";
import RegisterAccountPopup from "../popups/registerAccountPopup";
import {plaidEmployerTokenUrl} from "../../_constants";

export default function OwnerRegisterAccountSlide({nextHandler,backHandler,authToken,organization,user, updateOrganization}) {

	const [accountsResponse,setAccountsResponse] = useState(null);
	const [showNewAccountPopup,setShowNewAccountPopup] = useState(false);
	const showMessage = useStoreActions(actions=>actions.alertMessage.setMessage);
	const [plaidHandler,setPlaidHandler] = useState(null);
	const [accountSetUp,setAccountSetUp] = useState(false);
	const [manualConnect,setManualConnect] = useState(false);
	const [accountNumber,setAccountNumber] = useState('');
	const [routingNumber,setRoutingNumber] = useState('');
	const [accountName,setAccountName] = useState('');

	const onSuccess = useCallback((token, metadata) => {
		axios.post('/plaid/accounts',{public_token:token,organization_id:organization.organization_id},{headers: {'Auth-token': authToken}}).then(response=>{
			setAccountsResponse(response.data);
			setShowNewAccountPopup(true);
		}).catch(e=>{})
	}, []);

	const plaidButtonHandler = ()=>{
		plaidHandler.open();
	};

	const registerAccountPopupCloser = (account,institution) =>{
		if(!account || !institution){
			setShowNewAccountPopup(false);
			setAccountsResponse(null);
			return;
		}
		const postData = {
			account:account,
			item:accountsResponse.item,
			connection_id:accountsResponse.connection_id,
			institution:institution,
			organization_id:organization.organization_id
		};
		axios.post('/plaid/register-account',postData,{headers: {'Auth-token': authToken}}).then(response=>{
			setShowNewAccountPopup(false);
			setAccountsResponse(null);
			setTimeout(()=>{document.body.style.overflow = 'scroll';nextHandler();},30);
		}).catch(e=>{});

	};

	const sendManualAccountInfo = () =>{
		let postData = {
			funding_account:{
				funding_account_number:accountNumber.trim(),
				funding_account_routing_number:routingNumber.trim(),
				funding_account_title:accountName.trim()
			}

		};
		if(postData.funding_account.funding_account_number.length === 0){
			showMessage({message:'Account Number Required',type:'error'});
			return;
		}
		if(postData.funding_account.funding_account_routing_number.length === 0 || postData.funding_account.funding_account_routing_number.length > 9){
			showMessage({message:'Routing Number Invalid',type:'error'});
			return;
		}
		axios.post('/organizations/'+organization.organization_id+'/funding',postData,{headers: {'Auth-token': authToken}}).then(response=>{
			if(response.data.funding_account_id){
				organization.funding_account_id = response.data.funding_account_id;
				updateOrganization(organization);
				nextHandler();
			}else{
				showMessage({message:'Unknown error adding account',type:'error'});
			}
		}).catch(e=>{});
	};

	useEffect(()=>{
		const plaidCancelToken = axios.CancelToken.source();
		const validateCancelToken = axios.CancelToken.source();
		const fundingCancelToken = axios.CancelToken.source();
		axios.get('/organizations/'+organization.organization_id+'/validate-billing-plaid', {cancelToken:validateCancelToken.token,headers: {'Auth-token': authToken}}).then(response=>{
			if(!!response.data.plaid_account_set_up){
				setAccountSetUp(true);
				return;
			}
			axios.post(plaidEmployerTokenUrl, {}, {cancelToken:plaidCancelToken.token,headers: {'Auth-token': authToken}}).then(result=>{
				if (result.error != null) {
					showMessage({type:'error',message: result.error});
					return;
				}
				setPlaidHandler(window.Plaid.create({
					token: result.data.link_token,
					onSuccess: onSuccess
				}));
			});
		}).catch(e=>{});

		if(organization.funding_account_id){
			axios.get('/organizations/'+organization.organization_id+'/funding',{cancelToken:fundingCancelToken.token,headers: {'Auth-token': authToken}}).then(response=>{
				if(response.data.funding_account){
					setAccountNumber(response.data.funding_account.funding_account_number);
					setRoutingNumber(response.data.funding_account.funding_account_routing_number);
				}
			}).catch(e=>{});
		}

		return ()=>{
			validateCancelToken.cancel('');
			plaidCancelToken.cancel('');
			fundingCancelToken.cancel('');
		}
	},[]);

	return (
		<>
			{showNewAccountPopup && <RegisterAccountPopup authToken={authToken} small={false} data={accountsResponse} subType={'checking'} closeHandler={registerAccountPopupCloser} />}
			<div className={'slide welcome-slide register-account-screen'}>
				{!accountSetUp &&
				<>
					<h1>Connect your Account</h1>
					{(organization && organization.organization_multi_program === 'no') &&
						<>
							<span>
								Please select a verification method to connect the bank account you’d like to use for Thrive Payments
								{organization.using_rightfoot &&
									<>
										<br/><br/>We recommend using the Plaid automatic verification when possible. Manual Verification will require you to verify micro-deposits and will add an additional 1-2 business days to the application process.
									</>
								}
							</span>
							{plaidHandler &&
							<div className={'button-holder'}>
								<button onClick={() => {
									setManualConnect(false);
									plaidButtonHandler()
								}} className={'simple-rounded-button small fixed green'}>
									<span>Connect Through Plaid</span></button>
								<button onClick={() => {
									setManualConnect(true);
								}} className={'simple-rounded-button small fixed purple'}><span>Connect Manually</span>
								</button>
							</div>
							}
						</>
					}
					{(organization && organization.organization_multi_program === 'yes') &&
						<>
							<span>Please verify the bank account you’d like to use for Thrive Payments</span>
						</>
					}
					{(manualConnect || (organization && organization.organization_multi_program === 'yes')) &&

					<div className={'form-container no-title slide-down'}>
						<div className={'form-inner field-list'}>
							<label className={'full-row'}>
								Account Name:
								<input name={'funding_account_title'} value={accountName} onChange={e => {
									setAccountName(e.target.value)
								}}/>
							</label>
							<label>
								Account #:
								<input name={'funding_account_number'} value={accountNumber} onChange={e => {
									setAccountNumber(e.target.value)
								}} placeholder={'#########'}/>
							</label>
							<label>
								Routing #:
								<input name={'funding_account_routing_number'} value={routingNumber} onChange={e => {
									setRoutingNumber(e.target.value)
								}} placeholder={'#########'}/>
							</label>
							<label className={'full-row right'}>
								<button onClick={sendManualAccountInfo} className={'simple-rounded-button purple'}>
									<span>Submit</span></button>
							</label>
							{(organization && organization.using_rightfoot) &&
							<label className={'full-row'}>
								* After submitting your application two micro deposits will be added to the linked
								account. You will need to verify the amounts of those deposits on the 'Company
								Account' screen under the main menu after logging in.
							</label>
							}
						</div>
					</div>
					}
				</>
				}
				{accountSetUp &&
				<>
					<h1>Connected</h1>
					<span>Bank Account already connected.</span>
					<p>
						If you need to make changes to your connected funding account contact us at <a href="mailto:support@ThriveMatching.com" target="_blank" rel={'noreferrer noopener'}>support@ThriveMatching.com</a>.
					</p>
					<button onClick={() => {
						nextHandler();
					}} className={'simple-rounded-button small fixed green'}><span>Continue</span></button>
				</>
				}
			</div>
		</>
	)
}

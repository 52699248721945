import React from 'react';
import {EMPLOYEE_TYPE, EMPLOYEE_TYPE_NO_EVOSHARE} from "../../../_constants";

export default function WelcomeSlide({user,nextHandler,layout, organization, role}){
	const roleString = role && (role===EMPLOYEE_TYPE || role===EMPLOYEE_TYPE_NO_EVOSHARE)?'Employee Enrollment':'Employer Enrollment';

	return (
		<div className={'slide welcome-slide'}>
			<div>
				<img className={'thrive-logo'} src={'/images/logo-no-text.svg'} alt={'thrive'} />
				<span>{organization.organization_view_title || organization.organization_title}</span>
				<span>{roleString}</span>
				{layout !== 'mobileMax' &&
				<h1>Welcome {user && user.user_display_name}</h1>
				}
				{layout === 'mobileMax' &&
				<h1>Welcome {user && user.user_display_name.split(' ')[0]}</h1>
				}
			</div>
			<div className={'welcome-logo'}>
				{(organization && organization.organization_logo && organization.organization_logo.length>0)?
					<img src={organization.organization_logo_url} alt={organization.organization_title} />:
					<span className={'logo-placeholder mdi mdi-help'} />
				}
			</div>
			<div>
				<button onClick={()=>{nextHandler()}} className={'simple-rounded-button small fixed green'}><span>Lets Get Started!</span></button>
			</div>
		</div>
	)

}



import React, {useEffect, useState} from 'react'
import {CSSTransition} from "react-transition-group";
import FileListPlaceholder from "../../../components/placeholders/fileListPlaceholder";
import axios from "axios";
import {organizationCyclesUrl, organizationPayrollUrl} from "../../../_constants";
import {useStoreActions, useStoreState} from "easy-peasy";
import ElectionMatchList from "../../../components/lists/electionMatchList";
import InfiniteScroll from "react-infinite-scroller";

export default function Elections({accentColor}){

    const [loading,setLoading] = useState(true);

    const [currentPage,setCurrentPage] = useState(1);
    const [totalPages,setTotalPages] = useState(1);
    const [isScrollLoading,setIsScrollLoading] = useState(false);

    const [files,setFiles] = useState([]);
    const activeOrganization = useStoreState(state=>state.user.activeOrganization);
    const setNewestFile = useStoreActions(actions=>actions.newestFileForDownload.setFile);

    const scrollLoad = (page) =>{
        setIsScrollLoading(true);
        let queryString = '?page='+page;
        setCurrentPage(page);
        axios.get(organizationCyclesUrl(activeOrganization['organization_id']) + queryString).then(response=>{
            setFiles([...files,...response.data.election_cycles]);
            setLoading(false);
            setTotalPages(response.data.meta.pages);
            setTimeout(()=>{setIsScrollLoading(false);},0)
        }).catch(e=>{});
    };

    useEffect(()=>{
        let cancelToken = axios.CancelToken.source();
        axios.get(organizationCyclesUrl(activeOrganization['organization_id'])).then(response=>{
            setFiles(response.data.election_cycles);
            if(response.data.election_cycles.length>0) {
                let newestFile = response.data.election_cycles[0];
                setNewestFile({type: 'elections', id: newestFile.election_cycle_id,date:newestFile.election_cycle_timestamp});
            }
            setCurrentPage(response.data.meta.page);
            setTotalPages(response.data.meta.pages);
            setLoading(false);
        }).catch(e=>{});
        return ()=>{
            cancelToken.cancel('');
        }
    },[]);

    return (
        <>
            <div className={'content-header'}>
                <div className={'title'}>
                    <h1 style={{color:accentColor}} >
                        Final Election Files
                    </h1>
                    <a className={'sub-header'}  href={'#'}>
                        Download Past Files
                    </a>
                </div>
            </div>
            <CSSTransition
                in={loading}
                timeout={{
                    appear: 500,
                    enter: 300,
                    exit: 0,
                }}
                classNames="fade"
                unmountOnExit
            >
                <FileListPlaceholder />
            </CSSTransition>
            <CSSTransition
                in={!loading}
                timeout={{
                    enter: 300,
                    exit: 0,
                }}
                classNames="fade"
                unmountOnExit
            >
                <InfiniteScroll
                    pageStart={1}
                    loadMore={isScrollLoading?null:scrollLoad}
                    initialLoad={false}
                    hasMore={(currentPage<totalPages)}
                    loader={<div className="loader" key={0}>Loading ...</div>}
                >
                    <ElectionMatchList accentColor={accentColor} files={files} linkClass={'elections'}/>
                </InfiniteScroll>
            </CSSTransition>

        </>
    )
}

import React, {useEffect, useState} from 'react';
import {useStoreActions} from "easy-peasy";
import {formatDate, validateEmailAddress, validateNumberFormat, validateWebsiteForForte} from "../../../_helpers";
import NumberFormat from "react-number-format";
import axios from "axios";
import {ownerTitleOptions} from "../../../_constants";

export default function ProfileSetup({user, organization, updateUser, nextHandler, authToken, backHandler}){
	const showMessage = useStoreActions(actions=>actions.alertMessage.setMessage);
	const [updateKey,setUpdateKey] = useState(Math.random);
	const [formOptions,setFormOptions] = useState({});
	const [profileInfo,setProfileInfo] = useState(
		{
			profile_ssn:{label:'Last 4 of SSN',value:user.profile_ssn || '',type:'number',format:'####',mask:'_'},
			profile_dob:{label:'Date of Birth',value:user.profile_dob? formatDate(user.profile_dob) : '',type:'number',format:'##/##/####',mask:['M','m','D','d','Y','e','a','r']},
			profile_phone:{label:'Phone #',value:user.profile_phone || '',type:'number',format:'(###) ###-####',mask:'_'},
			address_street_1:{label:'Address',value:user.address_street_1 || ''},
			address_street_2:{label:'Address Line 2',value:user.address_street_2 || '', optional:true},
			address_city:{label:'City',value:user.address_city || ''},
			state_id:{label:'State',value:user.state_id || '',optionsKey:'states'},
			address_zip:{label:'Postal Code',value:user.address_zip || ''},
			country_id:{label:'Country',value:user.country_id || '236',optionsKey:'countries'},
		}
	);

	const updateProfileInfo = (key,value) =>{
		if(profileInfo[key]) {
			profileInfo[key].value = value;
			profileInfo[key].error = !value;
			setProfileInfo(profileInfo);
			setUpdateKey(Math.random())
		}
	};

	const mapFormForPost = (formData) =>{
		let data = {};
		Object.entries(formData).forEach((entry)=>{
			if(!entry[1]){
				return;
			}
			data[entry[0]] = entry[1].value;
		});
		return data;
	};

	const validateFormObject = (formObject,missingFields) =>{
		Object.entries(formObject).forEach((entry)=>{
			if(!entry[1]){
				return;
			}
			if(entry[1].optional){
				formObject[entry[0]].error = false;
				return;
			}
			if(!entry[1].value){
				formObject[entry[0]].error = true;
				missingFields.push(entry[0]);
			}else if(entry[1].type && entry[1].type === 'number' && entry[1].format){
				if(!validateNumberFormat(entry[1].value,entry[1].format, false)){
					formObject[entry[0]].error = true;
					missingFields.push(entry[0]);
				}else{
					formObject[entry[0]].error = false;
				}
			}else{
				formObject[entry[0]].error = false;
			}
		});
	};

	const remapFormOptions = (options) =>{
		return options.map((option)=>{
			let labelKey = '';
			let valueKey = '';
			for(const propKey in option){
				if(propKey.indexOf('_title')>-1 || propKey.indexOf('_name')>-1){
					labelKey = propKey;
				}else if(propKey.indexOf('_id')>-1){
					valueKey = propKey;
				}
			}
			option.label = option[labelKey];
			option.value = option[valueKey];
			return option;
		})
	};

	const submitHandler = () =>{
		let missingFields = [];

		validateFormObject(profileInfo,missingFields);
		setProfileInfo(profileInfo);
		setUpdateKey(Math.random());
		if(missingFields.length>0) {
			showMessage({message: 'All Fields are Required.', type: 'error'});
			return;
		}

		let postData = {
			user:mapFormForPost(profileInfo)
		};

		axios.post('/organizations/'+organization.organization_id+'/users/'+user.user_id+'/update-profile',postData,{headers:{'Auth-token': authToken}}).then(response=>{
			if(response.data.user) {
				updateUser(response.data.user);
			}
			nextHandler();
		}).catch(e=>{})
	};

	useEffect(()=> {
		if(user && !user.needs_profile){
			nextHandler();
		}
		const cancelToken = axios.CancelToken.source();
		axios.get('/form-options', {
			cancelToken: cancelToken.token,
			headers: {'Auth-token': authToken}
		}).then(response => {
			let businessTypes = remapFormOptions(response.data.business_types);
			let bankTypes = remapFormOptions(response.data.bank_account_types);
			let citizenshipTypes = remapFormOptions(response.data.countries);
			let countries = remapFormOptions(response.data.countries);
			let states = remapFormOptions(response.data.states);
			let legalStructures = remapFormOptions(response.data.business_legal_structure_types);
			setFormOptions({
				business_types: businessTypes,
				bank_account_types: bankTypes,
				citizenship_types: citizenshipTypes,
				countries: countries,
				states: states,
				business_legal_structure_types: legalStructures,
				owner_titles: ownerTitleOptions
			});
		}).catch(e => {
		});

		axios.get('/organizations/'+organization.organization_id+'/users/'+user.user_id,{
			cancelToken: cancelToken.token,
			headers: {'Auth-token': authToken}
		}).then(response=>{
			if(response.data.user){
				updateUser(response.data.user);
			}
		}).catch(e=>{});

		return ()=>{
			cancelToken.cancel('');
		}
	},[]);
	return(
		<>
			<div className={'slide welcome-slide form-slide'}>
				<div className={'title'}>
					<h2><img className={'thrive-logo'} src={'/images/thrive-contribution.svg'} alt={'thrive'} />Profile Information</h2>
				</div>
				<div className={'form-container no-title'}>

					<div className={'form-inner field-list'}>
						{Object.entries(profileInfo).map((entry,index)=>{
							if (!entry[1]){
								return null;
							}
							if(entry[1].optionsKey){
								return (
									<label key={'profile'+index}>
										{entry[1].label}
										<select className={(entry[1].value?'filled ':' ') + (entry[1].error?'invalid':'')} value={entry[1].value} onChange={(e)=>{updateProfileInfo(entry[0],e.target.value)}}>
											<option  value={''}>Select {entry[1].label}</option>
											{formOptions[entry[1].optionsKey] && formOptions[entry[1].optionsKey].map((option,index)=>(
												<option  style={{color:'#000'}} key={option.value+'-'+index} value={option.value}>{option.label}</option>
											))}
										</select>
									</label>
								)
							}else if(entry[1].type && entry[1].type==='number'){
								return (
									<label key={'profile'+index}>
										{entry[1].label}
										<NumberFormat
											className={(entry[1].error?'invalid':'')}
											placeholder={entry[1].label}
											format={entry[1].format}
											mask={entry[1].mask}
											decimalScale={entry[1].decimals || 0}
											prefix={entry[1].prefix || ''}
											suffix={entry[1].suffix || ''}
											value={entry[1].value}
											onValueChange={(values) => {
												const {value} = values;
												if(value) {
													updateProfileInfo(entry[0], value);
												}
											}} />
									</label>
								)
							}
							return(
								<label key={'ci'+index}>
									{entry[1].label}
									<input name={'profile_'+entry[0]} className={(entry[1].error?'invalid':'')} type={'text'} value={entry[1].value} placeholder={entry[1].label} onChange={(e)=>{updateProfileInfo(entry[0],e.target.value)}}/>
								</label>
							)
						})}
					</div>
				</div>
				<div className={'button-holder'}>
					<button onClick={()=>{backHandler()}} className={'simple-rounded-button small fixed green faded'}><span>Back</span></button>
					<button onClick={()=>{submitHandler()}} className={'simple-rounded-button small fixed green'}><span>Continue</span></button>
				</div>
			</div>
		</>
	)
}

import React, {useEffect, useState} from 'react';
import AuthPage from "../components/auth/authPage";
import Login from "../components/auth/login";
import {useStoreActions, useStoreState} from "easy-peasy";
import SwitchOrganization from "../components/auth/switchOrganization";
import Enroll from "../components/auth/enroll";
import ResendInvites from "../components/auth/resendInvites";

export default function EnrollScreen(){

	const [screenState,setScreenState] = useState('getRoles');
	const [completeMessage,setCompleteMessage] = useState('');
	const [email,setEmail] = useState('');
	const setMessage = useStoreActions(actions=>actions.alertMessage.setMessage);



	const enrollHandler = (response, email = null) =>{
		if(email) {
			setEmail(email);
		}
		if(response.data.message){
			setCompleteMessage(response.data.message);
			setScreenState('complete');
			return;
		}

		setScreenState('error');

	};

	return (
		<AuthPage>
			{screenState === 'getRoles' &&
				<Enroll responseHandler={enrollHandler}/>
			}
			{
				screenState === 'complete' &&
				<div className={'slide welcome-slide'}>
					<div>
						<img className={'thrive-logo'} src={'/images/logo-no-text.svg'} alt={'thrive'} />
						<h2>Invitations resent to {email}</h2>
						<p>{completeMessage}</p>
					</div>
					<a className={'standard-link green'} href={'/login'}>Log in to existing account</a>
				</div>
			}
			{
				screenState === 'error' &&
				<div className={'slide welcome-slide'}>
					<div>
						<img className={'thrive-logo'} src={'/images/logo-no-text.svg'} alt={'thrive'} />
						<h2>No Pending Invites found for {email}.</h2>
						<p>
							Please contact your employer to check that they have invited you to the program.
						</p>
					</div>
					<br/>
					<a className={'standard-link green'} href={'/login'}>Log in to existing account</a>
					<br/>
					<br/>
					<a className={'standard-link green'} href={'/login?reset=true'}>Reset Password</a>
				</div>
			}
		</AuthPage>
	)

}

import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useStoreActions,useStoreState} from "easy-peasy";
import {loginUrl} from "../../_constants";
import {useHistory, useLocation, useParams} from "react-router";
import PasswordField from "./passwordField";

export default function Login(){

	const history = useHistory();
	const location = useLocation();
	const [email,setEmail] = useState('');
	const [password,setPassword] = useState('');
	const [confirmPassword,setConfirmPassword] = useState('');
	const [validationCode,setValidationCode] = useState('');
	const [screenState,setScreenState] = useState('login');
	const setToken = useStoreActions(actions=>actions.user.updateToken);
	const setUserData = useStoreActions(actions=>actions.user.login);
	const setMessage = useStoreActions(actions=>actions.alertMessage.setMessage);

	useEffect(()=>{
		let params = new URLSearchParams(location.search);
		setValidationCode('');
		setConfirmPassword('');
		if(params.get("reset")){
			setScreenState('sendCode')
			return;
		}
		if(params.get("token")){
			axios.post('/auth/swap-login-token',{},{headers: {'Auth-token': params.get("token")}}).then(response=>{
				history.push('/');
				setUserData(response.data.user);
				setToken(response.data.token);
			}).catch(e=>{});
			return;
		}
		if(params.get("error")){
			setMessage({message:'User With Email or Password Not Found.',type:'error'});
			return;
		}
		if(params.get("reset_code")){
			setScreenState('resetPassword');
			setValidationCode(params.get("reset_code"));
			return;
		}
	},[location]);

	const handleLogin = () =>{
			axios.post(loginUrl, {email: email, password: password}).then(response => {
				setUserData(response.data.user);
				setToken(response.data.token);
			}).catch(e => {});
	};
	const sendCodeHandler = () =>{
		setScreenState('resetPassword');
		let postData = {email:email}
		setValidationCode('');
		setPassword('');
		setConfirmPassword('');
		axios.post('/auth/sendPasswordCode',postData).then(response=>{
			setMessage({message:'Code Sent, check your email.'});
			setScreenState('resetPassword');
			setValidationCode('');
			setPassword('');
			setConfirmPassword('');
		}).catch(e=>{
			setScreenState('login');
		});
	};
	const resetPasswordHandler = () =>{
		if(password !== confirmPassword){
			setMessage({message:'Passwords do not match',type:'error'});
			return;
		}
		if(validationCode.trim().length === 0){
			setMessage({message:'Validation Code required',type:'error'});
			return;
		}
		let postData = {
			code:validationCode,
			password:password,
			passwordConfirm:confirmPassword
		};
		axios.post('/auth/resetPassword',postData).then(response=>{
			setUserData(response.data.user);
			setToken(response.data.token);
		}).catch(e=>{});
	};
	const loginKeyUpHandler = (e) =>{
		if(e.keyCode === 13 && email.trim().length>0 && password.trim().length>0){
			handleLogin();
		}
	};
	return (
		<div className={'slide welcome-slide'}>
			<div>
				<img className={'thrive-logo'} src={'/images/logo-no-text.svg'} alt={'thrive'} />
				<h2>{screenState==='login'?'Sign in To Your Thrive Account':screenState==='sendCode'?'Enter email for verification':'Reset password'}</h2>
			</div>
			<div className={'small-form'}>
				{screenState === 'login' &&
				<>
					<label>
						Email
						<input onKeyUp={(e)=>{loginKeyUpHandler(e)}} type={'email'} value={email} onChange={(e) => {
							setEmail(e.target.value)
						}}/>
					</label>
					<label>
						Password
						<PasswordField keyupHandler={loginKeyUpHandler} value={password} changeHandler={setPassword}/>
					</label>
					<div className={'full-row'}>
						<span onClick={()=>{setScreenState('sendCode')}} className={'clickable'}>Forgot Password?</span>
						<button className={'simple-rounded-button green'} onClick={() => {
							handleLogin()
						}}><span>Sign In</span></button>
					</div>
				</>
				}
				{screenState === 'sendCode' &&
				<>
					<label>
						Email
						<input type={'email'} value={email} onChange={(e) => {
							setEmail(e.target.value)
						}}/>
					</label>
					<div className={'full-row'}>
						<span onClick={()=>{setScreenState('login')}} className={'clickable'}>Return to Log in</span>
						<button className={'simple-rounded-button green'} onClick={() => {
							sendCodeHandler()
						}}><span>Send Verification Code</span></button>
					</div>
				</>
				}
				{screenState === 'resetPassword' &&
				<>
					<label>
						Verification Code
						<input type={'validation-code'} value={validationCode} onChange={(e) => {
							setValidationCode(e.target.value)
						}}/>
					</label>
					<label>
						New Password
						<PasswordField value={password} changeHandler={setPassword} />
					</label>
					<label>
						Confirm Password
						<PasswordField value={confirmPassword} changeHandler={setConfirmPassword} />
					</label>
					<div className={'full-row'}>
						<span onClick={()=>{setScreenState('login')}} className={'clickable'}>Return to Log in</span>
						<button className={'simple-rounded-button green'} onClick={() => {
							resetPasswordHandler()
						}}><span>Reset Password</span></button>
					</div>
				</>
				}
			</div>
			{screenState === 'sendCode' &&
				<span onClick={()=>{setScreenState('resetPassword')}} className={'clickable standard-link green'}>Already have a code?</span>
			}
		</div>
	)
}

import React from 'react';
import {useLocation} from "react-router";

export default function MessageListPlaceholder(){

    const location = useLocation();
    const messages = [1,2,3,4];

    return (
        <div className={'message-list dashboard-list'}>
            {location.state.layout!=='mobileMax' &&
            <div className={'table-row file-row header'}>
                <span>Message Title</span>
                <span>Date Created</span>
                <span>Sent</span>
                <span>Details</span>
            </div>
            }
            <div className={'placeholder-holder'}>
                {location.state.layout!=='mobileMax' && messages.map((message,index)=>{
                    return (
                        <div key={index} className={'table-row  file-row ' + (message.isNew?'new':'')}>
                            <span className={'placeholder'} />
                            <span className={'placeholder'} />
                            <span className={'placeholder'} />
                            <span className={'placeholder'} />

                        </div>
                    )
                })}
                {(location.state.layout==='mobileMax')  && messages.map((message,index)=>{
                    return (
                        <div key={index} className={'table-row  file-row tablet ' + (message.isNew?'new':'')}>
                            <div>
                                <span className={'title'}>Message Title</span>
                                <span className={'placeholder'} />
                            </div>
                            <div>
                                <span className={'title'}>Date Created</span>
                                <span className={'placeholder'} />
                            </div>
                            <div>
                                <span className={'title'}>Sent</span>
                                <span className={'placeholder'} />
                            </div>
                            <div>
                                <span className={'placeholder'} />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>

    )
}

import React from 'react';
import {useStoreState} from "easy-peasy";

export default function SmallOrganizationCard({organization,clickHandler, viewing}){

	const activeOrganization = useStoreState(state=>state.user.activeOrganization);


	return (
		<div
			onClick={()=>{clickHandler(organization)}}
			className={'small-organization-card '+ ((!!activeOrganization && organization.organization_id+organization.role_token === activeOrganization.organization_id+activeOrganization.role_token) ? 'active ':'') + (viewing?'viewing':'' )}>
			{(organization.organization_logo && organization.organization_logo.length>0)?
				<img src={organization.organization_logo_url} alt={''} />:
				<span className={'logo-placeholder mdi mdi-help'} />
			}
			<div className={'name-and-role'}>
				<span className={'org-name'}>{organization.organization_view_title || organization.organization_title}</span>
				<span className={'user-type'}>{organization.role_title.split(' ').pop()}</span>
			</div>
			{(!!activeOrganization && organization.organization_id + organization.role_token === activeOrganization.organization_id + activeOrganization.role_token) ?
				<span className={'active-org-message'}><i>Logged In</i></span> :
					<button className={'round-forward-button'}/>
			}
		</div>
	)
}

import React from 'react';

export default function OwnerWelcomeScreen({user,nextHandler,layout, organization}){


	return (
		<div className={'slide welcome-slide company-owner'}>
			<div>
				<img className={'thrive-logo'} src={'/images/logo-no-text.svg'} alt={'thrive'} />
				<span>{organization.organization_view_title || organization.organization_title}</span>
				<h1>Welcome {user && user.user_display_name}</h1>
			</div>
			<div className={'welcome-logo'}>
				{(organization && organization.organization_logo && organization.organization_logo.length>0)?
					<img src={organization.organization_logo_url} alt={organization.organization_title} />:
					<span className={'logo-placeholder mdi mdi-help'} />
				}

			</div>
			<div>
				<button onClick={()=>{nextHandler()}} className={'simple-rounded-button small fixed green'}><span>Begin Setup</span></button>
			</div>

		</div>
	)

}

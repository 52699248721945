import React, {useEffect, useState} from 'react'
import {CSSTransition} from "react-transition-group";
import axios from 'axios';
import {organizationPayrollUrl, organizationUsersUrl, UPDATE_EMPLOYEES, UPDATE_PAYROLL} from "../../../_constants";
import {useStoreActions, useStoreState} from "easy-peasy";
import PayrollList from "../../../components/lists/payrollList";
import PayrollListPlaceholder from "../../../components/placeholders/payrollListPlaceholder";
import InfiniteScroll from "react-infinite-scroller";

export default function Payroll({accentColor}){

    const [loading,setLoading] = useState(true);

    const [currentPage,setCurrentPage] = useState(1);
    const [totalPages,setTotalPages] = useState(1);
    const [isScrollLoading,setIsScrollLoading] = useState(false);
    const setNewestFile = useStoreActions(actions=>actions.newestFileForDownload.setFile);

    const activeOrganization = useStoreState(state=>state.user.activeOrganization);
    const [files,setFiles] = useState([]);
    const uploadResponse = useStoreState(state=>state.fileUploadResponse.file);

    const scrollLoad = (page) =>{
        setIsScrollLoading(true);
        let queryString = '?page='+page;
        setCurrentPage(page);
        axios.get(organizationPayrollUrl(activeOrganization['organization_id']) + queryString).then(response=>{
            setFiles([...files,...response.data.cycles]);
            setLoading(false);
            setTotalPages(response.data.meta.pages);
            setTimeout(()=>{setIsScrollLoading(false);},0)
        }).catch(e=>{});
    };

    useEffect(()=>{
        let cancelToken = axios.CancelToken.source();
        axios.get(organizationPayrollUrl(activeOrganization['organization_id'])).then(response=>{
            if(response.data.cycles.length>0){
                let newest = response.data.cycles[0];
                if(activeOrganization.plan_token === 'employer-direct') {
                    setNewestFile({
                        type: 'payments',
                        id: newest.contribution_cycle_id,
                        date: newest.contribution_cycle_timestamp,
                        status: newest.contribution_cycle_status_status
                    })
                }else{
                    setNewestFile({
                        type: 'payroll',
                        id: newest.payroll_upload_id,
                        date: newest.payroll_upload_timestamp,
                        status: newest.contribution_cycle_status_status
                    })
                }
            }
            setFiles(response.data.cycles);
            setCurrentPage(response.data.meta.page);
            setTotalPages(response.data.meta.pages);
            setLoading(false);
        }).catch(e=>{});


        return ()=>{
            cancelToken.cancel('');
        }
    },[]);

    useEffect(()=>{
        if(uploadResponse && uploadResponse.includes( UPDATE_PAYROLL)){
            let cancelToken = axios.CancelToken.source();
            axios.get(organizationPayrollUrl(activeOrganization['organization_id'])).then(response=>{
                setFiles(response.data.cycles);
                setCurrentPage(response.data.meta.page);
                setTotalPages(response.data.meta.pages);
                setLoading(false);
            }).catch(e=>{});
            return ()=>{
                cancelToken.cancel('');
            }
        }
    },[uploadResponse]);

    return (
        <>
            <div className={'content-header'}>
                <div className={'title'}>
                    {(activeOrganization && activeOrganization.plan_token !== 'employer-direct') &&
                    <>
                        <h1 style={{color: accentColor}}>
                            Payroll Files
                        </h1>
                        <a className={'sub-header'} href={'#'}>
                            Download files
                        </a>
                    </>
                    }
                    {(activeOrganization && activeOrganization.plan_token === 'employer-direct') &&
                    <>
                        <h1 style={{color: accentColor}}>
                            Payment Files
                        </h1>
                        <a className={'sub-header'} href={'#'}>
                            Download Payment history
                        </a>
                    </>
                    }
                </div>
            </div>

            <CSSTransition
                in={loading}
                timeout={{
                    appear: 500,
                    enter: 300,
                    exit: 0,
                }}
                classNames="fade"
                unmountOnExit
            >
                <PayrollListPlaceholder />
            </CSSTransition>
            <CSSTransition
                in={!loading}
                timeout={{
                    enter: 300,
                    exit: 0,
                }}
                classNames="fade"
                unmountOnExit
            >
                <InfiniteScroll
                    pageStart={1}
                    loadMore={isScrollLoading?null:scrollLoad}
                    initialLoad={false}
                    hasMore={(currentPage<totalPages)}
                    loader={<div className="loader" key={0}>Loading ...</div>}
                >
                    <PayrollList files={files} />
                </InfiniteScroll>
            </CSSTransition>
            <div className={'cancel-instructions'}>
                * If you need cancel a transaction use the contact form below, or email us at <a href="mailto:support@ThriveMatching.com" target="_blank" rel={'noreferrer noopener'}>support@ThriveMatching.com</a>, and include all columns from the transaction's row in the payments file.
            </div>
        </>
    )
}

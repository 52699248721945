import React, {useEffect, useState} from 'react';
import axios from 'axios';
import SingleSelector from "../../components/singleSelector";
import {ADMIN_TYPE, Colors, EMPLOYER_CONTACT_TYPE, HR_TYPE, organizationUsersUrl} from "../../_constants";
import {useLocation} from "react-router";
import {CSSTransition} from "react-transition-group";

import UserList from "../../components/lists/userList";
import UserListPlaceholder from "../../components/placeholders/userListPlaceholder";
import UserPopup from "./userPopup";
import FilterButton from "../../components/filterButton";
import {useStoreActions, useStoreState} from "easy-peasy";

export default function Users(){
    const [newFirstName,setNewFirstName] = useState('');
    const [newLastName,setNewLastName] = useState('');
    const [newEmail,setNewEmail] = useState('');
    const [newSelectedRole,setNewSelectedRole] = useState(null);
    const [loading,setLoading] = useState(true);
    const [selectedUser,setSelectedUser] = useState(null);
    const [showUserPopup,setShowUserPopup] = useState(false);
    const setMessage = useStoreActions(actions=>actions.alertMessage.setMessage);
    const activeOrganization = useStoreState(state=>state.user.activeOrganization);
    const [roleOptions,setRoleOptions] = useState([{label:'Admin',value:ADMIN_TYPE},{label:'HR',value:HR_TYPE},{label:'Super Admin',value:EMPLOYER_CONTACT_TYPE}]);
    const [users,setUsers] = useState([]);

    const viewUser = (user) =>{
        setSelectedUser(user);
        setShowUserPopup(true);
    };
    const closeUser = () =>{
        setShowUserPopup(false);
    };

    const updateUser = (updatedUser) =>{
        if(updatedUser.roles.length<=0){
            setMessage({message:'At least One Account Type Required',type:'error'});
            return;
        }

        if(updatedUser.roles[0].value) {
            updatedUser.roles = updatedUser.roles.map(role => role.value);
        }else{
            updatedUser.roles = updatedUser.roles.map(role => role.role_token);
        }
        axios.post(organizationUsersUrl(activeOrganization['organization_id'])+'/'+updatedUser['user_id'],{user:updatedUser}).then(response=>{
            if(!!response.data.user){
                setMessage({message:'User Updated'});
                setNewSelectedRole(null);
                setNewEmail('');
                setNewFirstName('');
                setNewLastName('');
                setUsers(users.map(user=>{if(user.user_id===response.data.user.user_id){return response.data.user}return user}))
                setShowUserPopup(false);
            }
        }).catch(e=>{})
    };

    const submitNewUser = () =>{
        if(!newFirstName || newFirstName.trim().length === 0 || newLastName.trim().length === 0){
            setMessage({message:'Full Name Required for new user',type:'error'});
            return;
        }
        if(!newEmail || newEmail.trim().length === 0){
            setMessage({message:'Email Required for new user',type:'error'});
            return;
        }
        if(!newSelectedRole || newSelectedRole.length<=0){
            setMessage({message:'Account Type Required for new user',type:'error'});
            return;
        }
        let postData = {
            profile_first_name:newFirstName.trim(),
            profile_last_name:newLastName.trim(),
            user_email:newEmail.trim(),
            roles:[newSelectedRole] || [roleOptions[0].value]
        };
        axios.post(organizationUsersUrl(activeOrganization['organization_id']),{user:postData}).then(response=>{
            if(!!response.data.user){
                setMessage({message:'User Invited'});
                setNewEmail('');
                setNewFirstName('');
                setNewLastName('');
                setUsers([...users,response.data.user])
            }else{
                setMessage({message:'Server error: user not saved'})
            }
        }).catch(e=>{})
    };

    useEffect(()=>{
        let cancelToken = axios.CancelToken.source();
        axios.get(organizationUsersUrl(activeOrganization['organization_id']) + '?roles[]=employer-admin&roles[]=hr&roles[]=employer-contact',{cancelToken:cancelToken.token}).then(response=>{
            setUsers(response.data.users);
            setLoading(false);
        }).catch(e=>{});
        return ()=>{
            cancelToken.cancel('');
        }
    },[]);
    return (
        <>
            {showUserPopup && <UserPopup closeHandler={closeUser} user={selectedUser} updateHandler={updateUser} /> }
            <div className={'full-width page'}>
                <div className={'header'}>
                    <div>
                        <h1 className={'heading'} >
                            Thrive Users
                        </h1>
                    </div>
                </div>
                <div className={'list-section'}>
                    <div className={'body'}>
                        <CSSTransition
                            in={loading}
                            timeout={{
                                appear: 500,
                                enter: 300,
                                exit: 0,
                            }}
                            classNames="fade"
                            unmountOnExit
                        >
                            <UserListPlaceholder />
                        </CSSTransition>
                        <CSSTransition
                            in={!loading}
                            timeout={{
                                enter: 300,
                                exit: 0,
                            }}
                            classNames="fade"
                            unmountOnExit
                        >
                            <UserList users={users} viewHandler={viewUser} />
                        </CSSTransition>
                    </div>
                </div>
                <div className={'header'}>
                    <div>
                        <h1 className={'heading'} >
                            Add New User
                        </h1>
                    </div>
                </div>
                <div className={'list-section'}>
                    <div className={'body'}>
                        <div className={'popup-form invite'}>
                            <label>
                                First Name
                                <input
                                    value={newFirstName}
                                    onChange={(e) => {
                                        setNewFirstName(e.target.value);
                                    }}/>
                            </label>
                            <label>
                                Last Name
                                <input
                                    value={newLastName}
                                    onChange={(e) => {
                                        setNewLastName(e.target.value);
                                    }}/>
                            </label>
                            <label>
                                Account Type
                                <SingleSelector options={roleOptions} changeHandler={setNewSelectedRole}/>
                            </label>
                            <label >
                                Email
                                <input
                                    value={newEmail}
                                    onChange={(e) => {
                                        setNewEmail(e.target.value);
                                    }}/>
                            </label>
                            <div className={'save-row'}>
                                <button style={{backgroundColor:Colors.blue,color:'#FFF'}} className={'simple-rounded-button small approved-only'} onClick={submitNewUser}><span>Send Invite</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

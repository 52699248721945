import React, {useEffect, useRef} from 'react';
import {CSSTransition} from "react-transition-group";
import {clearAllBodyScrollLocks, disableBodyScroll} from "body-scroll-lock";

export default function Popup({children, closeHandler,tabbed,simple,bodyClass}){

    const popupRef = useRef();

    useEffect(()=>{
        if(popupRef.current) {
            disableBodyScroll(popupRef.current.targetElement);
        }
        return()=> {
            clearAllBodyScrollLocks();
        }


    },[]);

    return(

        <CSSTransition in={true}
                       appear={true}
                       timeout={{
                           appear: 500,
                           enter: 300,
                           exit: 500,
                       }}
                       classNames="fade">
            <div ref={popupRef} key={'popup'} onMouseDown={(e)=>{if(e.target.classList.contains('popup-holder') && simple && closeHandler){closeHandler()}}} className={'popup-holder'}>

                <div className={'popup-body ' + (bodyClass ||'') + (tabbed?' tabbed ':'') + (simple?' simple ':'')}>
                    {(!simple && closeHandler) && <div onClick={()=>{closeHandler()}} className={'popup-close'}><img src={'/images/cross-gray.svg'} />Close</div>}

                    {children}
                </div>

            </div>
        </CSSTransition>

    )
}

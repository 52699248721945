import React, {useState} from 'react';
import WhatIsThrivePopup from "./popups/whatIsThrivePopup";

export default function WhatIsThriveButton(){
	const [showPopup,setShowPopup] = useState(false);

	const closePopup = () =>{
		setShowPopup(false);
	};
	return (
		<>
			{showPopup && <WhatIsThrivePopup closeHandler={closePopup} />}
			<span onClick={()=>{setShowPopup(true)}} className={'clickable'}><img src={'/images/question-blue.svg'} alt={''} /> What is This?</span>
		</>
	)

}

import React from 'react'
import {useLocation} from "react-router";
import NoResults from "../noResults";
import axios from "axios";

export default function DebitsList({files,accentColor}){
    const location = useLocation();

    function downloadFile(path){
        axios.get('/auth/generate-token').then(response=>{
            if(response.data.token){
                window.open(path+response.data.token, '_blank');
            }
        }).catch(e=>{})
    }

    return (
        <div className={'invoices-list dashboard-list'}>
            {location.state.layout!=='mobileMax' &&
            <div className={'table-row file-row header'}>
                <span>Date</span>
                <span>Amount</span>
                <span>Processor</span>
                <span>Download details</span>
            </div>
            }
            {location.state.layout!=='mobileMax' && files.map((file)=>{
                return (
                    <div key={file.organization_debit_timestamp+file.organization_debit_processor} className={'table-row  file-row '}>
                        <span >{new Date(file.organization_debit_timestamp.replace(' ','T')).toLocaleDateString()}</span>
                        <span >${file.debit_total}</span>
                        <span >{file.organization_debit_processor}</span>
                        <span><a className={'invoice'}
                                 onClick={e=>{e.preventDefault();downloadFile(file.download_url)}}
                                 href={'#'}
                        ><span style={{color: accentColor}} className={'mdi mdi-file'}/>
                        </a> </span>

                    </div>
                )
            })}
            {(location.state.layout==='mobileMax')  && files.map((file)=>{
                return (
                    <div key={file.organization_debit_timestamp+file.organization_debit_processor} className={'table-row  file-row tablet ' + (file.flagged?'flagged':'')}>
                        <div>
                            <span className={'title'}>Date</span>
                            <span >{new Date(file.organization_debit_timestamp.replace(' ','T')).toLocaleDateString()}</span>
                        </div>
                        <div>
                            <span className={'title'}>Amount</span>
                            <span >${file.debit_total}</span>
                        </div>
                        <div>
                            <span className={'title'}>Processor</span>
                            <span >{file.organization_debit_processor}</span>
                        </div>
                        <div>
                                <span><a className={'invoice'}
                                         onClick={e=>{e.preventDefault();downloadFile(file.download_url)}}
                                         href={'#'}
                                ><span style={{color: accentColor}} className={'mdi mdi-file'}/>
                                </a> </span>
                        </div>
                    </div>
                )
            })}
            {files.length<=0 && <NoResults />}
        </div>
    )

}



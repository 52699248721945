import React, { useEffect, useState} from 'react';
import PayoffCalculatorButton from "../../payoffCalculatorButton";
import axios from 'axios';
import NumberFormat from "react-number-format";
import WhatIsThriveButton from "../../whatIsThriveButton";
import {contributionSettingsCalculateUrl} from "../../../_constants";
import InfoPopup from "../../popups/infoPopup";
import {formatExternalLink} from "../../../_helpers";

export default function ElectContributionSlide({layout, organization,user,changeHandler, authToken,nextHandler}){

	const [updateKey,setUpdateKey] = useState(Math.random());
	const [inputType,setInputType] = useState('flat');
	const [retirementInputType,setRetirementInputType] = useState('percentage');
	const [availableRows,setAvailableRows] = useState([]);
	const [showMaximized,setShowMaximized] = useState(false);
	const [availableContributionTypes,setAvailableContributionTypes] = useState([]);
	const [availableColumns,setAvailableColumns] = useState([]);
	const [calculateCancelToken,setCalculateCancelToken] = useState(axios.CancelToken.source());
	const [showRetirementInstruction,setShowRetirementInstructions] = useState(false);
	const [hasSeenRetirementInstructions,setHasSeenRetirementInstructions] = useState(false);
	const [contributionSettings,setContributionSettings] = useState({
		user_contribution_setting_eligible_compensation: '',
		user_contribution_setting_program_amount: '',
		user_contribution_setting_program_type: '',
		user_contribution_setting_program_contribution_flat: '',
		user_contribution_setting_program_contribution_percentage: '',
		user_contribution_setting_program_match_flat: '',
		user_contribution_setting_program_match_percentage: '',
		user_contribution_setting_program_total_flat: '',
		user_contribution_setting_retirement_amount: '',
		user_contribution_setting_retirement_type: '',
		user_contribution_setting_retirement_contribution_flat: '',
		user_contribution_setting_retirement_contribution_percentage: '',
		user_contribution_setting_retirement_match_flat: '',
		user_contribution_setting_retirement_match_percentage: '',
		user_contribution_setting_retirement_total_flat: '',
		user_contribution_setting_match_total_flat: '',
		user_contribution_setting_match_total_percentage: '',
		user_contribution_setting_contribution_total_flat: '',
		user_contribution_setting_contribution_total_percentage: '',
		user_contribution_setting_contribution_is_match_maximized: false
	});

	const [accountTypesString,setAccountTypesString] = useState('');

	const shouldShowTotalContributionColumn = () =>{
		if(!organization){
			return false;
		}
		return organization.plan_token !== 'employer-direct' && organization.plan_token !== 'payroll-deduction';
	}

	const getPostData = (newInputType = null,newRetirementType=null,newSettings = null) =>{
		newInputType = newInputType || inputType;
		newRetirementType = newRetirementType || retirementInputType;
		newSettings = newSettings || contributionSettings;
		if(!newInputType || !newSettings){
			return false;
		}
		let programAmount = parseFloat(
			newInputType==='percentage'?
				newSettings.user_contribution_setting_program_contribution_percentage:
				newSettings.user_contribution_setting_program_contribution_flat
		);
		let retirementAmount = parseFloat(
			newRetirementType==='percentage'?
				newSettings.user_contribution_setting_retirement_contribution_percentage:
				newSettings.user_contribution_setting_retirement_contribution_flat
		);
		let compensationAmount = newSettings.user_contribution_setting_eligible_compensation?parseFloat(newSettings.user_contribution_setting_eligible_compensation.toString().replace(/[^0-9\-.]/g,'')):null;

		if(isNaN(programAmount)){
			programAmount = 0;
		}
		if(isNaN(retirementAmount)){
			retirementAmount = 0;
		}
		if(isNaN(compensationAmount)){
			compensationAmount = 0;
		}
		let postData = {
			user_contribution_setting_program_amount: programAmount,
			user_contribution_setting_program_type: newInputType,
			user_contribution_setting_retirement_amount: retirementAmount,
			user_contribution_setting_retirement_type: retirementInputType,
			user_contribution_setting_eligible_compensation: compensationAmount,
		};
		if(organization.plan_token === 'employer-direct' && newSettings['contribution_tier_value']){
			postData.user_contribution_settings['contribution_tier_id'] = newSettings['contribution_tier_id'];

		}
		console.log(postData);
		return postData;
	}

	useEffect(()=>{

		let newValues = {
			user_contribution_setting_program_amount: 0,
			user_contribution_setting_program_type: 'percentage',
			user_contribution_setting_retirement_amount: 0,
			user_contribution_setting_retirement_type: 'percentage',
			user_contribution_setting_eligible_compensation: 0,
		};
		const newCancelToken = axios.CancelToken.source();
		setCalculateCancelToken(newCancelToken);
		axios.get('/organizations/'+organization.organization_id+'/users/'+user.user_id, {cancelToken:newCancelToken.token,headers: {'Auth-token': authToken}}).then(response=>{
			if(response.data.user){
				setUpdateKey(Math.random());
				let userInfo = response.data.user;
				axios.post(contributionSettingsCalculateUrl(organization.organization_id,user.user_id),{user_contribution_settings:response.data.user},{headers: {'Auth-token': authToken}}).then(response=>{
					if(!response.data.user_contribution_settings.user_contribution_setting_eligible_compensation){
						response.data.user_contribution_settings.user_contribution_setting_eligible_compensation = '0';
					}
					if(organization.plan_token === 'employer-direct' && userInfo['contribution_tier_value']){
						response.data.user_contribution_settings['user_contribution_setting_program_match_flat'] = userInfo['contribution_tier_value'];
						response.data.user_contribution_settings['contribution_tier_id'] = userInfo['contribution_tier_id'];

					}
					setInputType(response.data.user_contribution_settings['user_contribution_setting_program_type']);
					setRetirementInputType(response.data.user_contribution_settings['user_contribution_setting_retirement_type']);
					setContributionSettings(response.data.user_contribution_settings);
					setUpdateKey(Math.random());
				}).catch(e=>{});
			}else{
				axios.post(contributionSettingsCalculateUrl(organization.organization_id,user.user_id),{user_contribution_settings:newValues},{headers: {'Auth-token': authToken}}).then(response=>{
					if(!response.data.user_contribution_settings.user_contribution_setting_eligible_compensation){
						response.data.user_contribution_settings.user_contribution_setting_eligible_compensation = 0;
					}
					setContributionSettings(response.data.user_contribution_settings);
					setUpdateKey(Math.random());
				}).catch(e=>{});
			}

		}).catch(e=>{});

		return ()=>{
			calculateCancelToken.cancel('');
		}
	},[]);

	useEffect(()=>{
		const newValues = getPostData();
		if(!newValues){
			return;
		}
		changeHandler(newValues);
	},[updateKey,contributionSettings]);

	useEffect(()=>{
		if(!inputType){
			return;
		}
		contributionSettings.user_contribution_setting_program_type = inputType;

		setContributionSettings(contributionSettings);
		setUpdateKey(Math.random())
	},[inputType]);


	const sendValuesToApi = (newInputType,newSettings) =>{
		const newValues = getPostData(newInputType,null,newSettings);
		const newCancelToken = axios.CancelToken.source();
		if(!newValues){
			return;
		}
		setCalculateCancelToken(newCancelToken);
		axios.post(contributionSettingsCalculateUrl(organization.organization_id,user.user_id),{user_contribution_settings:newValues}, {cancelToken:newCancelToken.token,headers: {'Auth-token': authToken}}).then(response=>{
			if(!response.data.user_contribution_settings.user_contribution_setting_eligible_compensation){
				response.data.user_contribution_settings.user_contribution_setting_eligible_compensation = 0;
			}
			if(organization.plan_token === 'employer-direct' && newValues['contribution_tier_value']){
				response.data.user_contribution_settings['user_contribution_setting_program_match_flat'] = newValues['contribution_tier_value'];
				response.data.user_contribution_settings['contribution_tier_id'] = newValues['contribution_tier_id'];
			}
			setContributionSettings(response.data.user_contribution_settings);
			// setUpdateKey(Math.random());
		}).catch(e=>{});
	};

	const handleRetirementFocus = () =>{
		if(!hasSeenRetirementInstructions){
			setShowRetirementInstructions(true);
		}
	};

	const updateContributionSetting = (key,value) =>{
		calculateCancelToken.cancel('');
		if(!value){
			return;
		}

		let newSettings = contributionSettings;
		if(!newSettings.hasOwnProperty(key)){
			return;
		}
		if(newSettings[key] === value){
			return;
		}
		let newInputType = inputType;

		newSettings[key] = value;
		setContributionSettings(newSettings);
		setUpdateKey(Math.random());
		sendValuesToApi(newInputType,newSettings)
	};

	useEffect(()=>{
		if(!organization){
			return;
		}
		if(organization.plan_token === 'payroll-deduction'){
			setAvailableColumns( ['your-contribution']);
		}else if(organization.plan_token === 'employer-direct'){
			setAvailableColumns(['employer-contribution']);
		}else{
				setAvailableColumns(['your-contribution','employer-contribution']);
		}
		setAvailableContributionTypes(organization.organization_contribution_setting_contribution_type);
		if(organization.plan_token !== 'flexible-match'){
			setAvailableRows( ['thrive']);
		}else {
			setAvailableRows( [ 'retirement','thrive']);
		}
		setShowMaximized(organization.plan_token === 'flexible-match' || organization.plan_token === 'employer-match')

		let types = organization.organization_account_types.filter(a=>a);
		let newString = '';
		let lastType = '';
		if(types.length>0){
			lastType = types.pop().account_type_title;
		}
		if(types.length>1) {
			types.forEach((type) => {
				newString += type.account_type_title + ', ';
			});
		}else if(types.length===1){
			newString += types[0].account_type_title;
		}
		if(types.length>0){
			newString += ' and/or '
		}
		newString += lastType;
		setAccountTypesString(newString);
	},[organization]);

	useEffect(()=>{
		if(!availableContributionTypes){
			return;
		}
		if(availableContributionTypes.includes('percentage')){
			setInputType('percentage');
		}else{
			setInputType('flat')
		}
	},[availableContributionTypes]);



	return (
		<>
			{(showRetirementInstruction && organization.organization_retirement_setting_description) &&
				<InfoPopup closeHandler={()=>{setShowRetirementInstructions(false);setHasSeenRetirementInstructions(true)}}
				           title={'Retirement Plan Changes'}
				           info={organization.organization_retirement_setting_description} />
			}
		<div className={'two-column-signup max-width-container'}>
			{layout === 'desktop' &&
				<div className={'left'}>
					{(organization && organization.plan_token !== 'payroll-deduction') &&
						<h1 className={'title'}>Elect Your Monthly Thrive Contributions</h1>
					}
					{(organization && organization.plan_token === 'payroll-deduction') &&
						<h1 className={'title'}>Elect Your Payroll Deduction Amount</h1>
					}
					{(organization && organization.plan_token !== 'employer-direct') &&
						<>
					<div className={'contribution-columns'}>

						<label>
							Monthly Income
							<div
								className={'input-holder dollar flipped ' + (availableContributionTypes && availableContributionTypes.length <= 1 ? 'fill' : '')}>
								<div className={'input'}>
									<span>$</span>
									<NumberFormat
										value={contributionSettings.user_contribution_setting_eligible_compensation}
										thousandSeparator={true}
										decimalScale={0}
										onValueChange={(values) => {
											const {formattedValue, value} = values;
											updateContributionSetting('user_contribution_setting_eligible_compensation', value)
										}}
									/>
								</div>
							</div>
						</label>

						{(availableContributionTypes && availableContributionTypes.length > 1) &&
						<label>
							Contribution Type
							<div className={'input-holder split'}>
								{(availableContributionTypes.includes('percentage')) &&
								<div
									className={'input with-border'}>
									<div onClick={() => {
										setInputType('percentage')
									}}
									     className={' clickable radio-button ' + (inputType === 'percentage' ? 'selected' : '')}/>
									<span>%</span>
								</div>
								}
								{(availableContributionTypes.includes('flat')) &&
								<div
									className={'input with-border'}>
									<div onClick={() => {
										setInputType('flat')
									}}
									     className={'clickable radio-button ' + (inputType === 'flat' ? 'selected' : '')}/>
									<span>$</span>
								</div>
								}
							</div>
						</label>
						}
					</div>
					</>
					}
					<p className={'page-copy'}>
						{(organization && organization.plan_token === 'flexible-match') &&
						<>
							The Thrive Calculator will adjust to illustrate how much Employer matching dollars you will be entitled to based on your monthly income and your current retirement allocation.
						</>
						}
						{(organization && organization.plan_token === 'employer-match') &&
						<>
							The Thrive Calculator will adjust to illustrate how much Employer Benefit Dollars you will be entitled to based on your contribution.
						</>
						}
						{(organization && organization.plan_token === 'payroll-deduction') &&
							<>
								Set up how much you would like deducted from your payroll on a monthly basis to fund your Thrive Account
							</>
						}
						{(organization && organization.plan_token === 'employer-direct') &&
							<>
								Your Employer is offering a benefit of ${Math.round(contributionSettings.user_contribution_setting_program_match_flat)} towards your {accountTypesString}.
								If you are allocating this benefit towards your student loans, you can
								utilize the Loan Payoff Calculator below to see how this benefit will trim
								years and dollars off of your student loan.
							</>
						}
					</p>
					{(organization && organization.organization_contribution_setting_special_instructions) &&
						<p className={'page-copy'}>
							{organization.organization_contribution_setting_special_instructions}
						</p>
					}

						<PayoffCalculatorButton inverted={true}/>

				</div>
			}
			{layout !== 'desktop' &&
			<div className={'left'}>
				<div className={'row title-row'}>
					<h1 className={'title'}>Elect Your Monthly Thrive Contributions</h1>
					<PayoffCalculatorButton inverted={true}/>
				</div>
				<div className={''}>
					<p className={(organization && organization.organization_contribution_setting_special_instructions)?'page-copy':''}>
						{(organization && organization.plan_token === 'flexible-match') &&
						<>
							The Thrive Calculator will adjust to illustrate how much Employer matching dollars you will be entitled to based on your monthly income and your current retirement allocation.
						</>
						}
						{(organization && organization.plan_token === 'employer-match') &&
						<>
							The Thrive Calculator will adjust to illustrate how much Employer Benefit Dollars you will be entitled to based on your contribution.
						</>
						}
						{(organization && organization.plan_token === 'payroll-deduction') &&
						<>
							Set up how much you would like deducted from your payroll on a monthly basis to fund your Thrive Account
						</>
						}
						{(organization && organization.plan_token === 'employer-direct') &&
						<>
							Your Employer is offering a benefit of ${Math.round(contributionSettings.user_contribution_setting_program_match_flat)} towards your {accountTypesString}.
							If you are allocating this benefit towards your student loans, you can
							utilize the Loan Payoff Calculator below to see how this benefit will trim
							years and dollars off of your student loan.
						</>
						}
					</p>
					{(organization && organization.organization_contribution_setting_special_instructions) &&
					<p className={''}>
						{organization.organization_contribution_setting_special_instructions}
					</p>
					}
				</div>
				{(organization && organization.plan_token !== 'employer-direct') &&
				<div className={'row'}>
					<div className={'contribution-columns signup-top'}>

						<label>
							Monthly Income
							<div
								className={'input-holder dollar flipped ' + (availableContributionTypes && availableContributionTypes.length <= 1 ? 'fill' : '')}>
								<div className={'input'}>
									<span>$</span>
									<NumberFormat
										value={contributionSettings.user_contribution_setting_eligible_compensation}
										disabled={true}
										thousandSeparator={true}
										decimalScale={0}
										onValueChange={(values) => {
											const {formattedValue, value} = values;
											updateContributionSetting('user_contribution_setting_eligible_compensation', value)
										}}
									/>

								</div>
							</div>
						</label>

						{(availableContributionTypes && availableContributionTypes.length > 1) &&
						<label>
							Contribution Type
							<div className={'input-holder split'}>
								<div className={'input with-border'}>
									<div onClick={() => {
										setInputType('percentage')
									}}
									     className={' clickable radio-button ' + (inputType === 'percentage' ? 'selected' : '')}/>
									<span>%</span>
								</div>
								<div className={'input with-border'}>
									<div onClick={() => {
										setInputType('flat')
									}}
									     className={'clickable radio-button ' + (inputType === 'flat' ? 'selected' : '')}/>
									<span>$</span>
								</div>
							</div>
						</label>
						}
					</div>
				</div>
				}
			</div>
			}
			<div className={'right'}>
				<div className={'signup-calculator'}>
					<div>
						<div className={'contribution-block'}>
							{availableRows.includes('retirement') &&
								<div className={'contribution-row'}>
									<div className={'title retirement-title'}>
										<div>
											<img src={'/images/retirement.svg'}/>
											Retirement
										</div>
										{(organization && organization.organization_retirement_setting_logo_url && organization.organization_retirement_setting_url) &&
										<a href={formatExternalLink(organization.organization_retirement_setting_url)} target={'_blank'} rel={'noreferrer noopener'} className={'retirement-info'}>
											<img src={organization.organization_retirement_setting_logo_url} />
											{organization.organization_retirement_setting_title && <span>{organization.organization_retirement_setting_title}</span>}
										</a>
										}
									</div>
									<div className={'contribution-columns '+(availableColumns.length<2?'col-2':'')}>
										{availableColumns.includes('your-contribution') &&
											<label>
												Your Monthly Contribution
												<div className={'input-holder split'}>
													<div className={'input'}>
														<NumberFormat value={contributionSettings.user_contribution_setting_retirement_contribution_percentage}
														              disabled={!availableContributionTypes}
														              thousandSeparator={true}
														              className={(retirementInputType === 'percentage' ? 'active-type' : '')}
														              suffix={'%'}
														              maxLength={5}
														              decimalScale={2}
														              onFocus={()=>{
														              	handleRetirementFocus();
															              setRetirementInputType('percentage');
														              }}
														              onValueChange={(values) => {
																	const {formattedValue, value} = values;
															              if(retirementInputType === 'percentage') {
																              updateContributionSetting('user_contribution_setting_retirement_contribution_percentage', value)
															              }
																}}
														/>

													</div>
													<div className={'input'}>
														<NumberFormat value={contributionSettings.user_contribution_setting_retirement_contribution_flat}
														              disabled={true}
														              thousandSeparator={true}
														              className={(retirementInputType === 'flat' ? 'active-type' : '')}
														              prefix={'$'}
														              decimalScale={2}
														              onFocus={()=>{
															              handleRetirementFocus();
															              setRetirementInputType('flat');
														              }}
														              onValueChange={(values) => {
															              const {formattedValue, value} = values;
															              if(retirementInputType === 'flat') {
																              updateContributionSetting('user_contribution_setting_retirement_contribution_flat', value)
															              }
														              }}
														/>
													</div>
												</div>
											</label>
										}
										{availableColumns.includes('employer-contribution') &&
										<label>
											Employer Monthly Contribution
											<div className={'input-holder split'}>
												<NumberFormat value={contributionSettings.user_contribution_setting_retirement_match_percentage}
												              thousandSeparator={true}
												              className={'input read-only'}
												              suffix={'%'}
												              maxLength={5}
												              decimalScale={2}
												              displayType={'text'}
												/>
												<NumberFormat value={contributionSettings.user_contribution_setting_retirement_match_flat}
												              thousandSeparator={true}
												              className={'input read-only'}
												              prefix={'$'}
												              decimalScale={2}
												              displayType={'text'}
												/>
											</div>
										</label>
										}
										{shouldShowTotalContributionColumn() &&
										<label>
											Total Monthly Contribution
											<div className={'input-holder single'}>
												<NumberFormat
													value={contributionSettings.user_contribution_setting_retirement_total_flat}
													thousandSeparator={true}
													className={'input read-only'}
													prefix={'$'}
													decimalScale={2}
													displayType={'text'}
												/>
											</div>
										</label>
										}
									</div>
								</div>
							}
							{availableRows.includes('thrive') &&
								<div className={'contribution-row desktop'}>
									<div className={'title'}><img src={'/images/thrive-contribution.svg'}/>
										<div>
											Thrive
											<WhatIsThriveButton/>
										</div>
									</div>
									<div className={'contribution-columns '+(availableColumns.length<2?'col-2':'')}>
										{availableColumns.includes('your-contribution') &&
											<label>
												Your Monthly Contribution
												<div className={'input-holder split'}>
													<div className={'input'}>
														<NumberFormat value={contributionSettings.user_contribution_setting_program_contribution_percentage}
														              disabled={!availableContributionTypes || !availableContributionTypes.includes('percentage')}
														              thousandSeparator={true}
														              className={(inputType === 'percentage' ? 'active-type' : '')}
														              suffix={'%'}
														              maxLength={5}
														              decimalScale={2}
														              onValueChange={(values) => {
															              const {formattedValue, value} = values;
															              updateContributionSetting('user_contribution_setting_program_contribution_percentage',value)
														              }}
														              onFocus={()=>{setInputType('percentage');}}
														/>
													</div>
													<div className={'input'}>
														<NumberFormat value={contributionSettings.user_contribution_setting_program_contribution_flat}
														              disabled={!availableContributionTypes || !availableContributionTypes.includes('flat')}
														              thousandSeparator={true}
														              className={(inputType === 'flat' ? 'active-type' : '')}
														              prefix={'$'}
														              decimalScale={2}
														              onValueChange={(values) => {
															              const {formattedValue, value} = values;
															              updateContributionSetting('user_contribution_setting_program_contribution_flat',value)
														              }}
														              onFocus={()=>{setInputType('flat');}}
														/>
													</div>
												</div>
											</label>
										}
										{availableColumns.includes('employer-contribution') &&
											<label>
												Employer Monthly Contribution
												<div className={'input-holder split'}>
													{(organization && organization.plan_token !== 'employer-direct') &&
													<>
														<NumberFormat
															value={contributionSettings ? contributionSettings.user_contribution_setting_program_match_percentage : 0}
															thousandSeparator={true}
															className={'input read-only'}
															suffix={'%'}
															maxLength={5}
															decimalScale={2}
															displayType={'text'}
															/>

														< NumberFormat value={contributionSettings?contributionSettings.user_contribution_setting_program_match_flat:0}
															thousandSeparator={true}
															className={'input read-only'}
															prefix={'$'}
															decimalScale={2}
															displayType={'text'}
															/>
													</>
													}
													{(organization && organization.plan_token === 'employer-direct' && !user.user_contribution_setting_override_amount) &&
														< NumberFormat value={contributionSettings?contributionSettings.user_contribution_setting_program_match_flat:0}
													               thousandSeparator={true}
													               className={'input read-only ' + ((organization && organization.plan_token === 'employer-direct')?'single':'')}
													               prefix={'$'}
													               decimalScale={2}
													               displayType={'text'}
														/>
													}
													{(organization && organization.plan_token === 'employer-direct' && user.user_contribution_setting_override_amount) &&
													< NumberFormat value={user.user_contribution_setting_override_amount}
													               thousandSeparator={true}
													               className={'input read-only ' + ((organization && organization.plan_token === 'employer-direct')?'single':'')}
													               prefix={'$'}
													               decimalScale={2}
													               displayType={'text'}
													/>
													}
												</div>
												{(contributionSettings && contributionSettings.hasOwnProperty('tenure_requirement_met') && !contributionSettings.tenure_requirement_met) &&
													<div className={'tenure-required'}>
														*Tenure requirement not met.
														<span className={'tooltip'}>
															You will meet the tenure requirement for the employer match in {organization.organization_contribution_setting_min_tenure_length - contributionSettings.tenure_months} month{organization.organization_contribution_setting_min_tenure_length - contributionSettings.tenure_months === 1?'':'s'}.
														</span>
													</div>
												}
											</label>
										}
										{shouldShowTotalContributionColumn() &&
										<label>
											Total Monthly Contribution
											<div className={'input-holder single'}>
												<NumberFormat
													value={contributionSettings ? contributionSettings.user_contribution_setting_program_total_flat : 0}
													thousandSeparator={true}
													className={'input read-only'}
													prefix={'$'}
													decimalScale={2}
													displayType={'text'}
												/>
											</div>
										</label>
										}
									</div>
								</div>
							}
						</div>
						{shouldShowTotalContributionColumn() &&
						<div className={'contribution-block totals'}>
							<div className={'contribution-row desktop'}>
								<div className={'title'}><img src={'/images/totals.svg'}/>Totals</div>
								<div className={'contribution-columns ' + (availableColumns.length < 2 ? 'col-2' : '')}>
									{availableColumns.includes('your-contribution') &&
									<label>
										Your Monthly Contribution
										<div className={'input-holder split'}>
											<NumberFormat
												value={contributionSettings ? contributionSettings.user_contribution_setting_contribution_total_percentage : 0}
												thousandSeparator={true}
												className={'input read-only'}
												suffix={'%'}
												maxLength={5}
												decimalScale={2}
												displayType={'text'}
											/>
											<NumberFormat
												value={contributionSettings ? contributionSettings.user_contribution_setting_contribution_total_flat : 0}
												thousandSeparator={true}
												className={'input read-only'}
												prefix={'$'}
												decimalScale={2}
												displayType={'text'}
											/>
										</div>
									</label>
									}
									{availableColumns.includes('employer-contribution') &&
									<label>
										Employer Monthly Contribution
										<div className={'input-holder split'}>
											<NumberFormat
												value={contributionSettings ? contributionSettings.user_contribution_setting_match_total_percentage : 0}
												thousandSeparator={true}
												className={'input read-only'}
												suffix={'%'}
												maxLength={5}
												decimalScale={2}
												displayType={'text'}
											/>
											<NumberFormat
												value={contributionSettings ? contributionSettings.user_contribution_setting_match_total_flat : 0}
												thousandSeparator={true}
												className={'input read-only'}
												prefix={'$'}
												decimalScale={2}
												displayType={'text'}
											/>
										</div>
									</label>
									}
									{showMaximized &&
									<label>
										Total Match
										<div className={'input-holder contribution-status'}>
											<div
												className={'input read-only ' + (contributionSettings && contributionSettings.user_contribution_setting_contribution_is_match_maximized ? '' : 'error')}>{contributionSettings && contributionSettings.user_contribution_setting_contribution_is_match_maximized ? 'Maximized' : 'Not Maximized'}</div>
										</div>
									</label>
									}
								</div>
							</div>
						</div>
						}
						{(organization.organization_contribution_setting_match_description && organization && organization.plan_token !== 'employer-direct') &&
							<div className={'contribution-disclosure'} dangerouslySetInnerHTML={{__html:organization.organization_contribution_setting_match_description.replace(/\$?[0-9\.]+%?/g, '<strong>$&</strong>')}} />
						}
						{(organization && organization.plan_token === 'employer-direct') &&
							<div style={{display:'flex',justifyContent:'flex-end'}}>
								<button onClick={()=>{nextHandler()}} className={'simple-rounded-button huge blue'}><span>Sounds Great! Let's Register your Accounts!</span></button>
							</div>
						}

					</div>
				</div>
			</div>
		</div>
		</>
	)
}

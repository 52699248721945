import React, {useEffect, useState} from 'react';
import {Switch, useRouteMatch, Route, useHistory, Redirect, useLocation} from "react-router";
import SubNav from "../../components/nav/subNav";
import {EmployeeSecondaryMenu, EmployeeSecondaryMenuNoEvoshare, EmployerSecondaryMenu} from "../../_menus";
import {Colors, EMPLOYEE_TYPE} from "../../_constants";
import {useStoreActions, useStoreState} from "easy-peasy";
import EmployeeDashboardHeader from "../../components/employeeDashboardHeader";
import EmployeeActivity from "../employee/dashboard/employeeActivity";
import ContributionSettings from "../employee/dashboard/contributionSettings";
import EmployeeAccounts from "./dashboard/emplyeeAccounts";
import Evoshare from "./dashboard/evoshare";
import HasUnverifiedAccountsPopup from "../../components/popups/hasUnverifiedAccountsPopup";

export default function EmployeeDashboard(){
	const location = useLocation();
	const history = useHistory();
	const activeOrganization = useStoreState(state=>state.user.activeOrganization);
	const hasUnverifiedAccounts = useStoreState(state=>state.user.hasUnverifiedAccounts);
	const hasAcknowledgedUnverifiedAccounts = useStoreState(state=>state.user.acknowledgedUnverifiedAccounts)
	const setHasAcknowledgedUnverifiedAccounts = useStoreActions(actions=>actions.user.setAcknowledgedUnverifiedAccounts)
	const [secondaryMenu,setSecondaryMenu] = useState([]);
	const userType = useStoreState(state=>state.user.type);

	const updateMenu = () =>{
		if(!activeOrganization){
			return;
		}
		if(activeOrganization.evoshare_organization_token){
			setSecondaryMenu(EmployeeSecondaryMenu)
		}else{
			setSecondaryMenu(EmployeeSecondaryMenuNoEvoshare);
		}
	}

	useEffect(()=>{
		if(userType !== EMPLOYEE_TYPE){
			history.push('/')
		}
	},[userType]);
	useEffect(()=>{
		if(location.pathname === '/employee/'){
			history.push('/employee/activity');
		}
	},[location]);
	useEffect(()=>{
		updateMenu();
	},[activeOrganization]);

	useEffect(()=>{
		updateMenu();
	},[])
	return (
		<>
			{(!hasAcknowledgedUnverifiedAccounts && hasUnverifiedAccounts) &&
				<HasUnverifiedAccountsPopup closeHandler={()=>{setHasAcknowledgedUnverifiedAccounts(true)}} />
			}
			<Switch>
				<Route path={'/employee/activity'}>
					<EmployeeDashboardHeader header={headers.activity} />
				</Route>
				<Route path={'/employee/contributions'}>
					<EmployeeDashboardHeader header={headers.contributions} />
				</Route>
				<Route path={'/employee/accounts'}>
					<EmployeeDashboardHeader header={headers.accounts} />
				</Route>
				<Route path={'/employee/evoshare'}>
					<EmployeeDashboardHeader header={headers.evoshare} />
				</Route>
			</Switch>

			<div id={'dashboard-details'} className={'max-width-container inner-section'}>
				{secondaryMenu.length > 0 &&
					<SubNav menu={secondaryMenu} baseUrl={'/employee'}/>
				}
				<div className={'dashboard-content'}>
					<Switch>
						<Route path={'/employee/activity'}>
							<EmployeeActivity accentColor={headers.activity.color}/>
						</Route>
						<Route path={'/employee/contributions'}>
							<ContributionSettings accentColor={headers.contributions.color}/>
						</Route>
						<Route path={'/employee/accounts'}>
							<EmployeeAccounts accentColor={headers.accounts.color}/>
						</Route>
						<Route path={'/employee/evoshare'}>
							<Evoshare accentColor={headers.evoshare.color}/>
						</Route>
					</Switch>
				</div>
			</div>
		</>

	)
}

const headers = {
	activity:{
		color:Colors.blue,
		subTitle:'Access Your Account Activity Details Below'
	},
	contributions:{
		color:Colors.teal,
		subTitle:'Contributions last updated on %last_date'
	},
	accounts:{
		color:Colors.listBlue,
		subTitle:'Account last updated on %last_date'
	},
	evoshare:{
		color:Colors.orange,
		subTitle:'Access your Evoshare  Details Below'
	}
};

import React from 'react'
import {useLocation} from "react-router";
import {Colors} from "../../_constants";

export default function PayrollListPlaceholder(){
    const location = useLocation();
    const files = [1,2,3];
    return (
        <div className={'payroll-list dashboard-list'}>
            {location.state.layout!=='mobileMax' &&
                <div className={'table-row file-row header'}>
                    <span >File name</span>
                    <span >Uploaded By</span>
                    <span >Upload Date</span>
                    <span >Status</span>
                    <span >Download</span>
                </div>
            }
            {location.state.layout!=='mobileMax' && files.map((file)=>{
                return (
                    <div key={file} className={'table-row  file-row '}>
                        <span className={'placeholder'} />
                        <span className={'placeholder'} />
                        <span className={'placeholder'} />
                        <span className={'placeholder'} />

                        <span className={'placeholder'} />
                    </div>
                )
            })}
            {(location.state.layout==='mobileMax')  && files.map((file)=>{
                return (
                    <div key={file} className={'table-row  file-row tablet '}>
                        <div>
                            <span className={'title'}>File name</span>
                            <span className={'placeholder'} />
                        </div>
                        <div>
                            <span className={'title'}>Uploaded By</span>
                            <span className={'placeholder'} />
                        </div>
                        <div>
                            <span className={'title'}>Upload Date</span>
                            <span className={'placeholder'} />
                        </div>
                        <div>
                            <span className={'title'}>Status</span>
                            <span className={'placeholder'} />
                        </div>
                        <div>
                                <span className={'placeholder'} />
                        </div>
                    </div>
                )
            })}
        </div>
    )

}



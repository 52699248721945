import React from 'react';
import {ADMIN_TYPE, Colors, EMPLOYER_CONTACT_TYPE, HR_TYPE} from "../../_constants";
import {useLocation} from "react-router";
import NoResults from "../noResults";

export default function UserList({users,viewHandler}){
    const location = useLocation();
    const isUserAdmin = (user) =>{
        return user.roles.filter(role=>role.role_token===ADMIN_TYPE || role.role_token===EMPLOYER_CONTACT_TYPE).length>0;
    }
    const shouldShowUser = (user) =>{
        return user.roles.filter(role=>role.role_token===ADMIN_TYPE || role.role_token===EMPLOYER_CONTACT_TYPE || role.role_token===HR_TYPE).length>0;
    }
    return (
        <div className={'user-list dashboard-list'}>
            {location.state.layout!=='mobileMax' &&
            <div className={'table-row file-row header'}>
                <span>Name</span>
                <span>Email</span>
                <span>Access</span>
                <span>Details</span>
            </div>
            }
            {location.state.layout!=='mobileMax' && users.map((user)=>{
                if(!shouldShowUser(user)){
                    return null;
                }
                return (
                    <div key={user.user_id+user.user_email} className={'table-row  file-row '}>
                        <span >{user.user_display_name}</span>
                        <span >{user.user_email}</span>
                        <span className={'status '+(isUserAdmin(user)?'active ':'inactive ')} >{isUserAdmin(user)?'Admin':'HR'}</span>
                        <span ><button onClick={()=>{viewHandler(user)}} className={'employee'} style={{color:Colors.blue}}>View More</button></span>

                    </div>
                )
            })}
            {(location.state.layout==='mobileMax')  && users.map((user)=>{
                if(!shouldShowUser(user)){
                    return null;
                }
                return (
                    <div key={user.user_id+user.user_email} className={'table-row  file-row tablet'}>
                        <div>
                            <span className={'title'}>Name</span>
                            <span >{user.user_display_name}</span>
                        </div>
                        <div>
                            <span className={'title'}>Email</span>
                            <span >{user.user_email}</span>
                        </div>
                        <div>
                            <span className={'title'}>access</span>
                            <span className={'status '+(isUserAdmin(user)?'active ':'inactive ')} >{isUserAdmin(user)?'Admin':'Non-Admin'}</span>
                        </div>
                        <div>
                            <span ><button onClick={()=>{viewHandler(user)}} className={'employee'} style={{color:Colors.blue}}>View More</button></span>
                        </div>
                    </div>
                )
            })}
            {users.length<=0 && <NoResults />}
        </div>
    )
}

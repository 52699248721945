import React from 'react'
import {useLocation} from "react-router";
import NoResults from "../noResults";

export default function ActivityFileList({files,accentColor,linkClass,viewHandler, clearHandler}){
    const location = useLocation();

    return (
        <div className={linkClass+'-list dashboard-list'}>
            {location.state.layout!=='mobileMax' &&
                <div className={'table-row file-row header'}>
                    <span>Message</span>
                    <span>Name</span>
                    <span>Timestamp</span>
                    <span></span>
                </div>
            }
            {location.state.layout!=='mobileMax' && files.map((file,index)=>{
                if(!file.activity_message){
                    return null;
                }
                return (
                    <div key={index+file.activity_timestamp} className={'table-row  file-row ' + (file.flagged?'flagged':'')}>
                        <span >{file.activity_status==='error' && <img src={'/images/new-alert.svg'} alt={'new alert'} /> }{file.activity_message}</span>
                        <span>{file.profile_first_name + ' ' + file.profile_last_name}</span>
                        <span >{file.activity_timestamp?new Date(file.activity_timestamp.replace(' ','T')).toLocaleDateString():''}</span>
                        <span ><button onClick={()=>{viewHandler(file)}} className={'employee'} style={{color:accentColor}}>View More</button></span>

                    </div>
                )
            })}
            {(location.state.layout==='mobileMax')  && files.map((file,index)=>{
                if(!file.activity_message){
                    return null;
                }
                return (
                    <div key={index+file.activity_timestamp} className={'table-row  file-row tablet ' + (file.flagged?'flagged':'')}>
                        <div>
                            <span className={'title'}>Message</span>
                            <span >{file.activity_status==='error' && <img src={'/images/new-alert.svg'} alt={'new alert'} /> }{file.activity_message}</span>
                        </div>
                        <div>
                            <span className={'title'}>Name</span>
                            <span >{file.profile_first_name + ' ' + file.profile_last_name}</span>
                        </div>
                        <div>
                            <span className={'title'}>Timestamp</span>
                            <span >{file.activity_timestamp? new Date(file.activity_timestamp.replace(' ','T')).toLocaleDateString():''}</span>
                        </div>
                        <div>
                            <span ><button onClick={()=>{viewHandler(file)}} className={'employee'} style={{color:accentColor}}>View More</button></span>
                        </div>
                    </div>
                )
            })}
            {files.length<=0 && <NoResults clearHandler={clearHandler} />}
        </div>
    )

}



import React from 'react';
import {Link} from 'react-router-dom'
import Popup from "./popup";
export default function HasUnverifiedAccountsPopup({closeHandler})
{

	return (
		<Popup closeHandler={closeHandler} bodyClass={'register-accounts'}>
			<div className={'popup-content register-account-screen manual-loan-message'}>
				<span>You currently have at least one unverified account. Two micro-deposits were made to the account(s) and will need to be verified before payments can be made. You should see the deposits within 5-7 business days of linking the account.</span>
				<span>Go to the <Link to={'/employee/accounts'} onClick={e => {
					closeHandler(true)
				}}>'Register Account'</Link> tab and select 'Verify Your micro-deposits' below the account to enter the deposit amounts and verify the account. </span>
			</div>
		</Popup>
	)
}

import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Popup from "./popup";
import ProviderSelector from "../providerSelector";


export default function InstitutionSelectorPopup({subtype,closeHandler,authToken}){

	const [institutions,setInstitutions] = useState([]);
	const [selectedInstitution,setSelectedInstitution] = useState(null);



	useEffect(()=>{
		const cancelToken = axios.CancelToken.source();
		let config = {
			cancelToken:cancelToken.token
		};
		if(authToken){
			config.headers = {'Auth-token': authToken};
		}
		axios.get('/plaid/institutions',config).then(response=>{
			if(response.data.institutions.length === 0){
				closeHandler('new')
			}
			setInstitutions(response.data.institutions);
		}).catch(e=>{});

		return ()=>{
			cancelToken.cancel('');
		}
	},[]);

	useEffect(()=>{
		if(!selectedInstitution){
			return;
		}
		const cancelToken = axios.CancelToken.source();
		let config = {
			cancelToken:cancelToken.token
		};
		if(authToken){
			config.headers = {'Auth-token': authToken};
		}
		axios.get('/plaid/institution-accounts/'+selectedInstitution.plaid_connection_id,config).then(response=>{
			closeHandler(response.data);
		}).catch(e=>{});

		return ()=>{
			cancelToken.cancel('');
		}
	},[selectedInstitution]);



	return (
		<Popup bodyClass={'register-accounts'} >
			<div className={'popup-content register-account-screen'}>

				<>
					<img src={'/images/logo-no-text.svg'} alt={'Thrive'}/>
					<h2>Connected Accounts</h2>
					{institutions.map((institution) => {
							if (!institution.plaid_institution_id) {
								return null;
							}
							return (

								<div onClick={() => {
									setSelectedInstitution(institution)
								}} key={institution.plaid_institution_id} className={'institution-button'}>
									<ProviderSelector inputDisabled={true} isDisplayOnly={true} clickable={true} options={[{
										label: institution.plaid_institution_name,
										value: institution.pliad_institution_id,
										logo: 'data:image/png;base64,' + institution.plaid_institution_logo
									}]}/>
								</div>
							)
						}
					)}
					<button onClick={() => {
						closeHandler('new')
					}} className={'simple-rounded-button small fixed green'}><span><strong>+</strong> Connect Another Account</span>
					</button>
					<span onClick={() => {
						closeHandler(null)
					}} className={'clickable standard-link green'}>Close</span>
				</>

			</div>
		</Popup>
	)
}

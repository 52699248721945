import React from 'react';
import {Colors} from "../../_constants";
import {useLocation} from "react-router";
import NoResults from "../noResults";

export default function MessageList({messages,viewHandler, clearHandler}){

    const location = useLocation();

    return (
        <div className={'message-list dashboard-list'}>
            {location.state.layout!=='mobileMax' &&
            <div className={'table-row file-row header'}>
                <span>Message Subject</span>
                <span>Date Created</span>
                <span>Sent</span>
                <span>Details</span>
            </div>
            }
            {location.state.layout!=='mobileMax' && messages.map((message,index)=>{
                return (
                    <div key={index} className={'table-row  file-row ' + (message.isNew?'new':'')}>
                        <span >{message.announcement_subject}</span>
                        <span >{message.announcement_timestamp?new Date(message.announcement_timestamp.replace(' ','T')).toLocaleDateString():''}</span>
                        <span >{message.announcement_timestamp_sent?new Date(message.announcement_timestamp_sent.replace(' ','T')).toLocaleDateString() : <i>Draft</i>}</span>
                        <span ><button onClick={()=>{viewHandler(message)}} className={'employee'} style={{color:Colors.blue}}>View More</button></span>

                    </div>
                )
            })}
            {(location.state.layout==='mobileMax')  && messages.map((message,index)=>{
                return (
                    <div key={index} className={'table-row  file-row tablet ' + (message.isNew?'new':'')}>
                        <div>
                            <span className={'title'}>Message Title</span>
                            <span >{message.announcement_subject}</span>
                        </div>
                        <div>
                            <span className={'title'}>Date Created</span>
                            <span >{message.announcement_timestamp?new Date(message.announcement_timestamp.replace(' ','T')).toLocaleDateString():''}</span>
                        </div>
                        <div>
                            <span className={'title'}>Sent</span>
                            <span >{message.announcement_timestamp_sent?new Date(message.announcement_timestamp_sent.replace(' ','T')).toLocaleDateString() : <i>Draft</i>}</span>
                        </div>
                        <div>
                            <span ><button onClick={()=>{viewHandler(message)}} className={'employee'} style={{color:Colors.blue}}>View More</button></span>
                        </div>
                    </div>
                )
            })}
            {messages.length<=0 && <NoResults clearHandler={clearHandler} />}
        </div>

    )
}

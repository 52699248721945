import React, {useState} from 'react';
import ContactPopup from "./popups/contactPopup";

export default function Footer(){

    const [showContactForm,setShowContactForm] = useState(false)

    const closeContactForm = () =>{
        setShowContactForm(false);
    };
    return (
        <>
            {showContactForm && <ContactPopup closeHandler={closeContactForm} /> }
            <div className={'footer full-width'}>
                <div className={'max-width-container'}>
                    <div className={'logo-holder'}>
                        <img src={'/images/logo-no-text.svg'} />
                        <span>© {new Date().getFullYear()} Thrive, LLC.</span>
                    </div>
                    <a href={'#'} onClick={e=>{e.preventDefault();setShowContactForm(true)}}>
                        Contact Us
                    </a>
                </div>
            </div>
        </>
    )
}

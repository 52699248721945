import React, {useEffect, useState} from 'react';
import Popup from "./popup";
import axios from 'axios';

export default function ServiceAgreementPopup({closeHandler}){

	const [agreement,setAgreement] = useState({});

	useEffect(()=>{
		const cancelToken = axios.CancelToken.source();
		axios.get('/settings/thrive-services-agreement',{cancelToken:cancelToken.token}).then(response=>{
			setAgreement(response.data.setting);
		});
		return ()=>{
			cancelToken.cancel('')
		}
	},[]);

	return (
		<Popup closeHandler={closeHandler} >
			<div className={'popup-content thrive-description'} >
				<h2><img src={'/images/thrive-contribution.svg'} alt={''} />Thrive Professional Service Agreement</h2>
				{agreement.setting_value &&
				<div dangerouslySetInnerHTML={{__html: agreement.setting_value}}/>
				}
			</div>
		</Popup>
	)
}

export const getEnv = () =>{
	if(window.location.hostname.indexOf('demo')>-1){
		return 'demo'
	}
	if(window.location.hostname.indexOf('qa')>-1){
		return 'qa'
	}
	if(window.location.hostname.indexOf('thrivematching')>-1){
		return 'production'
	}
	if(window.location.hostname.indexOf('dev3')>-1 || window.location.hostname.indexOf('dev4')>-1){
		return 'dev'
	}
	if(window.location.hostname.indexOf('local')>-1){
		return 'local';
	}
};

export const getBaseApiUrl = () =>{
	switch(getEnv()){
		case 'production':
			return 'https://api.thrivematching.com';
		case 'dev':
			if(window.location.hostname.indexOf('dev3')>-1) {
				return 'https://api.thrive.dev3.stream9.net';
			}else{
				return 'https://api.thrive.dev4.stream9.net';
			}
		case 'demo':
			return 'https://api.demo.thrivematching.com';
		case 'qa':
			return 'https://api.qa.thrivematching.com';
		case 'local':
			return 'http://api.thrive.localhost:8888';
		default:
			return 'http://api.thrive.localhost:8888';
	}
};

export const  getTextWidth = (text, font) => {
	const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
	const context = canvas.getContext("2d");
	context.font = font;
	return context.measureText(text).width;
};

export const uniqid =(a = "", b = false) => {
	const c = Date.now()/1000;
	let d = c.toString(16).split(".").join("");
	while(d.length < 14) d += "0";
	let e = "";
	if(b){
		e = "_";
		e += Math.round(Math.random()*100000000);
	}
	return a + d + e;
};

export const appendScript = (scriptToAppend) => {
	const script = document.createElement("script");
	script.src = scriptToAppend;
	script.async = true;
	document.body.appendChild(script);
};

export const removeScript = (scriptToRemove) => {
	let allSuspects=document.getElementsByTagName("script");
	for (let i=allSuspects.length; i>=0; i--){
		if (allSuspects[i] && allSuspects[i].getAttribute("src")!==null
			&& allSuspects[i].getAttribute("src").indexOf(`${scriptToRemove}`)                !== -1 ){
			allSuspects[i].parentNode.removeChild(allSuspects[i])
		}
	}
};

export const validateNumberFormat = (input,format,formatted=true) =>{
	if(formatted){
		if(!input || input.length !== format.length){
			return false;
		}
		let valid = true;
		format.split('').forEach((char,index)=>{
			if(char === '#'){
				if(isNaN(parseInt(input.charAt(index)))){
					valid = false;
				}
			}else{
				if(char !== input.charAt(index)){
					valid = false;
				}
			}
		});
		return valid;
	}else{
		if(!input || input.length<1){
			return false;
		}
		let formatDigits = format.match(/#/g);
		let inputDigits = input.match(/\d/g);

		if(!inputDigits){
			return false;
		}
		if(!formatDigits){
			return true;
		}
		return formatDigits.length === inputDigits.length;
	}
};

export const getHttpErrorMessage = (error) =>{
	return (error.response && error.response.data && error.response.data.meta)?error.response.data.meta.message || error.message:error.message;
}

export const validateEmailAddress = (email) =>{
	return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const validateWebsiteForForte = (website) =>{
	return /(www.)/.test(website) && !/(http)/.test(website) && (website.match(/./g) || []).length>1;
};

export const formatExternalLink = (link) => {
	if(/(http)/.test(link)){
		return link;
	}

	return 'http://' + link;
}

export const formatDate = (dateString) =>{
	dateString = dateString.replace(' ','T');
	if(dateString.indexOf('T')<0) {
		dateString+='T11:00:00';
	}
	let date = new Date(dateString);
	let year = date.getFullYear();

	let month = (1 + date.getMonth()).toString();
	month = month.length > 1 ? month : '0' + month;

	let day = date.getDate().toString();
	day = day.length > 1 ? day : '0' + day;

	return month + '/' + day + '/' + year;
}

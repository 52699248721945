import React, {useState} from 'react';

export default function MobileEmployeeRow({employee, accentColor, viewHandler, employeeStatus, employeeAcceptDate,selectToggler,selectedEmployees}){

    const [isOpen,setIsOpen] = useState(false);

    return (
        <>
            <div  className={'mobile-employee-drawer with-selector'}>
                <div className={'select-holder'} onClick={() => {
                    selectToggler(employee)
                }}>
                    <span className={'checkbox ' + (selectedEmployees.some(emp=>emp.user_id === employee.user_id) ? 'checked ' : ' ')}/>
                </div>
                <div onClick={()=>{setIsOpen(!isOpen)}} className={'mobile-employee-header ' + (isOpen?'open':'')}>
                    <span className={'title'}>Name</span>
                    <span>{employee.profile_first_name + ' ' + employee.profile_last_name}</span>
                </div>
                <div className={'table-row employee-row tablet mobile ' + (isOpen?'open':'')}>
                    <div>
                        <span className={'title'}>Name</span>
                        <span>{employee.profile_first_name + ' ' + employee.profile_last_name}</span>
                    </div>
                    <div>
                        <span className={'title'}>Email</span>
                        <span>{employee.user_email}</span>
                    </div>
                    <div>
                        <span className={'title'}>ID No</span>
                        <span>{employee.user_organization_number}</span>
                    </div>
                    <div>
                        <span className={'title'}>Start Date</span>
                        <span>{employee.user_organization_start_date?new Date((employee.user_organization_start_date + 'T11:00:00').replace(' ','T')).toLocaleDateString():''}</span>
                    </div>
                    <div>
                        <span className={'title'}>Sign Up Date</span>
                        <span>{employeeAcceptDate || 'N/A'}</span>
                    </div>
                    <div>
                        <span className={'title'}>Invite Date</span>
                        <span>{new Date(employee.user_organization_timestamp.replace(' ','T')).toLocaleDateString()}</span>
                    </div>
                    <div>
                        <span className={'title'}>Status</span>
                        <span className={ 'status ' + (employeeStatus === 'active'?'active':'inactive')} >{employeeStatus}</span>
                    </div>
                    <span ><button onClick={()=>{viewHandler(employee)}} className={'employee'} style={{color:accentColor}}>View More</button></span>
                </div>

            </div>

        </>
    )
}

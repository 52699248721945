import React from 'react';
import MobileEmployeeRow from "../mobileEmployeeRow";
import {useLocation} from "react-router";

export default function EmployeeListPlaceholder(){

    const location = useLocation();
    const employees = [1,2,3,4,5,6,7,8,9,10];
    return (
        <div className={'employee-list dashboard-list drawer-list'}>
            {location.state.layout === 'desktop' &&
            <div className={'table-row employee-row header'}>
                <span className={'small'}>Name</span>
                <span className={'large'}>Email</span>
                <span className={'small'}>ID No</span>
                <span className={'small'}>Start Date</span>
                <span className={'small'}>Sign Up Date</span>
                <span className={'small'}>Invite Date</span>
                <span className={'small'}>Status</span>
                <span className={'med'}>Details</span>
            </div>
            }
            <div className={'placeholder-holder'}>
            {location.state.layout==='desktop' && employees.map((employee)=>{
                return (
                    <div key={employee} className={'table-row employee-row'}>
                        <span className={'small placeholder'} />
                        <span className={'large placeholder'} />
                        <span className={'small placeholder'}/>
                        <span className={'small placeholder'}/>
                        <span className={'small placeholder'}/>
                        <span className={'small placeholder'}/>
                        <span className={'small placeholder'}/>
                        <span className={'med placeholder'}/>
                    </div>
                )
            })}
            {(location.state.layout==='tabletMax' ||location.state.layout==='tabletMed')  && employees.map((employee)=>{
                return (
                    <div key={employee} className={'table-row employee-row tablet'}>
                        <div>
                            <span className={'title'}>Name</span>
                            <span className={'placeholder'} />
                        </div>
                        <div>
                            <span className={'title'}>Email</span>
                            <span className={'placeholder'} />
                        </div>
                        <div>
                            <span className={'title'}>ID No</span>
                            <span className={'placeholder'} />
                        </div>
                        <div>
                            <span className={'title'}>Start Date</span>
                            <span className={'placeholder'} />
                        </div>
                        <div>
                            <span className={'title'}>Sign Up Date</span>
                            <span className={'placeholder'} />
                        </div>
                        <div>
                            <span className={'title'}>Invite Date</span>
                            <span className={'placeholder'} />
                        </div>
                        <div>
                            <span className={'title'}>Status</span>
                            <span className={'placeholder'} />
                        </div>
                        <div>
                            <span className={'placeholder'} />
                        </div>
                    </div>
                )
            })}
            {location.state.layout === 'mobileMax' &&
                employees.map((employee)=> (
                    <div key={employee}  className={'mobile-employee-drawer'}>
                        <div className={'mobile-employee-header '}>
                            <span className={'title'}>Name</span>
                            <span className={'placeholder'} />
                        </div>
                    </div>
                ))
            }
            </div>
        </div>
    )
}

import React, {useEffect, useState} from 'react';
import Popup from "./popup";
import axios from 'axios';

export default function FaqPopup({closeHandler}){

	const [faqs,setFaqs] = useState([]);

	useEffect(()=>{
		const cancelToken = axios.CancelToken.source();
		axios.get('/faqs',{cancelToken:cancelToken.token}).then(response=>{
			setFaqs(response.data.faqs);
		});
		return ()=>{
			cancelToken.cancel('')
		}
	},[]);

	return (
		<Popup closeHandler={closeHandler}>
			<div className={'popup-content faq-popup'}>
				<div className={'faq-title'}>
					<img src={'/images/logo-no-text.svg'} />
					Frequently Asked Questions
				</div>
				<div className={'faqs'}>
					{
						faqs.map((faq,index)=>(
							<div key={faq.faq_id+index}>
								<h3>{faq.faq_question}</h3>
								<p>{faq.faq_answer}</p>
							</div>
						))
					}
				</div>
			</div>
		</Popup>
	)
}

import React, {useState,useEffect} from 'react';
import Popup from "../../components/popups/popup";
import "react-datepicker/dist/react-datepicker.css";
import {ADMIN_TYPE, Colors, EMPLOYEE_TYPE, EMPLOYER_CONTACT_TYPE, HR_TYPE} from "../../_constants";
import SingleSelector from "../../components/singleSelector";
import FilterButton from "../../components/filterButton";

export default function UserPopup({closeHandler,user,updateHandler}){
	const accentColor = Colors.blue;
	const [curUser,setCurUser] = useState(JSON.parse(JSON.stringify(user)));
	const [userUpdated,setUserUpdated] = useState('');
	const statusOptions = [
		{label:'Active',value:'active',selected:user? user.user_status_token==='active':false},
		{label:'Inactive',value:'inactive',selected:user? user.user_status_token!=='active':false}
	];
	const [roleOptions,setRoleOptions] = useState([{label:'Super Admin',value:EMPLOYER_CONTACT_TYPE},{label:'Admin',value:ADMIN_TYPE},{label:'Employee',value:EMPLOYEE_TYPE},{label:'HR',value:HR_TYPE}]);

	const updateUser = (key,value) =>{
		curUser[key] = value;
		setCurUser(curUser);
		setUserUpdated(Math.random());
	};
	const updateRoles = (roles) =>{
		updateUser('roles',roles);
	};
	const saveUser = (e) =>{
		e.preventDefault();
		updateHandler(curUser);
	};
	useEffect(()=>{
		let newRoleOptions = roleOptions.map(newRole=>{
			if(user.roles.filter(role=>role.role_token===newRole.value).length>0){
				newRole.selected = true;
			}else{
				newRole.selected = false;
			}
			return newRole;
		});
		setRoleOptions(newRoleOptions);

	},[user]);

	return(
		<Popup closeHandler={closeHandler}>
			<div className={'user-settings popup-content'}>
				<h2 style={{color:accentColor}} className={'title'}>User Account</h2>
				<div className={'popup-form user'}>
					<label>
						First Name
						<input
							value={curUser.profile_first_name}
							onChange={(e)=>{updateUser('profile_first_name',e.target.value)}}
							placeholder={'First Name'}/>
					</label>

					<label>
						Last Name
						<input
							value={curUser.profile_last_name}
							onChange={(e)=>{updateUser('profile_last_name',e.target.value)}}
							placeholder={'Last Name'}/>
					</label>

					<label>
						Email
						<input
							value={curUser.user_email}
							onChange={(e)=>{updateUser('user_email',e.target.value)}}
							placeholder={'email'}/>
					</label>


					{/*<label>*/}
					{/*	Original Invite Date*/}
					{/*	<div className={'read-only'}>{curUser.invite_date || 'Invite not sent'}</div>*/}
					{/*</label>*/}

					{/*<label>*/}
					{/*	Signup Date*/}
					{/*	<div className={'read-only'}>*/}
					{/*		<span>{curUser.signup_date || 'Invite Not Accepted'}</span>*/}
					{/*		{!curUser.signup_date && <span style={{color:accentColor}} className={'clickable standard-link'} onClick={()=>{sendInvite()}}>Resend Invite</span>}*/}
					{/*	</div>*/}
					{/*</label>*/}
					<label>
						Account Types
						<FilterButton fullWidth={true} squared={true} label={'Select Roles'} options={roleOptions} changeHandler={updateRoles}/>
					</label>
					<div className={'save-row'}>
						<button style={{backgroundColor:accentColor,color:'#FFF'}} className={'simple-rounded-button'} onClick={(e)=>{saveUser(e)}}><span>Save Changes</span></button>
					</div>
				</div>
			</div>
		</Popup>
	)
}

import React, {useEffect, useState} from 'react';
import {Colors, organizationActivityUrl, organizationUsersUrl} from "../../_constants";
import FilterButton from "../../components/filterButton";
import {CSSTransition} from "react-transition-group";
import FileListPlaceholder from "../../components/placeholders/fileListPlaceholder";
import ActivityPopup from "../../components/popups/activityPopup";
import axios from "axios";
import {useStoreActions, useStoreState} from "easy-peasy";
import ActivityFileList from "../../components/lists/activityFileList";
import InfiniteScroll from "react-infinite-scroller";

export default function Activity(){

    const [currentPage,setCurrentPage] = useState(1);
    const [totalPages,setTotalPages] = useState(1);
    const [isScrollLoading,setIsScrollLoading] = useState(false);

    const [filterOptions,setFilterOptions] = useState([{label:'File Uploaded',value:'file_uploaded',action:'category'},{label:'Error',value:'error',action:'category'}]);
    const [selectedFilters,setSelectedFilters] = useState([]);
    const [files,setFiles] = useState([]);
    const [searchTerm,setSearchTerm] = useState('');
    const [selectedActivity,setSelectedActivity] = useState(null);
    const [showActivityPopup,setShowActivityPopup] = useState(false);
    const [loading,setLoading] = useState(true);
    const activeOrganization = useStoreState(state=>state.user.activeOrganization);
    const [updateToken,setUpdateToken] = useState('');


    const viewActivity = (activity) =>{
        setSelectedActivity(activity);
        setShowActivityPopup(true);
    };
    const closeActivity = () =>{
        setShowActivityPopup(false);
    };

    const scrollLoad = (page) =>{
        setIsScrollLoading(true);
        let queryString = '?page='+page;
        if(selectedFilters.length>0){
            selectedFilters.forEach((filter)=>{
                queryString += '&types[]='+filter.value;
            })

        }
        if(searchTerm.trim().length>0){
            queryString += '&search=' + searchTerm.trim();
        }
        setCurrentPage(page);
        axios.get(organizationActivityUrl(activeOrganization['organization_id']) + queryString).then(response=>{
            setFiles([...files,...response.data.activity]);
            setLoading(false);

            setTotalPages(response.data.meta.pages);
            setTimeout(()=>{setIsScrollLoading(false);},0)
        }).catch(e=>{});
    }

    useEffect(()=>{
        let fileCancelToken = axios.CancelToken.source();
        let typeCancelToken = axios.CancelToken.source();
        axios.get(organizationActivityUrl(activeOrganization['organization_id']),{cancelToken:fileCancelToken.token}).then(response=>{
            setFiles(response.data.activity);
            setCurrentPage(response.data.meta.page);
            setTotalPages(response.data.meta.pages);
            setLoading(false);
        }).catch(e=>{});
        axios.get(organizationActivityUrl(activeOrganization['organization_id'])+'/types',{cancelToken:typeCancelToken.token}).then(response=>{
            setFilterOptions(response.data.activity_types.map(type=>{return {label:type.activity_type_title,value:type.activity_type,action:'types'}}));
        }).catch(e=>{});
        return ()=>{
            fileCancelToken.cancel('');
            typeCancelToken.cancel('');
        }
    },[]);


    useEffect(()=>{
        if(loading || isScrollLoading){
            return;
        }
        let queryString = '';
        if(selectedFilters.length>0){
            queryString = '?';
            selectedFilters.forEach((filter,index)=>{
                queryString += 'types[]='+filter.value;
                if(index<selectedFilters.length-1){
                    queryString += '&'
                }
            })

        }
        if(searchTerm.trim().length>0){
            queryString += queryString.length>0?'&':'?';
            queryString += 'search=' + searchTerm.trim();
        }
        let cancelToken = axios.CancelToken.source();
        axios.get(organizationActivityUrl(activeOrganization['organization_id']) + queryString,{cancelToken:cancelToken.token}).then(response=>{
            setFiles(response.data.activity);
            setCurrentPage(response.data.meta.page);
            setTotalPages(response.data.meta.pages);
            setLoading(false);
            setUpdateToken(Math.random());
        }).catch(e=>{});
        return ()=>{
            cancelToken.cancel('');
        }
    },[searchTerm,selectedFilters]);

    const clearSearchAndFilters = () =>{
        setSearchTerm('');
        setSelectedFilters([]);
        setFilterOptions(filterOptions.map(filter=>{
            filter.selected = false;
            return filter;
        }))
    }

    return (
        <>
            {showActivityPopup && <ActivityPopup activity={selectedActivity} closeHandler={closeActivity}/>}
            <div className={'full-width page'}>
                <div className={'header'}>
                    <div>
                        <h1 className={'heading'} >
                            Activity Log
                        </h1>
                        <p className={'sub-heading'}>
                            View Recent Activity
                        </p>
                    </div>
                </div>
                <div className={'list-section'}>
                    <div className={'body'}>
                        <div className={'search-filter-container'}>
                            <input type={'text'} placeholder={'Search'} value={searchTerm} onChange={(e)=>{setSearchTerm(e.target.value)}} />
                            <FilterButton options={filterOptions} changeHandler={setSelectedFilters} />
                        </div>

                        <CSSTransition
                            in={loading}
                            timeout={{
                                appear: 500,
                                enter: 300,
                                exit: 0,
                            }}
                            classNames="fade"
                            unmountOnExit
                        >
                            <FileListPlaceholder />
                        </CSSTransition>
                        <CSSTransition
                            in={!loading}
                            timeout={{
                                enter: 300,
                                exit: 0,
                            }}
                            classNames="fade"
                            unmountOnExit
                        >
                            <InfiniteScroll
                                pageStart={1}
                                loadMore={isScrollLoading?null:scrollLoad}
                                initialLoad={false}
                                hasMore={(currentPage<totalPages)}
                                loader={<div className="loader" key={0}>Loading ...</div>}
                            >
                                <ActivityFileList viewHandler={viewActivity} accentColor={Colors.blue} files={files} linkClass={'employee'} clearHandler={clearSearchAndFilters} />
                            </InfiniteScroll>
                        </CSSTransition>
                    </div>
                </div>
            </div>
        </>
    )

}

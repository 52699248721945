import React from 'react';

export default function ReportsPlaceholder(){
    const defaultHeight = 300;
    return (
        <div className={'report-holder placeholder-holder'}>
            <div className={'report-column'}>
                <h5 className={'placeholder'} />
                <div style={{height:defaultHeight}}>
                    <span className={'placeholder'} />
                    <span className={'placeholder'} />
                    <span className={'placeholder'} />
            </div>
            </div>
            <div className={'report-column'}>
                <h5 className={'placeholder'} />
                <div style={{height:defaultHeight}}>
                    <span className={'placeholder'} />
                    <span className={'placeholder'} />
                </div>
            </div>
            <div className={'report-column'}>
                <h5 className={'placeholder'} />
                <div style={{height:defaultHeight}}>
                    <span className={'placeholder'} />
                    <span className={'placeholder'} />
                    <span className={'placeholder'} />
                    <span className={'placeholder'} />
                </div>
            </div>
        </div>
    )
}

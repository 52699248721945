import React from 'react';
import Popup from "./popup";
import {useHistory} from "react-router";

export default function NotApprovedPopup({closeHandler}){

	const history = useHistory();

	const goToStatus = (e) =>{
		e.preventDefault();
		history.push('/company-account');
		closeHandler();
	};

	return (
		<Popup closeHandler={closeHandler} bodyClass={'evoshare-result'}>
			<div className={'popup-content thrive-description'} >
				<h2><img src={'/images/thrive-contribution.svg'} alt={''} />Activity Not Permitted</h2>
				<div style={{padding:'20px', textAlign:'center'}} className={'body'}>
					Your account has not yet been approved.
					<br/>
					<br/>
					You can view your account status on the <a onClick={goToStatus} href={'/company-account'}>Company Account Page</a>.
				</div>
			</div>
		</Popup>
	)
}

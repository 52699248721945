import React from 'react'
import {useLocation} from "react-router";

export default function FileListPlaceholder({linkClass}){
    const location = useLocation();
    const files = [1,2,3,4];
    return (
        <div className={linkClass+'-list dashboard-list '}>
            {location.state.layout!=='mobileMax' &&
                <div className={'table-row file-row header'}>
                    <span>File name</span>
                    <span>Upload Date</span>
                    <span>Download</span>
                </div>
            }
            <div className={'placeholder-holder'}>
                {location.state.layout!=='mobileMax' && files.map((file,index)=>{
                    return (
                        <div key={index} className={'table-row  file-row'}>
                            <span className={'placeholder'} />
                            <span className={'placeholder'} />
                            <span className={'placeholder'} />
                        </div>
                    )
                })}
                {(location.state.layout==='mobileMax')  && files.map((file,index)=>{
                    return (
                        <div key={index} className={'table-row  file-row tablet' }>
                            <div>
                                <span className={'title'}>File name</span>
                                <span className={'placeholder'} />
                            </div>
                            <div>
                                <span className={'title'}>Upload Date</span>
                                <span className={'placeholder'} />
                            </div>
                            <div>
                                <span className={'placeholder'} />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )

}



import React, {useState, useEffect} from 'react';
import PayoffCalculatorButton from "../../../components/payoffCalculatorButton";
import WhatIsThriveButton from "../../../components/whatIsThriveButton";
import axios from "axios";
import {contributionSaveSettingsUrl, contributionSettingsCalculateUrl} from "../../../_constants";
import {useStoreActions, useStoreState} from "easy-peasy";
import NumberFormat from "react-number-format";
import {formatExternalLink} from "../../../_helpers";
import useAlertOnUnsaved from "../../../hooks/useAlertOnUnsaved";
import {Prompt} from "react-router";


export default function ContributionSettings({accentColor}){

	const activeOrganization = useStoreState(state=>state.user.activeOrganization);
	const user = useStoreState(state=>state.user.user);
	const setMessage = useStoreActions(actions=>actions.alertMessage.setMessage);
	const setLastUpdated = useStoreActions(actions=>actions.headerInfo.setLastUpdated);
	const [organization,setOrganization] = useState(null);
	const [inputType,setInputType] = useState(null);
	const [retirementInputType,setRetirementInputType] = useState('percentage');
	const [availableRows,setAvailableRows] = useState([]);
	const [showMaximized,setShowMaximized] = useState(false);
	const [availableContributionTypes,setAvailableContributionTypes] = useState([]);
	const [availableColumns,setAvailableColumns] = useState([]);
	const [calculateCancelToken,setCalculateCancelToken] = useState(axios.CancelToken.source());
	const [contributionSettings,setContributionSettings] = useState({
		user_contribution_setting_eligible_compensation: '',
		user_contribution_setting_program_amount: '',
		user_contribution_setting_program_type: '',
		user_contribution_setting_program_contribution_flat: '',
		user_contribution_setting_program_contribution_percentage: '',
		user_contribution_setting_program_match_flat: '',
		user_contribution_setting_program_match_percentage: '',
		user_contribution_setting_program_total_flat: '',
		user_contribution_setting_retirement_amount: '',
		user_contribution_setting_retirement_type: '',
		user_contribution_setting_retirement_contribution_flat: '',
		user_contribution_setting_retirement_contribution_percentage: '',
		user_contribution_setting_retirement_match_flat: '',
		user_contribution_setting_retirement_match_percentage: '',
		user_contribution_setting_retirement_total_flat: '',
		user_contribution_setting_match_total_flat: '',
		user_contribution_setting_match_total_percentage: '',
		user_contribution_setting_contribution_total_flat: '',
		user_contribution_setting_contribution_total_percentage: '',
		user_contribution_setting_contribution_is_match_maximized: false
	});
	const [updateKey,setUpdateKey] = useState(Math.random());
	const [userHasInteracted,setUserHasInteracted] = useState(false);
	const [unsavedChanges,setUnsavedChanges] = useState(false);
	useAlertOnUnsaved(unsavedChanges);

	const getPostData = (newInputType = null,newRetirementType=null,newSettings = null) =>{
		newInputType = newInputType || inputType;
		newRetirementType = newRetirementType || retirementInputType;
		newSettings = newSettings || contributionSettings;
		if(!newInputType || !newSettings){
			return false;
		}
		let programAmount = parseFloat(
			newInputType==='percentage'?
				newSettings.user_contribution_setting_program_contribution_percentage:
				newSettings.user_contribution_setting_program_contribution_flat
		);
		let retirementAmount = parseFloat(
			newRetirementType==='percentage'?
				newSettings.user_contribution_setting_retirement_contribution_percentage:
				newSettings.user_contribution_setting_retirement_contribution_flat
		);
		let compensationAmount = newSettings.user_contribution_setting_eligible_compensation?parseFloat(newSettings.user_contribution_setting_eligible_compensation.toString().replace(/[^0-9\-.]/g,'')):null;

		if(isNaN(programAmount)){
			programAmount = 0;
		}
		if(isNaN(retirementAmount)){
			retirementAmount = 0;
		}
		if(isNaN(compensationAmount)){
			compensationAmount = 0;
		}
		return {
			user_contribution_setting_program_amount: programAmount,
			user_contribution_setting_program_type: newInputType,
			user_contribution_setting_retirement_amount: retirementAmount,
			user_contribution_setting_retirement_type: retirementInputType,
			user_contribution_setting_eligible_compensation: compensationAmount,
		}
	}

	useEffect(()=>{

		setLastUpdated('...');
		let newValues = {
			user_contribution_setting_program_amount: 0,
			user_contribution_setting_program_type: 'percentage',
			user_contribution_setting_retirement_amount: 0,
			user_contribution_setting_retirement_type: 'percentage',
			user_contribution_setting_eligible_compensation: 0,
		};
		let newCancelToken = axios.CancelToken.source();
		setCalculateCancelToken(newCancelToken);
		axios.get('/organizations/'+activeOrganization.organization_id+'/users/'+user.user_id, {cancelToken:newCancelToken.token}).then(response=>{
			setLastUpdated(response.data.user.user_contribution_setting_timestamp?new Date(response.data.user.user_contribution_setting_timestamp.replace(' ','T')).toLocaleDateString() : 'hide');
			if(response.data.user){
				setUpdateKey(Math.random());
				setInputType(response.data.user['user_contribution_setting_program_type']);
				setRetirementInputType('percentage');
				if(activeOrganization.plan_token === 'employer-direct' && response.data.user['user_contribution_setting_override_amount']){
					setContributionSettings(response.data.user);
					return;
				}

				newCancelToken = axios.CancelToken.source();
				setCalculateCancelToken(newCancelToken);

				let userInfo = response.data.user;
				axios.post(contributionSettingsCalculateUrl(activeOrganization.organization_id,user.user_id),{user_contribution_settings:response.data.user},{cancelToken:newCancelToken.token}).then(response=>{
					if(!response.data.user_contribution_settings.user_contribution_setting_eligible_compensation){
						response.data.user_contribution_settings.user_contribution_setting_eligible_compensation = '0';
					}
					if(activeOrganization.plan_token === 'employer-direct' && userInfo['contribution_tier_value']){
						response.data.user_contribution_settings['user_contribution_setting_program_match_flat'] = userInfo['contribution_tier_value'];
						response.data.user_contribution_settings['contribution_tier_id'] = userInfo['contribution_tier_id'];
					}
					setInputType(response.data.user_contribution_settings['user_contribution_setting_program_type']);
					setRetirementInputType(response.data.user_contribution_settings['user_contribution_setting_retirement_type']);
					setContributionSettings(response.data.user_contribution_settings);
					setUpdateKey(Math.random());
				}).catch(e=>{});
			}else{
				newCancelToken = axios.CancelToken.source();
				setCalculateCancelToken(newCancelToken);
				axios.post(contributionSettingsCalculateUrl(activeOrganization.organization_id,user.user_id),{user_contribution_settings:newValues},{cancelToken:newCancelToken.token}).then(response=>{
					setContributionSettings(response.data.user_contribution_settings);
					setUpdateKey(Math.random());
				}).catch(e=>{});
			}

		}).catch(e=>{});

		const organizationCancelToken = axios.CancelToken.source();
		axios.get('/organizations/'+activeOrganization.organization_id, {cancelToken:organizationCancelToken.token}).then(response=>{
			setOrganization(response.data.organization);
		});
		return ()=>{
			calculateCancelToken.cancel('');
			organizationCancelToken.cancel('');
		}
	},[]);



	const sendValuesToApi = (newInputType,newSettings) =>{
		const newValues = getPostData(newInputType,null,newSettings);
		const newCancelToken = axios.CancelToken.source();
		if(!newValues){
			return;
		}
		if(userHasInteracted) {
			setUnsavedChanges(true);
		}
		setCalculateCancelToken(newCancelToken);
		axios.post(contributionSettingsCalculateUrl(activeOrganization.organization_id,user.user_id),{user_contribution_settings:newValues}, {cancelToken:newCancelToken.token}).then(response=>{
			setContributionSettings(response.data.user_contribution_settings);
			setUpdateKey(Math.random());
		}).catch(e=>{});
	}

	const updateContributionSetting = (key,value) =>{
		calculateCancelToken.cancel('');
		if(!value){
			return;
		}

		let newSettings = contributionSettings;
		if(!newSettings.hasOwnProperty(key)){
			return;
		}
		let newInputType = inputType;

		if(newSettings[key] === value){
			return;
		}
		newSettings[key] = value;
		setContributionSettings(newSettings);
		setUpdateKey(Math.random());

		sendValuesToApi(newInputType,newSettings)
	};

	const shouldShowTotalContributionColumn = () =>{
		if(!organization){
			return false;
		}
		return organization.plan_token !== 'employer-direct' && organization.plan_token !== 'payroll-deduction';
	}

	useEffect(()=>{
		if(!organization){
			return;
		}
		if(organization.plan_token === 'payroll-deduction'){
			setAvailableColumns( ['your-contribution']);
		}else if(organization.plan_token === 'employer-direct'){
			setAvailableColumns(['employer-contribution']);
		}else{
			setAvailableColumns(['your-contribution','employer-contribution']);
		}
		setAvailableContributionTypes(organization.organization_contribution_setting_contribution_type);
		if(organization.plan_token !== 'flexible-match'){
			setAvailableRows( ['thrive']);
		}else {
			setAvailableRows( [ 'retirement','thrive']);
		}
		setShowMaximized(organization.plan_token === 'flexible-match' || organization.plan_token === 'employer-match')
	},[organization]);

	useEffect(()=>{
		if(!availableContributionTypes){
			return;
		}
		if(availableContributionTypes.includes('percentage')){
			setInputType('percentage');
		}else{
			setInputType('flat')
		}
	},[availableContributionTypes]);

	const saveSettingsHandler = () =>{
		contributionSettings['user_contribution_setting_retirement_type'] = retirementInputType;
		contributionSettings['user_contribution_setting_program_type'] = inputType;
		setContributionSettings(contributionSettings);
		axios.post(contributionSaveSettingsUrl(activeOrganization.organization_id,user.user_id),{user_contribution_settings:contributionSettings}).then(response=>{
			if(!response.data.meta.message) {
				setMessage({message: 'Contribution Settings Saved'});
			}
			setUnsavedChanges(false);
		}).catch(e=>{});
	}

	return (

	<>
		<Prompt
			when={unsavedChanges}
			message={() => 'You have unsaved changes, are you sure you want to leave?'}
		/>
		{contributionSettings &&
		<>
			<div className={'content-header'}>
				<div className={'title'}>
					<h1 style={{color: accentColor}}>
						Monthly Contribution Settings
					</h1>
					{(organization && organization.plan_token !== 'employer-direct') &&
					<span className={'sub-header'}>
						Update Your Contributions and Employer Match Percentages
					</span>
					}
				</div>
				<PayoffCalculatorButton/>
			</div>

			<div className={'contribution-block'} onClick={()=> {
				setUserHasInteracted(true);
			}}>
				{(contributionSettings && contributionSettings.user_contribution_setting_eligible_compensation && activeOrganization.plan_token !== 'employer-direct') &&
				<div className={'contribution-row'}>
					<div className={'title'}><img src={'/images/income.svg'}/>Income</div>

					<div className={'contribution-columns'}>
						<label>
							Monthly Income
							<div className={'input-holder dollar'}>
								<div className={'input'}>
									<NumberFormat
										value={contributionSettings.user_contribution_setting_eligible_compensation}
										thousandSeparator={true}
										decimalScale={0}
										onValueChange={(values) => {
											const {formattedValue, value} = values;

											updateContributionSetting('user_contribution_setting_eligible_compensation', value)
										}}
									/>
									<span>$</span>
								</div>
							</div>
						</label>
						{(availableContributionTypes && availableContributionTypes.length > 1) &&
						<label>
							Contribution Method
							<div className={'input-holder split'}>
								<div className={'input with-border'}>
									<div onClick={() => {
										setInputType('percentage')
									}}
									     className={' clickable radio-button ' + (inputType === 'percentage' ? 'selected' : '')}/>
									<span>%</span>
								</div>
								<div className={'input with-border'}>
									<div onClick={() => {
										setInputType('flat')
									}}
									     className={'clickable radio-button ' + (inputType === 'flat' ? 'selected' : '')}/>
									<span>$</span>
								</div>
							</div>
						</label>
						}
					</div>

				</div>
				}
				{availableRows.includes('retirement') &&
				<div className={'contribution-row'}>
					<div className={'title retirement-title'}>
						<div>
							<img src={'/images/retirement.svg'}/>
							Retirement
						</div>
						{(organization && organization.organization_retirement_setting_logo_url && organization.organization_retirement_setting_url) &&
						<a href={formatExternalLink(organization.organization_retirement_setting_url)} target={'_blank'} rel={'noreferrer noopener'} className={'retirement-info'}>
							<img src={organization.organization_retirement_setting_logo_url} />
							{organization.organization_retirement_setting_title && <span>{organization.organization_retirement_setting_title}</span>}
						</a>
						}
					</div>
					<div className={'contribution-columns ' + (availableColumns.length < 2 ? 'col-2' : '')}>
						{availableColumns.includes('your-contribution') &&
						<label>
							Your Monthly Contribution
							<div className={'input-holder split'}>
								<div className={'input'}>

									<NumberFormat
										value={contributionSettings.user_contribution_setting_retirement_contribution_percentage}
										disabled={!availableContributionTypes}
										thousandSeparator={true}
										className={(retirementInputType === 'percentage' ? 'active-type' : '')}
										suffix={'%'}
										maxLength={5}
										decimalScale={2}
										onValueChange={(values) => {
											const {formattedValue, value} = values;
											if(retirementInputType === 'percentage') {
												updateContributionSetting('user_contribution_setting_retirement_contribution_percentage', value)
											}
										}}
										onFocus={() => {
											setRetirementInputType('percentage');
										}}
									/>
								</div>
								<div className={'input'}>
									<NumberFormat
										value={contributionSettings.user_contribution_setting_retirement_contribution_flat}
										disabled={true}
										thousandSeparator={true}
										className={(retirementInputType === 'flat' ? 'active-type' : '')}
										prefix={'$'}
										decimalScale={2}
										onValueChange={(values) => {
											const {formattedValue, value} = values;
											if(retirementInputType === 'flat') {
												updateContributionSetting('user_contribution_setting_retirement_contribution_flat', value)
											}
										}}
										onFocus={() => {
											setRetirementInputType('flat');
										}}
									/>
								</div>
							</div>
						</label>
						}
						{availableColumns.includes('employer-contribution') &&
						<label>
							Employer Monthly Contribution
							<div className={'input-holder split'}>
								<NumberFormat
									value={contributionSettings.user_contribution_setting_retirement_match_percentage}
									thousandSeparator={true}
									className={'input read-only'}
									suffix={'%'}
									maxLength={5}
									decimalScale={2}
									displayType={'text'}
								/>
								<NumberFormat
									value={contributionSettings.user_contribution_setting_retirement_match_flat}
									thousandSeparator={true}
									className={'input read-only'}
									prefix={'$'}
									decimalScale={2}
									displayType={'text'}
								/>
							</div>
						</label>
						}
						{shouldShowTotalContributionColumn() &&
						<label>
							Total Monthly Contribution
							<div className={'input-holder single'}>
								<NumberFormat
									value={contributionSettings.user_contribution_setting_retirement_total_flat}
									thousandSeparator={true}
									className={'input read-only'}
									prefix={'$'}
									decimalScale={2}
									displayType={'text'}
								/>
							</div>
						</label>
						}
					</div>
				</div>
				}
				{availableRows.includes('thrive') &&
				<div className={'contribution-row'}>
					<div className={'title'}><img src={'/images/thrive-contribution.svg'}/>
						<div>
							Thrive Contribution
							<WhatIsThriveButton/>
						</div>
					</div>
					<div className={'contribution-columns ' + (availableColumns.length < 2 ? 'col-2' : '')}>
						{availableColumns.includes('your-contribution') &&
						<label>
							Your Monthly Contribution
							<div className={'input-holder split'}>
								<div className={'input'}>
									<NumberFormat
										value={contributionSettings.user_contribution_setting_program_contribution_percentage}
										disabled={!availableContributionTypes || !availableContributionTypes.includes('percentage')}
										thousandSeparator={true}
										className={(inputType === 'percentage' ? 'active-type' : '')}
										suffix={'%'}
										maxLength={5}
										decimalScale={2}
										onValueChange={(values) => {
											const {formattedValue, value} = values;
											updateContributionSetting('user_contribution_setting_program_contribution_percentage', value)
										}}
										onFocus={() => {
											setInputType('percentage');
										}}
									/>
								</div>
								<div className={'input'}>
									<NumberFormat
										value={contributionSettings.user_contribution_setting_program_contribution_flat}
										disabled={!availableContributionTypes || !availableContributionTypes.includes('flat')}
										thousandSeparator={true}
										className={(inputType === 'flat' ? 'active-type' : '')}
										prefix={'$'}
										decimalScale={2}
										onValueChange={(values) => {
											const {formattedValue, value} = values;
											updateContributionSetting('user_contribution_setting_program_contribution_flat', value)
										}}
										onFocus={() => {
											setInputType('flat');
										}}
									/>
								</div>
							</div>
						</label>
						}
						{availableColumns.includes('employer-contribution') &&
						<label>
							Employer Monthly Contribution
							<div className={'input-holder split'}>
								{(organization && organization.plan_token !== 'employer-direct') &&
									<>
								<NumberFormat
									value={contributionSettings.user_contribution_setting_program_match_percentage}
									thousandSeparator={true}
									className={'input read-only'}
									suffix={'%'}
									maxLength={5}
									decimalScale={2}
									displayType={'text'}
								/>
									<NumberFormat
									value={contributionSettings.user_contribution_setting_program_match_flat}
									thousandSeparator={true}
									className={'input read-only'}
									prefix={'$'}
									decimalScale={2}
									displayType={'text'}
									/>
									</>
								}
								{(organization && organization.plan_token === 'employer-direct' && !contributionSettings.user_contribution_setting_override_amount) &&
								< NumberFormat value={contributionSettings?contributionSettings.user_contribution_setting_program_match_flat:0}
								               thousandSeparator={true}
								               className={'input read-only ' + ((organization && organization.plan_token === 'employer-direct')?'single':'')}
								               prefix={'$'}
								               decimalScale={2}
								               displayType={'text'}
								/>
								}
								{(organization && organization.plan_token === 'employer-direct' && contributionSettings.user_contribution_setting_override_amount) &&
								< NumberFormat value={contributionSettings.user_contribution_setting_override_amount}
								               thousandSeparator={true}
								               className={'input read-only ' + ((organization && organization.plan_token === 'employer-direct')?'single':'')}
								               prefix={'$'}
								               decimalScale={2}
								               displayType={'text'}
								/>
								}
							</div>
							{(contributionSettings && contributionSettings.hasOwnProperty('tenure_requirement_met') && !contributionSettings.tenure_requirement_met) &&
							<div className={'tenure-required'}>
								*Tenure requirement not met.
								<span className={'tooltip'}>
															You will meet the tenure requirement for the employer match in {organization.organization_contribution_setting_min_tenure_length - contributionSettings.tenure_months} month{organization.organization_contribution_setting_min_tenure_length - contributionSettings.tenure_months === 1?'':'s'}.
														</span>
							</div>
							}
						</label>
						}
						{shouldShowTotalContributionColumn() &&
						<label>
							Total Monthly Contribution
							<div className={'input-holder single'}>
								<NumberFormat value={contributionSettings.user_contribution_setting_program_total_flat}
								              thousandSeparator={true}
								              className={'input read-only'}
								              prefix={'$'}
								              decimalScale={2}
								              displayType={'text'}
								/>
							</div>
						</label>
						}
					</div>
				</div>
				}
			</div>
			{shouldShowTotalContributionColumn() &&
			<div className={'contribution-block totals'}>
				<div className={'contribution-row desktop'}>
					<div className={'title'}><img src={'/images/totals.svg'}/>Totals</div>
					<div className={'contribution-columns ' + (availableColumns.length < 2 ? 'col-2' : '')}>
						{availableColumns.includes('your-contribution') &&
						<label>
							Your Monthly Contribution
							<div className={'input-holder split'}>
								<NumberFormat
									value={contributionSettings.user_contribution_setting_contribution_total_percentage}
									thousandSeparator={true}
									className={'input read-only'}
									suffix={'%'}
									maxLength={5}
									decimalScale={2}
									displayType={'text'}
								/>
								<NumberFormat
									value={contributionSettings.user_contribution_setting_contribution_total_flat}
									thousandSeparator={true}
									className={'input read-only'}
									prefix={'$'}
									decimalScale={2}
									displayType={'text'}
								/>
							</div>
						</label>
						}
						{availableColumns.includes('employer-contribution') &&
						<label>
							Employer Monthly Contribution
							<div className={'input-holder split'}>
								<NumberFormat
									value={contributionSettings.user_contribution_setting_match_total_percentage}
									thousandSeparator={true}
									className={'input read-only'}
									suffix={'%'}
									maxLength={5}
									decimalScale={2}
									displayType={'text'}
								/>
								<NumberFormat value={contributionSettings.user_contribution_setting_match_total_flat}
								              thousandSeparator={true}
								              className={'input read-only'}
								              prefix={'$'}
								              decimalScale={2}
								              displayType={'text'}
								/>
							</div>
						</label>
						}
						{showMaximized &&
						<label>
							Total Match
							<div className={'input-holder contribution-status'}>
								<div
									className={'input read-only ' + (contributionSettings.user_contribution_setting_contribution_is_match_maximized ? '' : 'error')}>{contributionSettings.user_contribution_setting_contribution_is_match_maximized ? 'Maximized' : 'Not Maximized'}</div>
							</div>
						</label>
						}
					</div>
				</div>
			</div>
			}
			{(organization && organization.organization_contribution_setting_match_description) &&
				<div className={'contribution-disclosure'} dangerouslySetInnerHTML={{__html:organization.organization_contribution_setting_match_description.replace(/\$?[0-9\.]+%?/g, '<strong>$&</strong>')}} />
			}
			{(organization && organization.plan_token !== 'employer-direct') &&
			<div className={'row'}>
				<button onClick={saveSettingsHandler} className={'simple-rounded-button green'}>
					<span>Save Changes</span></button>
			</div>
			}
		</>
		}
	</>

	)
}

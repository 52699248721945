import {action, createStore, persist} from "easy-peasy";

export const store = createStore({
    user:persist({
        user:{},
        type:'',
        email:'',
        displayName:'',
        activeOrganization:null,
        organizations:[],
        hasUnverifiedAccounts:false,
        acknowledgedUnverifiedAccounts:false,
        setType:action((state,payload)=>{
            state.type = payload;
        }),
        setName:action((state,payload)=>{
            state.displayName = payload;
        }),
        setActiveOrganization:action((state,payload)=>{
           state.activeOrganization = payload;
           if(payload) {
               state.hasUnverifiedAccounts = payload.unverified_accounts ? payload.unverified_accounts.length > 0 : false;
           }else{
               state.hasUnverifiedAccounts = false;
           }
            state.acknowledgedUnverifiedAccounts = false;
           state.type = payload?payload.role_token : null;
        }),
        setAcknowledgedUnverifiedAccounts:action((state,payload)=> {
            state.acknowledgedUnverifiedAccounts = true;
        }),
        login:action((state,payload)=>{
            state.user = payload;
            state.displayName = payload.user_display_name;
            state.email = payload.user_email;
            state.organizations = payload.user_organization_roles || payload.roles;
        }),
        token:'',
        isLoggedIn:false,
        updateToken:action((state,payload)=>{
            state.token = payload;
            state.isLoggedIn = !!payload;
            if(!payload){
                state.type = '';
                state.email = '';
                state.displayName = '';
                state.activeOrganization = null;
                state.organizations = [];
            }
        })
    }),
    alertMessage:{
        showMessage:false,
        type:'',
        message:'',
        setShowMessage:action((state,payload)=>{
            state.showMessage = payload;
        }),
        setMessage:action((state,payload)=>{
            state.message = payload?payload.message:'';
            state.type = payload?payload.type:'';
            state.showMessage = !!payload;
        })
    },
    fileUploadResponse:{
        file:null,
        setFile:action((state,payload)=>{
            state.file = payload + Math.random();
        }),
        reset:action((state,payload)=>{
            state.file = null;
        })
    },
    newestFileForDownload:{
        type:'',
        id:'',
        date:'',
        status:'',
        setFile:action((state,payload)=>{
            state.type=payload.type;
            state.id=payload.id;
            state.date=payload.date;
            state.status = payload.status;
        }),
        reset:action((state,payload)=>{
            state.type = '';
            state.id = '';
            state.date = '';
            state.status = '';
        })
    },
    headerInfo:{
        lastUpdated:'',
        showIgnoreErrorsOption:false,
        setLastUpdated:action((state,payload)=>{
            state.lastUpdated = payload;
        }),
        reset:action((state,payload)=>{
            state.lastUpdated = null;
        }),
        setShowIgnoreErrorsOption:action((state,payload)=>{
            state.showIgnoreErrorsOption = payload;
        })
    },
    forte:{
        termsType:'',
        setTermsType:action((state,payload)=>{
          state.termsType = payload;
        })
    }
});

import React,{useState} from 'react';
import PayoffCalculatorPopup from "./popups/payoffCalculatorPopup";

export default function PayoffCalculatorButton({inverted}){

	const [showPopup,setShowPopup] = useState(false);

	const closePopup = () =>{
		setShowPopup(false);
	};
	return (
		<>
			{showPopup && <PayoffCalculatorPopup closeHandler={closePopup} />}
			<button onClick={()=>{setShowPopup(true)}}  id={'payoff-calc-button'} className={(inverted?'inverted':'')}><span className={'chart'} /><span>Loan Payoff Calculator</span></button>
		</>
	)
}

import React, {useEffect, useState} from 'react';
import AuthPage from "../components/auth/authPage";
import Login from "../components/auth/login";
import {useStoreState} from "easy-peasy";
import SwitchOrganization from "../components/auth/switchOrganization";

export default function LoginScreen(){

	const [screenState,setScreenState] = useState(null);
	const isLoggedIn = useStoreState(state=>state.user.isLoggedIn);
	const activeOrganization = useStoreState(state=>state.user.activeOrganization);
	useEffect(()=>{
		if(!isLoggedIn){
			setScreenState('login');
		}else{
			setScreenState('pickOrganization');
		}
	},[isLoggedIn]);

	return (
		<AuthPage>
			{screenState === 'login' &&
				<Login/>
			}
			{
				screenState === 'pickOrganization' &&
				<SwitchOrganization/>
			}
		</AuthPage>
	)

}

import React from 'react'
import {useLocation} from "react-router";
import NoResults from "../noResults";
import {baseApiUrl, organizationInvoicesUrl} from "../../_constants";
import {useStoreState} from "easy-peasy";
import axios from "axios";

export default function InvoiceList({files,accentColor}){
    const location = useLocation();
    const authToken = useStoreState(state=>state.user.token);

    function downloadFile(path){
        axios.get('/auth/generate-token').then(response=>{
            if(response.data.token){
                window.open(path+response.data.token, '_blank');
            }
        }).catch(e=>{})
    }

    return (
        <div className={'invoices-list dashboard-list'}>
            {location.state.layout!=='mobileMax' &&
                <div className={'table-row file-row header'}>
                    <span>File name</span>
                    <span>Processed</span>
                    <span>status</span>
                    <span>updated</span>
                    <span>Download</span>
                </div>
            }
            {location.state.layout!=='mobileMax' && files.map((file)=>{
                return (
                    <div key={file.file_name+file.invoice_timestamp} className={'table-row  file-row ' + (file.invoice_status_status==='error'?'flagged':'')}>
                        <span >{file.invoice_status_status==='error' && <img src={'/images/new-alert.svg'} alt={'new alert'} /> }{file.file_name}</span>
                        <span >{new Date(file.invoice_timestamp.replace(' ','T')).toLocaleDateString()}</span>
                        <span >{file.invoice_status_status}</span>
                        <span >{new Date(file.invoice_status_timestamp.replace(' ','T')).toLocaleDateString()}</span>
                        <span><a className={'invoice'}
                                 onClick={e=>{e.preventDefault();downloadFile(baseApiUrl+organizationInvoicesUrl(file.organization_id)+'/'+file.invoice_id+'?auth-token=')}}
                                 href={'#'}
                                 ><span style={{color: accentColor}} className={'mdi mdi-file'}/>
                        </a> </span>

                    </div>
                )
            })}
            {(location.state.layout==='mobileMax')  && files.map((file)=>{
                return (
                    <div key={file.file_name+file.invoice_timestamp} className={'table-row  file-row tablet ' + (file.flagged?'flagged':'')}>
                        <div>
                            <span className={'title'}>File name</span>
                            <span >{file.invoice_status_status==='error' && <img src={'/images/new-alert.svg'} alt={'new alert'} /> }{file.file_name}</span>
                        </div>
                        <div>
                            <span className={'title'}>Processed</span>
                            <span >{new Date(file.invoice_timestamp.replace(' ','T')).toLocaleDateString()}</span>
                        </div>
                        <div>
                            <span className={'title'}>Status</span>
                            <span >{file.invoice_status_status}</span>
                        </div>
                        <div>
                            <span className={'title'}>Updated</span>
                            <span >{new Date(file.invoice_status_timestamp.replace(' ','T')).toLocaleDateString()}</span>
                        </div>
                        <div>
                                <span><a className={'invoice'}
                                         onClick={e=>{e.preventDefault();downloadFile(baseApiUrl+organizationInvoicesUrl(file.organization_id)+'/'+file.invoice_id+'?auth-token=')}}
                                         href={'#'}
                                        ><span style={{color: accentColor}} className={'mdi mdi-file'}/>
                                </a> </span>
                        </div>
                    </div>
                )
            })}
            {files.length<=0 && <NoResults />}
        </div>
    )

}



import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom'
import {useStoreActions, useStoreState} from "easy-peasy";
import {EmployeeMainMenu, EmployerMainMenu} from "../../_menus";
import {getTextWidth} from "../../_helpers";
import {ADMIN_TYPE, EMPLOYEE_TYPE, EMPLOYER_CONTACT_TYPE, HR_TYPE} from "../../_constants";

export default function MainMenu({clickHandler,withAccountInfo}){
    const location = useLocation();
    const userType = useStoreState(state=>state.user.type);
    const [menuItems,setMenuItems] = useState([]);
    const activeOrganization = useStoreState(state=>state.user.activeOrganization);
    const setAuthToken = useStoreActions(actions => actions.user.updateToken);
    const availableOrganizations = useStoreState(state=>state.user.organizations);


    const logout = () =>{
        if(clickHandler){
            clickHandler(false)
        }
      setAuthToken(null);
    };
    useEffect(()=>{
        switch (userType) {
            case HR_TYPE:
                setMenuItems(EmployerMainMenu);
                break;
            case EMPLOYER_CONTACT_TYPE:
                setMenuItems(EmployerMainMenu);
                break;
            case ADMIN_TYPE:
                setMenuItems(EmployerMainMenu);
                break;
            case EMPLOYEE_TYPE:
                setMenuItems(EmployeeMainMenu);
                break;
            default:
                setMenuItems([]);
        }
    },[userType]);



    return(
        <div className={'main-menu'}>
            {menuItems.map((item,index)=>(
                    <Link
                        onClick={()=>{if(clickHandler){clickHandler(false)}}}
                        style={{width:getTextWidth(item.title,'500 15px "roboto", sans-serif')}}
                        key={index}
                        to={item.to}
                        className={location.pathname.indexOf(item.to)>=0?'active':''}>
                            {item.title}
                    </Link>
                )
            )}
            {withAccountInfo &&
                <>
                    <Link
                        onClick={()=>{if(clickHandler){clickHandler(false)}}}
                        to={'/account'}>Account Settings</Link>
                    {availableOrganizations.filter(role => role.user_organization_role_status === 'active').length > 1 &&
                        <Link onClick={() => {
                            if (clickHandler) {
                                clickHandler(false)
                            }
                        }}
                              to={'/switch'}
                        >Switch Accounts<img src={"/images/switch.svg"}/></Link>
                    }
                    {(activeOrganization && (activeOrganization.role_token === ADMIN_TYPE || activeOrganization.role_token === EMPLOYER_CONTACT_TYPE)) &&
                    <Link onClick={() => {
                        if (clickHandler) {
                            clickHandler(false)
                        }
                    }} to={'/company-account'}>Company Account</Link>
                    }
                    <span className={'clickable'} onClick={()=>{logout()}}>Log Out <img src={"/images/logout.svg"}/></span>

                </>
            }
        </div>
    )
}



import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Popup from "./popup";
import ProviderSelector from "../providerSelector";
import AccountSelector from "../accountSelector";

export default function RegisterAccountPopup({data,subType,closeHandler,small,authToken,addAccountManual}){


	const [accounts,setAccounts] = useState([]);
	const [updateKey,setUpdateKey] = useState(Math.random());
	const [selectedAccount,setSelectedAccount] = useState(null);
	const [institution,setInstitution] = useState(null);
	useEffect(()=>{

		if(!data || !data.accounts || !subType){
			return
		}
		setAccounts(data.accounts.filter(account=>account.subtype === subType));
		let config = {};
		if(authToken){
			config.headers = {'Auth-token': authToken};
		}
		axios.post('/plaid/institution',{institution_id:data.item.institution_id},config).then(response=>{

			if(response.data.institution){
				let newInstitution = response.data.institution;
				setInstitution(newInstitution);
				setAccounts(data.accounts.filter(account=>account.subtype === subType).map(account=>{
					if(newInstitution.logo) {
						account.logo = 'data:image/png;base64,' + newInstitution.logo;
					}
					account.selected = !!(selectedAccount && account.account_id === selectedAccount.account_id);
					return account;
				}));
				setUpdateKey(Math.random());
			}
		}).catch(e=>{})
		setUpdateKey(Math.random());
	},[data,subType])

	const submit = () =>{
		closeHandler(selectedAccount,institution);
	};
	return (
		<Popup bodyClass={small?'register-accounts':null} >
			<div className={'popup-content register-account-screen'}>
				<img src={'/images/plaid_logo.svg'} alt={'plaid'} />
				<h1>Account Connected</h1>
				{(accounts && accounts.length>0) &&
					<span>Select Your Account</span>
				}
				{(!accounts || accounts.length<=0) &&
					<>
					<span>No compatible accounts available from this institution</span>

					</>
				}
				<AccountSelector options={accounts} changeHandler={setSelectedAccount} updateKey={updateKey}/>
				{(data && data.item.institution_id && subType==='student') &&
				<span>Have an account that isn't listed? <a href={'#'} onClick={e => {
					e.preventDefault();
					addAccountManual(data.item)
				}}>Add it manually.</a> </span>
				}
				<button onClick={submit} className={'simple-rounded-button small fixed green'}><span>Continue</span></button>
				<span onClick={()=>{closeHandler(null)}} className={'clickable standard-link green'}>Close</span>
			</div>
		</Popup>
	)
}

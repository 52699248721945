import React, {useEffect,useState} from 'react';
import axios from 'axios';

export default function PlanDetailsSlide({organization,authToken}){

	const [feeDetails,setFeeDetails] = useState();
	const [planDetails,setPlanDetails] = useState();

	useEffect(()=>{
		axios.get('/organizations/'+organization.organization_id+'/plan',{headers:{'Auth-token': authToken}}).then(response=>{
			setFeeDetails(response.data.fees_details);
			setPlanDetails(response.data.plan_details);
		}).catch(e=>{})
	},[organization]);

	return (
		<div className={'slide welcome-slide plan-details-slide'}>
			<div className={'title'}>
				<img className={'thrive-logo'} src={'/images/logo-no-text.svg'} alt={'thrive'} />
				<h1>Your Thrive Program</h1>
			</div>
			<div className={'plan-details-holder'}>
				<div className={'plan-details'}>
					<h3>Plan Details</h3>
					<span>{organization.plan_title}</span>
					{planDetails && planDetails.map((detail,index)=><span key={'plan-'+index}>{detail}</span>)}
				</div>
				<div className={'fees'}>
					<h3>Fees</h3>
					{feeDetails &&
						feeDetails.map((detail,index)=>{
							return (
								<div key={'fee-'+index}>
									{detail.title && <h4>{detail.title}</h4>}
									{detail.fees &&
										detail.fees.map((fee,findex)=>{
											if(fee[1]){
												return (
													<div key={'fee-detail-'+findex}>
														<span>{fee[0]}</span>
														<span>{fee[1]}</span>
													</div>
												)
											}else{
												return null;
											}
										})
									}
								</div>
							)
						})
					}
				</div>
			</div>
		</div>
	)
}

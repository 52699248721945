import React, {useEffect, useRef, useState} from 'react';
import {
    baseApiUrl,
    Months,
    organizationCycleDownloadUrl, organizationEligibilityTemplateUrl,
    organizationEligibilityUploadUrl, organizationInvoicesUrl,
    organizationPayrollTemplateUrl, organizationPayrollUploadUrl,
    organizationPayrollUrl, organizationPendingCycleDownloadUrl, UPDATE_EMPLOYEES, UPDATE_PAYROLL
} from "../_constants";
import axios from 'axios';
import {useStoreActions, useStoreState} from "easy-peasy";
import {getHttpErrorMessage} from "../_helpers";
import UploadErrorPopup from "./popups/uploadErrorPopup";

export default function HeaderFileBox({header,uploadCallback}){

    const fileUploadRef = useRef();
    const [uploading,setUploading] = useState(false);
    const [uploadPercentage,setUploadPercentage] = useState(0);
    const [uploadMessage,setUploadMessage] = useState('');
    const [uploadError,setUploadError] = useState(false);
    const setMessage = useStoreActions(actions=>actions.alertMessage.setMessage);
    const authToken = useStoreState(state=>state.user.token);
    const activeOrganization = useStoreState(state=>state.user.activeOrganization);
    const setFileUploadResponse = useStoreActions(actions=>actions.fileUploadResponse.setFile);
    const newestFileForDownload = useStoreState(state=>state.newestFileForDownload);
    const [templateDownloadUrl,setTemplateDownloadUrl] = useState('');
    const [cancelId,setCancelId] = useState(null);
    const [errorList,setErrorList] = useState([]);
    const [showErrorList,setShowErrorList] = useState(false);
    const setNewestFile = useStoreActions(actions=>actions.newestFileForDownload.setFile);
    const setShowIgnoreErrorsOption = useStoreActions(actions=>actions.headerInfo.setShowIgnoreErrorsOption);
    const showIgnoreErrorsOption = useStoreState(state=>state.headerInfo.showIgnoreErrorsOption);
    const [ignoreErrors,setIgnoreErrors] = useState(false);

    useEffect(()=>{
        setUploadMessage('');
        switch (header.uploadType) {
            case 'employee':
                setCancelId(null);
                setTemplateDownloadUrl(baseApiUrl+organizationEligibilityTemplateUrl(activeOrganization['organization_id'])+'?auth-token=');
                break;
            case 'payroll':
                if(newestFileForDownload.type==='payroll' && newestFileForDownload.status === 'pending'){
                    setCancelId(newestFileForDownload.id);
                }else{
                    setCancelId(null);
                }
                setTemplateDownloadUrl(baseApiUrl+organizationPayrollTemplateUrl(activeOrganization['organization_id'])+'?auth-token=');
                break;
            default:
                break;
        }
    },[header,authToken]);

    useEffect(()=>{
        if(newestFileForDownload.type==='payroll' && newestFileForDownload.status === 'pending'){
            setCancelId(newestFileForDownload.id);
        }else{
            setCancelId(null);
        }
    },[newestFileForDownload]);

    const getIcon = () =>{
        switch(header.icon){
            case 'pdf':
                return <img src="/images/pdf-icon.svg" alt="pdf" />;
            default:
                return '';
        }
    };
    const getFileName = () =>{
        if(header.type === 'download'){
            return header.fileName;
        }
        return header.fileName + ' Upload';
    };
    const getDate = ()=>{
        if(getFileDownloadPath() && getFileDownloadPath().indexOf('/pending-invoice?') >= 0 ){
            return null;
        }
        if(getFileDownloadPath() && (getFileDownloadPath().indexOf('election-cycles/pending?') >= 0 || getFileDownloadPath().indexOf('payments/pending?') >= 0)){
            let d = new Date();
            let month = d.getMonth();
            let day = d.getDate();
            if(day>25){
                month += 1;
            }
            return Months[month%12] + ' ' + d.getFullYear();
        }
        if(header.downloadType && newestFileForDownload.date){
            let d = new Date(newestFileForDownload.date);
            return Months[d.getMonth()] + ' ' + d.getFullYear();
        }
        return null;
    };
    const getFileUploadPath = () =>{
        if(!header.uploadType){
            return false;
        }
        switch(header.uploadType){
            case "payroll":
                return organizationPayrollUploadUrl(activeOrganization['organization_id']);
            case "employee":
                return organizationEligibilityUploadUrl(activeOrganization['organization_id']);
            default:
                break;
        }
    };
    const getFileDownloadPath = () =>{
        if(activeOrganization.plan_token === 'employer-direct' && header.downloadType === 'invoice' && activeOrganization.has_pending_invoice){
            return baseApiUrl+organizationInvoicesUrl(activeOrganization['organization_id'])+'/pending-invoice?auth-token=';
        }
        if(header.downloadType === 'elections'){
            return baseApiUrl+organizationPendingCycleDownloadUrl(activeOrganization['organization_id'],newestFileForDownload.id)+'?auth-token=';
        }
        if(header.downloadType === 'payments'){
            return baseApiUrl+'/organizations/'+activeOrganization['organization_id']+'/payments/pending?auth-token=';
        }
        if(!header.downloadType || header.downloadType !== newestFileForDownload.type){

            return false;
        }
        switch(header.downloadType){
            case "elections":
                return baseApiUrl+organizationCycleDownloadUrl(activeOrganization['organization_id'],newestFileForDownload.id)+'?auth-token=';
            case "invoice":
                return baseApiUrl+organizationInvoicesUrl(activeOrganization['organization_id'])+'/'+newestFileForDownload.id+'?auth-token=';
            case "payments":
                return baseApiUrl+organizationInvoicesUrl(activeOrganization['organization_id'])+'/'+newestFileForDownload.id+'?auth-token=';
            default:
                break;
        }
    };
    const uploadClickHandler = (e) =>{
        e.preventDefault();
        fileUploadRef.current.value = null;
        if(!cancelId || !header.uploadType || header.uploadType !== 'payroll') {
            fileUploadRef.current.click()
        }else{
            axios.delete('/organizations/'+activeOrganization['organization_id']+'/payroll/uploads/'+cancelId).then(response=>{
                setFileUploadResponse(UPDATE_PAYROLL);
                setCancelId(null);
            }).catch(e=>{})
        }
    };

    const onUploadProgress = (progressEvent) =>{
        setUploadPercentage(Math.round( (progressEvent.loaded * 100) / progressEvent.total ))
    };


    const fileSelected = (shouldIgnoreErrors = false) =>{
        if(uploading){
            return;
        }
        const fileUploadPath = getFileUploadPath();
        if(!fileUploadPath){
            setMessage({message:'Upload Path missing',type:'error'});
            return;
        }
        let files = fileUploadRef.current.files;
        setUploading(true);
        let formData = new FormData();
        formData.append('file',files[0]);

        if(shouldIgnoreErrors){
            formData.set('ignore_errors',true);
        }

        axios.post(fileUploadPath,formData,{headers: {'Content-Type': 'multipart/form-data' }, onUploadProgress:onUploadProgress}).then(response=>{
            if(response.data.meta && response.data.meta.message){
                setMessage({message:response.data.meta.message});
            }
            setIgnoreErrors(false);
            if(response.data.errors){
                setErrorList(response.data.errors);
                setShowErrorList(true);
                setUploading(false);
                setUploadPercentage(0);


                if(response.data.broken) {
                    setShowIgnoreErrorsOption(true);
                    setUploadError(true);
                    return;
                }else{
                    setShowIgnoreErrorsOption(false);
                    setUploadError(false);
                }
            }else{
                setShowIgnoreErrorsOption(false);
                setErrorList([]);
                setShowErrorList(false);
            }

            let uploadResponse = null;
            switch(header.uploadType){
                case "payroll":
                    uploadResponse = UPDATE_PAYROLL;
                    if(response.data.payroll_upload){
                        setCancelId(response.data.payroll_upload.payroll_upload_id)
                    }
                    break;
                case "employee":
                    uploadResponse = UPDATE_EMPLOYEES;
                    break;
                default:
                    break;
            }


            setFileUploadResponse(uploadResponse);
            setUploading(false);
            setUploadPercentage(0);
            let message = (response.data && response.data.meta)?response.data.meta.message:'';
            setUploadMessage(message);
            setUploadError(false)
        }).catch(error=>{
            if(fileUploadRef.current) {
                fileUploadRef.current.value = null;
            }
            setUploading(false);
            let message = getHttpErrorMessage(error);
            setUploadMessage(message);
            setUploadError(true);
        });
    };

    const reSubmitWithErrors = () =>{
        fileSelected(true);
    }

    function DownloadTitle(){
        if(getFileDownloadPath().indexOf('/pending-invoice?') >= 0){
            return <span>Download Pending Invoice</span>
        }
        let d = new Date();
        let month = d.getMonth();
        let day = d.getDate();
        if(day>25){
            month += 1;
        }
        if(getFileDownloadPath().indexOf('election-cycles/pending?') >= 0){
            return <span>Download Pending {Months[(month+1)%12]} Elections</span>
        }
        if(getFileDownloadPath().indexOf('payments/pending?') >= 0){
            return <span>Download Pending {Months[(month+1)%12]} Payments</span>
        }
        return <span>Download Here</span>
    }

    function downloadFile(e){
        e.preventDefault();
        let filePath = getFileDownloadPath();

        axios.get('/auth/generate-token').then(response=>{
            if(response.data.token){
                window.open(filePath+response.data.token, '_blank');
            }
        }).catch(e=>{})
    }

    function downloadTemplate(e){
        e.preventDefault();


        axios.get('/auth/generate-token').then(response=>{
            if(response.data.token){
                window.open(templateDownloadUrl+response.data.token, '_blank');
            }
        }).catch(e=>{})
    }

    return (
        <>
            {showErrorList && <UploadErrorPopup closeHandler={()=>{setShowErrorList(false);}} errors={errorList} showIgnoreErrorOption={header.fileName.toLowerCase().indexOf('payroll')>-1 && showIgnoreErrorsOption} submitHandler={reSubmitWithErrors} />}
        <div className={'header-file-box'}>
            <div className={'inner-box'} >
                <div className={'content'}>
                    {!!header.icon && getIcon()}
                    <h2 style={{color:header.color}}>{getFileName()}</h2>
                    {getDate() && <p>{getDate()}</p> }
                    {!getDate() && <p></p> }
                    {header.type === 'upload' &&
                    <>
                       <button onClick={(e)=>{uploadClickHandler(e)}} style={{backgroundColor:header.color}} className={'simple-rounded-button approved-only'} >
                           {cancelId && <span>Processing Pending</span>}
                           {!cancelId && <span>{uploading?uploadPercentage<100?'Uploading ' + uploadPercentage+'%':'Processing...':'Browse'}</span>}
                       </button>
                        <input
                            className={'approved-only'}
                            ref={fileUploadRef}
                            type='file'
                            style={{display:'none'}}
                            accept={".csv"}
                            onChange={()=>{fileSelected()}}/>
                        {uploading &&
                            <div className={'upload-progress-bar-holder'}>
                                <div className={'upload-progress-bar'}>
                                    <span style={{width: uploadPercentage+'%'}}/>
                                </div>
                            </div>
                        }
                        {!uploading &&
                            <div className={'upload-message ' + (uploadError?'error':'')}>
                                {cancelId && ((uploadMessage?uploadMessage+' - ':'') +'You can cancel the current pending file before 6:00 PM ET by clicking the green box.')}
                                {(!cancelId && uploadMessage) && uploadMessage}
                            </div>
                        }
                    </>
                    }
                    {(header.type === 'download' && getFileDownloadPath()) &&
                    <>
                        <a style={{backgroundColor:header.color}} className={'simple-rounded-button'}
                           href={'#'} onClick={downloadFile}>
                            <DownloadTitle/>
                        </a>
                        {getFileDownloadPath().indexOf('/pending-invoice?') >= 0 &&
                            <div className={'upload-message'}>
                                Pending Invoice shows charges based on current enrolled employees' settings.
                            </div>
                        }
                    </>
                    }
                    {(header.type === 'download' && !getFileDownloadPath()) &&
                        <div className={'upload-message'}>
                            No Files Currently Available.
                        </div>
                    }
                </div>
                {(header.type==='upload' && templateDownloadUrl.length>0) &&
                    <a href={'#'} className={'template'} onClick={downloadTemplate} >
                        <span style={{color:header.color}} className={'mdi mdi-file'} />Download {header.fileName} Template
                    </a>
                }

            </div>
        </div>
        </>
    )
}

import React, {useCallback, useEffect, useState} from 'react';
import {Prompt} from "react-router";
import ProviderSelector from "../../../components/providerSelector";
import InstitutionSelectorPopup from "../../../components/popups/institutionSelectorPopup";
import RegisterAccountPopup from "../../../components/popups/registerAccountPopup";
import {useStoreActions, useStoreState} from "easy-peasy";
import axios from "axios";
import {plaidEmployeeLoanTokenUrl, plaidEmployeeTokenUrl} from "../../../_constants";
import NumberFormat from "react-number-format";
import BankInfoPopup_529 from "../../../components/popups/bankInfoPopup";
import LoanAccountPopup from "../../../components/popups/loanAccountPopup";
import ManualLoanOptionPopup from "../../../components/popups/manualOptionPopup";
import ManualSavingsPopup from "../../../components/popups/manualSavingsPopup";
import SavingsMicroDepositsPopup from "../../../components/popups/savingsMicroDepositsPopup";
import useAlertOnUnsaved from "../../../hooks/useAlertOnUnsaved"

export default function EmployeeAccounts(){

	const showMessage = useStoreActions(actions=>actions.alertMessage.setMessage);
	const setLastUpdated = useStoreActions(actions=>actions.headerInfo.setLastUpdated);
	const [plaidHandler,setPlaidHandler] = useState(null);
	const [plaidLoanHandler,setPlaidLoanHandler] = useState(null);
	const [showNewAccountPopup,setShowNewAccountPopup] = useState(false);
	const [selectedAccountType,setSelectedAccountType] = useState('');
	const [accountsResponse,setAccountsResponse] = useState(null);
	const [totalAllocation,setTotalAllocation] = useState(0);
	const [linkedAccounts,setLinkedAccounts] = useState([]);
	const [showInstitutionPopup,setShowInstitutionPopup] = useState(false);
	const user = useStoreState(state=>state.user.user);
	const activeOrganization = useStoreState(state=>state.user.activeOrganization);
	const [show529Popup,setShow529Popup] = useState(false);
	const [showManualLoanPopup,setShowManualLoanPopup] = useState(false);
	const [showManualSavingsPopup,setShowManualSavingsPopup] = useState(false);
	const [connectionItem,setConnectionItem] = useState(null);
	const [showManualOptionPopup,setShowManualOptionPopup] = useState(false);
	const [showDepositsForm,setShowDepositsForm] = useState(false);
	const [selectedAccount,setSelectedAccount] = useState(null);
	const [unsavedChanges,setUnsavedChanges] = useState(false);
	useAlertOnUnsaved(unsavedChanges);
	const manualOptionCloser = (openAddPopup = false) =>{
		setShowManualOptionPopup(false);
		if(openAddPopup){
			switch(selectedAccountType){
				case 'student':
					setShowManualLoanPopup(true);
					break;
				case 'savings':
					setShowManualSavingsPopup(true);
					break;
				default:
					break;
			}

		}else{
			setTimeout(()=>{document.body.style.overflow = 'scroll';},30);
		}
	}

	const manualLoanPopup = (newConnectionItem = null) =>{
		setShow529Popup(false);
		setShowInstitutionPopup(false);
		setShowNewAccountPopup(false);
		setConnectionItem(newConnectionItem)
		setShowManualLoanPopup(true);
	}

	const manualPopupResponseHandler = (response) =>{
		if(response.data.account){
			setUnsavedChanges(true);
			setLinkedAccounts([response.data.account, ...linkedAccounts]);
		}
		setShowManualLoanPopup(false);
		setShowManualSavingsPopup(false);
		setTimeout(()=>{document.body.style.overflow = 'scroll';},30);
	};

	const depositsPopupResponseHandler = (response) =>{
		if(response.data.account_id){
			setLinkedAccounts(linkedAccounts.map(account=>{
				if(account['account_id'] === response.data.account_id){
					account['savings_account_verified'] = true;
				}
				return account
			}))
		}
		setShowDepositsForm(false);
	}


	const onExit = () => {
		if(activeOrganization['organization_allow_manual_savings'] === 'yes') {
			setShowManualOptionPopup(true);
		}
		setTimeout(()=>{document.body.style.overflow = 'scroll';},30);
	};

	const onStudentExit = () => {
		setShowManualOptionPopup(true);
		setTimeout(()=>{document.body.style.overflow = 'scroll';},30);
	};

	const onSuccess = useCallback((token, metadata) => {
		axios.post('/plaid/accounts',{public_token:token}).then(response=>{
			setAccountsResponse(response.data);
			setShowNewAccountPopup(true);
		}).catch(e=>{})
	}, []);

	useEffect(()=>{
		const plaidCancelToken = axios.CancelToken.source();
		const accountsToken = axios.CancelToken.source();
		setLastUpdated('...');
		axios.post(plaidEmployeeTokenUrl, {}, {cancelToken:plaidCancelToken.token}).then(result=>{
			if (result.error != null) {
				showMessage({type:'error',message: result.error});
				return;
			}
			setPlaidHandler(window.Plaid.create({
				token: result.data.link_token,
				onSuccess: onSuccess,
				onExit:onExit
			}));
		});

		axios.post(plaidEmployeeLoanTokenUrl, {}, {cancelToken:plaidCancelToken.token}).then(result=>{
			if (result.error != null) {
				showMessage({type:'error',message: result.error});
				return;
			}
			setPlaidLoanHandler(window.Plaid.create({
				token: result.data.link_token,
				onSuccess: onSuccess,
				onExit:onStudentExit
			}));
		});

		axios.get('/organizations/'+activeOrganization.organization_id+'/users/'+user.user_id+'/accounts').then(response=>{
			let existingAccounts = response.data.accounts;
			let lastUpdated = 0;
			existingAccounts.forEach(account=>{
				if(account.account_timestamp_updated) {
					let date = account.account_timestamp_updated?new Date(account.account_timestamp_updated.replace(' ','T')).valueOf():0;
					lastUpdated = Math.max(lastUpdated, date);
				}
			});
			if(lastUpdated>0){
				setLastUpdated(new Date(lastUpdated).toLocaleDateString())
			}else{
				setLastUpdated('hide');
			}
			setLinkedAccounts(existingAccounts);
			let totalAlc = 0;
			existingAccounts.forEach(acc=>{totalAlc += parseInt(acc.account_allocation || 0)});
			setTotalAllocation(totalAlc);

		}).catch(e=>{});
		return ()=>{
			plaidCancelToken.cancel('');
			accountsToken.cancel('')
		}
	},[]);

	useEffect(()=>{
		if(linkedAccounts && linkedAccounts.length === 1 && linkedAccounts[0].account_allocation == 0){
			updateAccountAllocation(100,linkedAccounts[0]);
			saveSettings();
		}
	},[linkedAccounts])

	const plaidButtonHandler = (accountType) =>{

		setSelectedAccountType(accountType);
		// setShowInstitutionPopup(true);
		if(accountType === 'student'){
			plaidLoanHandler.open();
			return;
		}
		plaidHandler.open();
	};

	const registerAccountPopupCloser = (account,institution) =>{
		if(!account || !institution){
			setShowNewAccountPopup(false);
			setAccountsResponse(null);
			showMessage({message:'Could not retrieve account or bank info, please try again later',type:'error'});
			return;
		}
		const postData = {
			account:account,
			item:accountsResponse.item,
			connection_id:accountsResponse.connection_id,
			institution:institution,
			organization_id:activeOrganization.organization_id
		};
		axios.post('/plaid/register-account',postData).then(response=>{
			setShowNewAccountPopup(false);
			setAccountsResponse(null);
			if(linkedAccounts.filter(acc=>acc.plaid_account_id === response.data.account.plaid_account_id).length>0){
				showMessage({message:'Account Already Listed',type:'error'});
			}else {
				setUnsavedChanges(true);
				setLinkedAccounts([response.data.account, ...linkedAccounts]);
			}
			setTimeout(()=>{document.body.style.overflow = 'scroll';},30);
		}).catch(e=>{});

	};

	const popupResponseHandler_529 = (response) =>{
		if(response.data.account){
			setLinkedAccounts([response.data.account, ...linkedAccounts]);
			setUnsavedChanges(true);
		}
		setShow529Popup(false);
		setTimeout(()=>{document.body.style.overflow = 'scroll';},30);
	};

	const getAccountType = (account) =>{

		switch (account.account_type_token) {
			case 'student':
				return 'Student Loan';
			case 'savings':
				return 'Emergency Savings Account';
			case '529':
				return 'College savings plan';
			case 'education savings account':
				return 'Tax-advantaged Education Savings Account (ESA)';
			default:
				return 'Account';
		}
	};

	const updateAccountAllocation = (value,newAccount) =>{
		let newTotalAllocation = 0;
		setLinkedAccounts(linkedAccounts.map(account=>{

			if((account.plaid_account_id && account.plaid_account_id === newAccount.plaid_account_id) || (account.account_id && account.account_id === newAccount.account_id)){
				account.account_allocation = value;
			}
			newTotalAllocation += parseInt(account.account_allocation || 0);
			return account;
		}));
		setUnsavedChanges(true);
		setTotalAllocation(newTotalAllocation);
	};

	const institutionPopupCloseHandler = (data) => {
		setShowInstitutionPopup(false);
		if(!data){
			return;
		}
		if(data === 'new'){
			if(selectedAccountType === 'student'){
				plaidLoanHandler.open();
				return;
			}
			plaidHandler.open();
			return;
		}
		if(data.accounts){
			setAccountsResponse(data);
			setShowNewAccountPopup(true);
		}
	};

	const reallocateDeletedAllocation = (deletedAllocation,remainingAccounts) =>{
		setUnsavedChanges(true);
		let split = Math.round(parseInt(deletedAllocation)/remainingAccounts.length);
		let numbOfRemainingAccounts = remainingAccounts.length;
		let totalAlc = 0;
		remainingAccounts.forEach(acc=>{totalAlc += parseInt(acc.account_allocation || 0)});
		let remainder = (100 - totalAlc) - (split * numbOfRemainingAccounts);
		return remainingAccounts.map((account,index)=>{
			account.account_allocation = parseInt(account.account_allocation)+split;
			if(index===0 && remainder){
				account.account_allocation += remainder;
			}
			return account;
		});
	};

	const removeAccount = (account) =>{
		if(!account.account_id){
			let existingAccounts = linkedAccounts.filter(a=>a.plaid_account_id !== account.plaid_account_id);
			existingAccounts = reallocateDeletedAllocation(account.account_allocation,existingAccounts);
			setLinkedAccounts(existingAccounts);
			let totalAlc = 0;
			existingAccounts.forEach(acc=>{totalAlc += parseInt(acc.account_allocation || 0)});
			setTotalAllocation(totalAlc);
			return;
		}
		axios.delete('/organizations/'+activeOrganization.organization_id+'/users/'+user.user_id+'/accounts/'+account.account_id).then(response=>{
			let existingAccounts = linkedAccounts.filter(a=>a.account_id !== account.account_id);
			existingAccounts = reallocateDeletedAllocation(account.account_allocation,existingAccounts);
			setLinkedAccounts(existingAccounts);
			let totalAlc = 0;
			existingAccounts.forEach(acc=>{totalAlc += parseInt(acc.account_allocation || 0)});
			setTotalAllocation(totalAlc);
			if(existingAccounts.length > 0) {
				saveSettings(existingAccounts)
			}else{
				setUnsavedChanges(false);
			}
		}).catch(e=>{})
	};

	const saveSettings = (accounts = null) =>{
		let postAccounts = accounts || linkedAccounts;
		axios.post('/organizations/'+activeOrganization.organization_id+'/users/'+user.user_id+'/accounts',{accounts:postAccounts}).then(response=>{
			if(!response.data.meta.message) {
				showMessage({message: 'Account Allocation Settings Saved.'});
			}
			setUnsavedChanges(false);
		}).catch(e=>{});
	};

	const checkForAccountType = (typeToken) =>{
		if(!activeOrganization){
			return false;
		}
		return activeOrganization.organization_account_types.some(at=>at.account_type_token === typeToken);
	};

	return (
		<>
			<Prompt
				when={unsavedChanges}
				message={() => 'You have unsaved changes, are you sure you want to leave?'}
			/>
			{show529Popup && <BankInfoPopup_529 sucessHandler={popupResponseHandler_529} closeHandler={()=>{setShow529Popup(false)}} organization={activeOrganization} user={user} />}
			{showInstitutionPopup && <InstitutionSelectorPopup subtype={selectedAccountType} closeHandler={institutionPopupCloseHandler} />}
			{showNewAccountPopup && <RegisterAccountPopup addAccountManual={manualLoanPopup} small={true} data={accountsResponse} subType={selectedAccountType} closeHandler={registerAccountPopupCloser} />}
			{showManualLoanPopup && <LoanAccountPopup organization={activeOrganization} subType={selectedAccountType} preselectedBank={connectionItem} sucessHandler={manualPopupResponseHandler} closeHandler={()=>{setShowManualLoanPopup(false)}} />}
			{showManualSavingsPopup && <ManualSavingsPopup organization={activeOrganization} subType={selectedAccountType} user={user} sucessHandler={manualPopupResponseHandler} closeHandler={()=>{setShowManualSavingsPopup(false)}} />}
			{showManualOptionPopup && <ManualLoanOptionPopup closeHandler={manualOptionCloser} />}
			{showDepositsForm && <SavingsMicroDepositsPopup closeHandler={()=>{setShowDepositsForm(false)}} sucessHandler={depositsPopupResponseHandler} organization={activeOrganization} user={user} accountId={selectedAccount}  />}
			<div className={'account-block'}>
				<div className={'add-account-holder'}>
					<div className={'add-account-buttons'}>
						{checkForAccountType('529') && <button onClick={()=>{setShow529Popup(true)}} ><span><strong>+</strong> Add 529</span></button>}
						{(plaidHandler || plaidLoanHandler) &&
						<>
							{checkForAccountType('emergency') && <button onClick={()=>{plaidButtonHandler('savings')}} ><span><strong>+</strong> Add Emergency Savings</span></button>}
							{checkForAccountType('student') && <button onClick={()=>{plaidButtonHandler('student')}}><span><strong>+</strong> Add Student Loan</span></button>}
						</>
						}
					</div>
				</div>
			</div>

			{linkedAccounts.filter(acc=>!!acc).map((account,index)=>{
				let accountName = account.account_name + (account.plaid_account_mask?' ('+account.plaid_account_mask+')':'') + (account.plaid_account_balance_balance?' ($'+account.plaid_account_balance_balance+')':'')
				return(
					<div key={(account.plaid_account_id|| account.account_id) + index} className={'account-block ' + (!account['savings_account_verified']?'unverified':'')}>
						<div className={'account-title'}>
							<h2>{getAccountType(account)}</h2>
							<button onClick={()=>{removeAccount(account)}}>Remove</button>
						</div>

						<div className={'account-details '+(account['savings_account_verified']?'':'unverified ')}>
							<label>
								Provider
								<ProviderSelector inputDisabled={true} isDisplayOnly={true} options={[{label:account.institution_name,value:account.pliad_institution_id,logo:account.plaid_institution_logo?'data:image/png;base64,'+account.plaid_institution_logo:null}]} changeHandler={()=>{}} />
							</label>
							<label>
								Account
								<ProviderSelector inputDisabled={true} isDisplayOnly={true} options={[{label:accountName,value:account.account_name,logo:null}]} changeHandler={()=>{}} />
							</label>
							<label>
								Allocation {account['savings_account_verified']?'':'*'}
								<div className={'input-holder dollar'}>
									<div className={'input'}>
										<NumberFormat value={account.account_allocation}
													  thousandSeparator={true}
													  decimalScale={0}
													  maxLength={3}
													  onValueChange={(values) => {
														  const {formattedValue, value} = values;
														  updateAccountAllocation(value,account);
													  }}
										/>
										<span>%</span>
									</div>
								</div>
							</label>

						</div>
						{!account['savings_account_verified'] &&
							<div style={{marginTop:10}}>
								<button  className={'clickable simple-rounded-button green'} onClick={()=>{setSelectedAccount(account['account_id']); setShowDepositsForm(true);}} >
									<span>Verify Your micro-deposits</span>
								</button>
								<span style={{marginLeft:'10px'}}>* 2 micro-deposits were made to this account and must be confirmed before payments can be made.</span>
							</div>

						}
					</div>
				)

			})
			}
			{linkedAccounts.length === 0 &&
				<div className={'account-block empty'}>
					<div>
						<h2>No Accounts Registered</h2>
						<p>Please Connect an Account</p>
					</div>
				</div>

			}

			{linkedAccounts.length > 0 &&
			<>
				<div className={'contribution-disclosure accounts in-slide'}>
					Contribution Amount Allocated <NumberFormat value={totalAllocation}
					                                            thousandSeparator={true}
					                                            className={totalAllocation !== 100?'error':''}
					                                            decimalScale={0}
					                                            suffix={'%'}
					                                            displayType={'text'}

				/>
				</div>

				<div className={'row'}>
					<button onClick={()=>{saveSettings()}} className={'simple-rounded-button blue'}><span>Save Changes</span>
					</button>
				</div>
			</>
			}
		</>
	)
}

import React from 'react'
import {useLocation} from "react-router";
import {useStoreState} from "easy-peasy";
import {baseApiUrl, organizationCycleDownloadUrl} from "../../_constants";
import NoResults from "../noResults";
import axios from "axios";

export default function ElectionMatchList({files,accentColor,linkClass,viewHandler,}){
    const location = useLocation();
    const activeOrganization = useStoreState(state=>state.user.activeOrganization);
    const authToken = useStoreState(state=>state.user.token);

    function downloadFile(path){
        axios.get('/auth/generate-token').then(response=>{
            if(response.data.token){
                window.open(path+response.data.token, '_blank');
            }
        }).catch(e=>{})
    }
    return (
        <div className={linkClass+'-list dashboard-list'}>
            {location.state.layout!=='mobileMax' &&
                <div className={'table-row file-row header'}>
                    <span>File name</span>
                    <span>Upload Date</span>
                    <span>Download</span>
                </div>
            }
            {location.state.layout!=='mobileMax' && files.map((file)=>{
                return (
                    <div key={file.election_cycle_id} className={'table-row  file-row ' + (file.flagged?'flagged':'')}>
                        <span >{file.flagged && <img src={'/images/new-alert.svg'} alt={'new alert'} /> }{file.election_cycle_title}</span>
                        <span >{new Date(file.election_cycle_timestamp.replace(' ','T')).toLocaleDateString()}</span>
                        <span><a className={linkClass}
                                 onClick={e=>{e.preventDefault();downloadFile(baseApiUrl+file.election_cycle_download_url+'?auth-token=')}}
                                 href={'#'}
                                 ><span style={{color: accentColor}} className={'mdi mdi-file'}/>
                        </a> </span>
                    </div>
                )
            })}
            {(location.state.layout==='mobileMax')  && files.map((file)=>{
                return (
                    <div key={file.election_cycle_id} className={'table-row  file-row tablet ' + (file.flagged?'flagged':'')}>
                        <div>
                            <span className={'title'}>File name</span>
                            <span >{file.flagged && <img src={'/images/new-alert.svg'} alt={'new alert'} /> }{file.election_cycle_title}</span>
                        </div>
                        <div>
                            <span className={'title'}>Upload Date</span>
                            <span >{new Date(file.election_cycle_timestamp.replace(' ','T')).toLocaleDateString()}</span>
                        </div>
                        <div>
                        <span><a className={linkClass}
                                 onClick={e=>{e.preventDefault();downloadFile(baseApiUrl+file.election_cycle_download_url+'?auth-token=')}}
                                 href={'#'}
                                 ><span style={{color: accentColor}} className={'mdi mdi-file'}/>
                        </a> </span>

                        </div>
                    </div>
                )
            })}
            {files.length<=0 && <NoResults /> }
        </div>
    )

}



import React, {useEffect, useState} from "react";
import {useLocation, Switch, Route, Redirect, useHistory} from "react-router-dom";
import {useStoreState,useStoreActions} from "easy-peasy";
import axios from 'axios';

import LoginScreen from "./screens/loginScreen";
import Signup from "./screens/signup";

import {
    ADMIN_TYPE,
    ADMIN_USER_TYPES,
    ApprovalNeededEnvironments,
    baseApiUrl,
    Breakpoints,
    EMPLOYEE_TYPE, EMPLOYER_CONTACT_TYPE, HR_TYPE
} from "./_constants";
import AuthRequiredRoutes from "./_authRequiredRoutes";
import AlertMessage from "./components/alertMessage";
import {CSSTransition} from "react-transition-group";
import {employerRoutes,alwaysAvailableRoutes,employeeRoutes} from "./_constants";
import Enroll from "./components/auth/enroll";
import EnrollScreen from "./screens/enrollScreen";
import NotApprovedPopup from "./components/popups/notApprovedPopup";
import {getEnv} from "./_helpers";

export default function AppRouter()
{
    const [layout,setLayout] = useState('desktop');
    let location = useLocation();
    location.state = {layout:layout};
	const history = useHistory();
    const authToken = useStoreState(state=>state.user.token);
    const isLoggedIn = useStoreState(state=>state.user.isLoggedIn);
    const [showMessage,setShowMessage] = useState(false);
    const setMessage = useStoreActions(actions=>actions.alertMessage.setMessage);
    const message = useStoreState(state=>state.alertMessage.message);
    const [messageTimer,setMessageTimer] = useState(null);
    const activeOrganization = useStoreState(state=>state.user.activeOrganization);
    const userType = useStoreState(state=>state.user.type);
    const setToken = useStoreActions(actions => actions.user.updateToken);
    const [showApp,setShowApp] = useState(false);
    const [docHasApprovedListener,setDocHasApprovedListener] = useState(false);
    const [showNotApprovedPopup,setShowNotApprovedPopup] = useState(false);
    const getCurrentLayout = () =>{
        let curLayout = 'desktop';
        const windowWidth = window.innerWidth;
        Breakpoints.forEach(breakpoint=>{
            if(windowWidth<=breakpoint.width){
                curLayout = breakpoint.name;
            }
        });
        setLayout(curLayout);
        location.state = {layout:curLayout};
    };
    useEffect(()=>{
        if(location.pathname.includes('invite') && !location.pathname.includes('test')){
            setToken();
        }
        getCurrentLayout();
        window.addEventListener('resize',getCurrentLayout);

        return ()=>{
            document.removeEventListener('click', blockNonApprovedActions);
            window.removeEventListener('resize',getCurrentLayout);
        }
    },[]);

    useEffect(()=>{
        if(!message){
            clearTimeout(messageTimer);
            setShowMessage(false)
        }
        clearTimeout(messageTimer);
        setShowMessage(true);
        setMessageTimer(setTimeout(()=>{setShowMessage(false); setMessage(null)},5000));
    },[message]);

    axios.defaults.baseURL = baseApiUrl;
    axios.defaults.timeout = 60 * 10 * 1000;
    axios.defaults.timeoutErrorMessage = 'Request took too long.';
    useEffect(()=>{
        const requestInterceptor = axios.interceptors.request.use(
            function (config) {
                if(!isLoggedIn || !authToken){
                    return config;
                }
                config.headers['Auth-token'] = authToken;

                return config;
            });
        const responseInterceptor = axios.interceptors.response.use(function(response){
            let message = (response.data && response.data.meta)?response.data.meta.message:null;
            if(message){
                setMessage({message: message});
            }
            return response;
        }, function(error){
            if(error.response && error.response.status === 401){
                setToken();
            }
            let message = (error.response && error.response.data && error.response.data.meta)?error.response.data.meta.message || error.message:error.message;
            if(message) {
                setMessage({message: message, type: 'error'});
            }
            return Promise.reject(error);
        });
        setShowApp(true);
        return ()=>{
            axios.interceptors.request.eject(requestInterceptor);
            axios.interceptors.request.eject(responseInterceptor);
        }
    },[authToken, isLoggedIn]);

    const blockNonApprovedActions = (e) => {
        if(ApprovalNeededEnvironments.includes(getEnv()) && !activeOrganization.application_approved) {
            if (e.target && (e.target.classList.contains('approved-only') || e.target.parentElement.classList.contains('approved-only'))) {
                e.preventDefault();
                e.stopImmediatePropagation();
                e.stopPropagation();
                setShowNotApprovedPopup(true);
            }
        }
    };

    useEffect(()=>{
    	if(!!activeOrganization){

            switch(userType){
                case HR_TYPE:
                    if(!employerRoutes.includes(location.pathname) && !alwaysAvailableRoutes.includes(location.pathname)) {
                        history.push('/employer/');
                    } else{
                        history.push(location.pathname);
                    }
                    break;
                case EMPLOYER_CONTACT_TYPE:
                    if(!employerRoutes.includes(location.pathname) && !alwaysAvailableRoutes.includes(location.pathname)) {
                        history.push('/employer/');
                    } else{
                        history.push(location.pathname);
                    }
                    break;
                case ADMIN_TYPE:
                    if(!employerRoutes.includes(location.pathname) && !alwaysAvailableRoutes.includes(location.pathname)) {
                        history.push('/employer/');
                    } else{
                        history.push(location.pathname);
                    }
                    break;
                case EMPLOYEE_TYPE:
                    if(!employeeRoutes.includes(location.pathname) && !alwaysAvailableRoutes.includes(location.pathname)) {
                        history.push('/employee/');
                    } else{
                        history.push(location.pathname);
                    }
                    break;
                default:
                    break;
            }
            if(!activeOrganization.application_approved) {
                if(!docHasApprovedListener && ApprovalNeededEnvironments.includes(getEnv())) {
                    document.addEventListener('click', blockNonApprovedActions);
                    setDocHasApprovedListener(true);
                }
            }else{
                document.removeEventListener('click', blockNonApprovedActions);
                setDocHasApprovedListener(false);
            }
	    }else{
            document.removeEventListener('click', blockNonApprovedActions);
            setDocHasApprovedListener(false);
        }
    	return ()=>{
            document.removeEventListener('click', blockNonApprovedActions);
            setDocHasApprovedListener(false);
        }
    },[activeOrganization,userType]);

    return (
        <>
            {showApp &&
                <>
                    {(!!message && showMessage) && <AlertMessage/> }
                    {(showNotApprovedPopup) && <NotApprovedPopup closeHandler={()=>setShowNotApprovedPopup(false)}/> }
            <Switch >
                <Route exact path='/'>
                    {(isLoggedIn && !!activeOrganization) ? <Redirect to={'/dashboard'}/> : <LoginScreen/>}
                </Route>
                <Route path={'/invite/:code'} >
                    <Signup layout={layout}/>
                </Route>
                <Route path={'/switch'}>
                    <LoginScreen/>
                </Route>
                <Route path={'/enroll'}>
                    <EnrollScreen/>
                </Route>
                <Route path='/'>
                    {(isLoggedIn && !!activeOrganization)  ? <AuthRequiredRoutes/> : <Redirect to={'/'}/>}
                </Route>
            </Switch>
                    </>
                }
        </>
    );
}

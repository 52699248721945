import React, {useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import Popup from "../popups/popup";

export default function PopupSubNav({menu,changeHandler,currentScreen}){

    const location = useLocation();
    const [showPopup,setShowPopup] = useState(false);
    const [size,setSize] = useState('small');
    const openPopup = (e) =>{
        if((size=='small' && location.state.layout==='mobileMax') || (size==='large' && !location.state.layout.includes('desktop','tabletMax'))) {
            e.preventDefault();
            setShowPopup(true)
        }
    };
    const closePopup = () =>{
        setShowPopup(false);
    };

    return (
        <>
            {showPopup &&
                    <Popup closeHandler={closePopup} simple={true} >
                        <div className={'mobile-subnav-holder popup-nav'}>
                        {
                            menu.map((item,index)=>(
                                    <span
                                        className={ 'tab-nav button ' + (currentScreen.indexOf(item.action)>=0?'active':'')}
                                        key={index}
                                        onClick={()=>{setShowPopup(false); changeHandler(item.action)}} >
                                        {item.icon && <span className={'mdi mdi-'+item.icon} />}{item.title}
                                    </span>
                                )
                            )
                        }
                        </div>
                    </Popup>
            }
                <div className={'sub-nav-holder desktop-nav popup-nav '+ size}>
                    {
                        menu.map((item,index)=>(
                            <span
                                style={{minWidth:'calc((100% / '+menu.length+') - 20px)'}}
                                className={ ' tab-nav button ' + (currentScreen.indexOf(item.action)>=0?'active':'')}
                                key={index}
                                onClick={(e)=>{openPopup(e); changeHandler(item.action)}} >
                                {item.icon && <span className={'mdi mdi-'+item.icon} />}{item.title}
                            </span>
                            )
                        )
                    }
            </div>
        </>
    )

}

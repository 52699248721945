import React from 'react';

export default function OwnerSignupSteps({curStep}){

	return (
		<div className={'full-width signup-steps'}>
			<div className={'max-width-container signup-steps desktop-up'}>
				<div className={'step '+ (curStep>1?'complete':curStep<2?'active':'')}>
					<span className={curStep>1?'complete':curStep<2?'active':''}>1</span>
					Welcome
				</div>
				<div className={'step ' + (curStep>3?'complete':[2,3].includes(curStep)?'active':'')}>
					<span className={curStep>3?'complete':[2,3].includes(curStep)?'active':''}>2</span>
					Terms and Conditions
				</div>
				<div className={'step ' + (curStep>6?'complete':[4,5,6].includes(curStep)?'active':'')}>
					<span className={curStep>6?'complete':[4,5,6].includes(curStep)?'active':''}>3</span>
					Forte Application
				</div>
				<div className={'step ' + (curStep>7?'complete':curStep===7?'active':'')}>
					<span className={curStep>7?'complete':curStep===7?'active':''}>4</span>
					Register Bank Account
				</div>
			</div>
			<div className={'max-width-container signup-steps tablet'}>
				{
					{
						0:<div className={'mobile-step'}><strong>1 / 4</strong>Welcome</div>,
						1:<div className={'mobile-step'}><strong>1 / 4</strong>Welcome</div>,
						2:<div className={'mobile-step'}><strong>2 / 4</strong>Terms and Conditions</div>,
						3:<div className={'mobile-step'}><strong>2 / 4</strong>Terms and Conditions</div>,
						4:<div className={'mobile-step'}><strong>3 / 4</strong>Forte Application</div>,
						5:<div className={'mobile-step'}><strong>3 / 4</strong>Forte Application</div>,
						6:<div className={'mobile-step'}><strong>3 / 4</strong>Forte Application</div>,
						7:<div className={'mobile-step'}><strong>4 / 4</strong>Register Bank Account</div>
					}[curStep]
				}

			</div>
		</div>
	)
}

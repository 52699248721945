import React, {useEffect, useState} from 'react';
import Popup from "./popup";
import NumberFormat from "react-number-format";
import axios from 'axios';
import {getHttpErrorMessage} from "../../_helpers";
import FileInput from "../fileInput";

export default function BankInfoPopup_529 ({sucessHandler,closeHandler,authToken, organization, user}){

	const axiosConfig = {headers:{'Auth-token': authToken}};
	const [accountNumber,setAccountNumber] = useState('');
	const [accountNumberConfirmation,setAccountNumberConfirmation] = useState('');
	const [statement,setStatement] = useState(null);
	const [routingNumber,setRoutingNumber] = useState('');
	const [ssn,setSsn] = useState('');
	const [isLoading,setIsLoading] = useState(false);
	const [errorFields,setErrorFields] = useState([]);
	const [message,setMessage] = useState('');
	const [bank,setBank] = useState('')
	const [accountName,setAccountName] = useState('');
	const [vendors,setVendors] = useState([]);
	const [selectedVendor,setSelectedVendor] = useState(null);
	const [updateToken,setUpdateToken] = useState(null);

	const updateFile = (type,value) =>{
		setStatement(value);
	};

	const updateSelectedVendor = (vendorId) =>{
		if(!vendorId){
			setSelectedVendor(null);
			return;
		}
		if(vendorId == -1){
			setSelectedVendor({account_vendor_id:-1});
			return;
		}
		setBank(vendors.find(vendor=>vendor.account_vendor_id === vendorId)['account_vendor_title']);
		setSelectedVendor(vendors.find(vendor=>vendor.account_vendor_id === vendorId));
	};

	const checkSsnRequirements = errors =>{
		if(ssn.trim().length !== 9){
			errors.push('ssn');
			setMessage('Full SSN required.');
		}
	};

	const checkAccountRequirements = errors =>{
		if(accountNumberConfirmation !== accountNumber){
			errors.push('accountNumberConfirmation');
			setMessage('Account Numbers Must Match.');
		}
		if(accountNumberConfirmation.trim().length<=0){
			errors.push('accountNumberConfirmation');
			setMessage('Confirm Account Number.');
		}
		if(accountNumber.trim().length<=0){
			errors.push('accountNumber');
			setMessage('Account Number Required.');
		}
	};

	const checkAsIsRequirements = errors =>{
		if(accountNumberConfirmation !== accountNumber){
			errors.push('accountNumberConfirmation');
			setMessage('Account Numbers Must Match.');
		}
		if(accountNumberConfirmation.trim().length<=0){
			errors.push('accountNumberConfirmation');
			setMessage('Confirm Account Number.');
		}
		if(accountNumber.trim().length<=0){
			errors.push('accountNumber');
			setMessage('Account Number Required.');
		}
		if(routingNumber.trim().length !== 9){
			errors.push('routingNumber');
			setMessage('Routing Number Required.');
		}
	};

	const checkOtherRequirements = errors =>{
		if(ssn.trim().length !== 9){
			errors.push('ssn');
			setMessage('Full SSN required.');
		}
		if(bank.trim().length<=0){
			errors.push('bank');
			setMessage('Bank Name required.');
		}
		if(accountNumberConfirmation !== accountNumber){
			errors.push('accountNumberConfirmation');
			setMessage('Account Numbers Must Match.');
		}
		if(accountNumberConfirmation.trim().length<=0){
			errors.push('accountNumberConfirmation');
			setMessage('Confirm Account Number.');
		}
		if(accountNumber.trim().length<=0){
			errors.push('accountNumber');
			setMessage('Account Number Required.');
		}
	};


	const hasErrors = () =>{
		let errors = [];
		if(!selectedVendor){
			errors.push('selectedVendor');
			setMessage('Must select 529 account vendor.');
			return true;
		}
		if(accountName.trim().length<=0){
			errors.push('accountName');
			setMessage('Account Name required.');
		}
		if(!selectedVendor || selectedVendor.account_vendor_id == -1){
			checkOtherRequirements(errors);
		}else if(selectedVendor.account_vendor_requirement === 'as-is'){
			checkAsIsRequirements(errors);
		}else if(selectedVendor.account_vendor_requirement === 'account-number'){
			checkAccountRequirements(errors);
		}else if(selectedVendor.account_vendor_requirement === 'ssn'){
			checkSsnRequirements(errors);
		}

		if(selectedVendor && selectedVendor.account_vendor_is_confirmed !== 'yes' && !statement)
		{
			errors.push('recentStatement');
			setMessage('Account Statement Required.')
		}

		setErrorFields(errors);
		return errors.length>0;
	};

	const saveHandler = () =>{
		if(hasErrors()){
			return;
		}
		let vendorId = (!selectedVendor || selectedVendor.account_vendor_id == -1)?null:selectedVendor.account_vendor_id;
		let postData = {
			account: {
				account_vendor_id:vendorId,
				unassigned_vendor_account_vendor_name:bank,
				account_name:accountName,
				account_routing_number: routingNumber,
				unassigned_vendor_account_account_number: accountNumber,
				unassigned_vendor_account_ssn:ssn,
				account_type_token:'529',
				account_statement:statement
			}
		};
		let config = {};
		if(authToken){
			config.headers = {'Auth-token': authToken}
		}
		axios.post('/organizations/'+organization['organization_id']+'/users/'+user['user_id']+'/add-account',postData, config).then(response=>{
			sucessHandler(response);
		}).catch(e=>{
			setMessage(getHttpErrorMessage(e));
		});
	};

	useEffect(()=>{
		let cancelToken = axios.CancelToken.source();
		let config = {};
		if(authToken){
			config.headers = {'Auth-token': authToken}
		}
		axios.get('/form-options/vendors',config).then(response=>{
			setVendors(response.data.vendors);
			setUpdateToken(Math.random());
		}).catch(e=>{});

		return ()=>{
			cancelToken.cancel('');
		}
	},[]);

	return (
		<Popup closeHandler={closeHandler}>
			<div className={'large-form popup-content form-slide'}>
				<div className={'title'}>
					<h2><img className={'thrive-logo'} src={'/images/thrive-contribution.svg'} alt={'thrive'} />Add Your 529 Account</h2>
				</div>
				<div className={'form-container no-border'}>

					<div className={'form-inner field-list'}>

						<label>
							Financial Institution:
							<select className={selectedVendor?'filled ':' '} value={selectedVendor?selectedVendor.account_vendor_id:''} onChange={(e)=>{updateSelectedVendor(e.target.value)}}>
								<option value={''}>Select a Financial Institution</option>
								{vendors.map(vendor=>
									<option  style={{color:'#000'}} key={vendor.account_vendor_id} value={vendor.account_vendor_id}>{vendor.account_vendor_title}</option>
								)}
							</select>
						</label>
						<label>
							Account Name:
							<input className={(errorFields.includes('accountName')?'invalid':'')} value={accountName} onChange={(e)=>{setAccountName(e.target.value)}}/>
						</label>
						{(selectedVendor && selectedVendor.account_vendor_instructions) &&
						<label className={'full-row bank-instructions'} dangerouslySetInnerHTML={{__html: selectedVendor.account_vendor_instructions}} />
						}
						{(selectedVendor && selectedVendor.account_vendor_is_confirmed === 'yes' &&  selectedVendor.account_vendor_requirement === 'as-is') &&
							<>
								<label className={'full-row'}>
									Routing Number:
									<NumberFormat
										className={(errorFields.includes('routingNumber') ? 'invalid' : '')}
										placeholder={'#########'}
										format={'#########'}
										mask={'_'}
										decimalScale={0}
										thousandSeparator={false}
										value={routingNumber}
										onValueChange={(values) => {
											const {value} = values;
											setRoutingNumber(value);

										}}/>
								</label>

								< label className={'full-row'}>
									Account Number:
									<input className={(errorFields.includes('accountNumber')?'invalid':'')} value={accountNumber} onChange={(e)=>{setAccountNumber(e.target.value)}}/>

									</label>

									<label className={'full-row'}>
									Confirm Account Number:
										<input className={(errorFields.includes('accountNumberConfirmation')?'invalid':'')} value={accountNumberConfirmation} onChange={(e)=>{setAccountNumberConfirmation(e.target.value)}}/>
									</label>
							</>
						}
						{(selectedVendor && selectedVendor.account_vendor_is_confirmed === 'yes' && selectedVendor.account_vendor_requirement === 'account-number') &&
							<>
								< label className={'full-row'}>
									Account Number:
									<input className={(errorFields.includes('accountNumber')?'invalid':'')} value={accountNumber} onChange={(e)=>{setAccountNumber(e.target.value)}}/>

								</label>

								<label className={'full-row'}>
									Confirm Account Number:
									<input className={(errorFields.includes('accountNumberConfirmation')?'invalid':'')} value={accountNumberConfirmation} onChange={(e)=>{setAccountNumberConfirmation(e.target.value)}}/>
								</label>
								<label style={{height:'72px'}} className={'full-row'} />
							</>
						}
						{(selectedVendor && selectedVendor.account_vendor_requirement === 'ssn') &&
						<>
							< label className={'full-row'}>
								Account Beneficiary's SSN:
								<NumberFormat
									className={(errorFields.includes('ssn') ? 'invalid' : '')}
									placeholder={'###-##-####'}
									format={'###-##-####'}
									mask={'_'}
									decimalScale={0}
									thousandSeparator={false}
									value={ssn}
									onValueChange={(values) => {
										const {value} = values;
										setSsn(value);
									}} />
							</label>
							<label style={{height:'72px'}} className={'full-row'} />
							<label style={{height:'72px'}} className={'full-row'} />

						</>
						}
						{(!selectedVendor || selectedVendor.account_vendor_id == -1 || selectedVendor.account_vendor_is_confirmed === 'no') &&
						<>

							< label className={'full-row'}>
								Account Beneficiary's SSN:
								<NumberFormat
									className={(errorFields.includes('ssn') ? 'invalid' : '')}
									placeholder={'###-##-####'}
									format={'###-##-####'}
									mask={'_'}
									decimalScale={0}
									thousandSeparator={false}
									value={ssn}
									onValueChange={(values) => {
										const {value} = values;
										setSsn(value);
									}} />
							</label>
							< label className={'full-row'}>
								Account Number:
								<input className={(errorFields.includes('accountNumber')?'invalid':'')} value={accountNumber} onChange={(e)=>{setAccountNumber(e.target.value)}}/>

							</label>

							<label className={'full-row'}>
								Confirm Account Number:
								<input className={(errorFields.includes('accountNumberConfirmation')?'invalid':'')} value={accountNumberConfirmation} onChange={(e)=>{setAccountNumberConfirmation(e.target.value)}}/>
							</label>
						</>
						}
						<FileInput label={'Recent Statement:'}
								   descriptionsTitle={'Why we need this'}
								   axiosConfig={axiosConfig}
								   optional={selectedVendor && selectedVendor.account_vendor_is_confirmed === 'yes'}
								   changeHandler={updateFile}
								   descriptions={[{document_description_id:1,document_description_description: 'Having a copy of your most recent statement can help ensure your account is linked correctly.'}]}
								   fileKey={'recentStatement'}
								   fullRow={true}
								   uploadUrl={'/organizations/'+organization['organization_id']+'/users/'+user['user_id']+'/upload-statement'}
								   error={Array.isArray(errorFields) && errorFields.includes('recentStatement')}

						/>
						<div className={'save-row full-row'}>
							<button  className={'simple-rounded-button blue'} onClick={(e)=>{saveHandler()}}><span>Add Account</span></button>
							{message && <p className={'popup-form-error'}>{message}</p>}
						</div>

					</div>

				</div>

			</div>
		</Popup>
	)
}

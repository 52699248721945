import React from 'react';
import HeaderFileBox from "./headerFileBox";
import {CSSTransition} from "react-transition-group";

export default function EmployerDashboardHeader({header}){

    return (
        <>
            {!!header && (

                <div style={{backgroundColor:header.color}} className={'dashboard-header full-width'}>
                    <div className={'dashboard-header-holder max-width-container'}>
                        <div className={'dashboard-header-content'}>
                            <h1>{header.title}</h1>
                            <p>{header.subTitle}</p>
                            <a href={'#dashboard-details'} className={'down-anchor'}/>
                        </div>
                        <HeaderFileBox header={header}/>
                    </div>
                </div>
            )
            }
        </>
    )
}

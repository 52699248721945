import React from 'react';
import {Colors} from "../../_constants";
import {useLocation} from "react-router";
import NoResults from "../noResults";

export default function EmployeeActivityList({activities,viewHandler,accentColor}){

	const location = useLocation();
	return (
		<div className={'employee-list dashboard-list'}>
			{location.state.layout!=='mobileMax' &&
				<div className={'table-row file-row header'}>
					<span>Activity</span>
					<span>Date</span>
					<span>Status</span>
					<span>Details</span>
				</div>
			}
			{location.state.layout!=='mobileMax' && activities.map((activity,index)=>{
				if(!activity.activity_message){
					return null;
				}
				return (
					<div key={index+activity.activity_timestamp} className={'table-row file-row'}>
						<span>{activity.activity_type_title}</span>
						<span>{activity.activity_timestamp?new Date(activity.activity_timestamp.replace(' ','T')).toLocaleDateString():''}</span>
						<span>{activity.activity_status==='error'?<i>{activity.activity_status}</i> : activity.activity_status}</span>
						<span><button onClick={()=>{viewHandler(activity)}} className={'employee'} style={{color:accentColor}}>View More</button></span>
					</div>
				)
			})}
			{(location.state.layout==='mobileMax')  && activities.map((activity,index)=>{
				if(!activity.activity_message){
					return null;
				}
				return (
					<div key={index+activity.activity_timestamp} className={'table-row file-row tablet'}>
						<div>
							<span className={'title'}>Activity</span>
							<span>{activity.activity_type_title}</span>
						</div>
						<div>
							<span className={'title'}>Date</span>
							<span>{activity.activity_timestamp?new Date(activity.activity_timestamp.replace(' ','T')).toLocaleDateString():''}</span>
						</div>
						<div>
							<span className={'title'}>Status</span>
							<span>{activity.activity_status==='error'?<i>{activity.activity_status}</i> : activity.activity_status}</span>
						</div>
						<div>
							<span><button onClick={()=>{viewHandler(activity)}} className={'employee'} style={{color:accentColor}}>View More</button></span>
						</div>
					</div>
				)
			})}
			{activities.length<=0 && <NoResults />}
		</div>

	)
}

import React, {useCallback, useEffect, useState} from 'react';
import axios from "axios";
import evoshare from "../../../screens/employee/dashboard/evoshare";

export default function WelcomeSlide2({user,nextHandler,organization,authToken,evoshareEligible, redirectHandler}){

	const [allOffersDenied,setAllOffersDenied] = useState(false);

	const [accountTypesString,setAccountTypesString] = useState('');
	const buildAccountTypesString = (accountTypes) =>{
		let types = accountTypes.filter(a=>a);
		let newString = '';
		let lastType = '';
		if(types.length>0){
			lastType = types.pop().account_type_title;
		}
		if(types.length>1) {
			types.forEach((type) => {
				newString += type.account_type_title + ', ';
			});
		}else if(types.length===1){
			newString += types[0].account_type_title;
		}
		if(types.length>0){
			newString += ' and/or '
		}
		newString += lastType;
		setAccountTypesString(newString);
	};

	const noClicked = () =>{
		setAllOffersDenied(true);
	};

	useEffect(()=>{
		if(!organization){
			return;
		}
		if(organization.organization_account_types.length<=0){
			axios.get('/organizations/'+organization.organization_id,{headers: {'Auth-token': authToken}}).then(response=> {
				buildAccountTypesString(response.data.organization.organization_account_types);
			}).catch(e=>{});
		}else{
			buildAccountTypesString(organization.organization_account_types);
		}

	},[organization]);


	return (
		<>
			{!allOffersDenied &&
			<div className={'slide welcome-slide'}>
				<div>
					<img className={'thrive-logo'} src={'/images/logo-no-text.svg'} alt={'thrive'}/>
					<span>Welcome <strong>{user && user.user_display_name}</strong></span>
					{(organization && organization.plan_token !== 'payroll-deduction' && accountTypesString) &&
						<p>Would you like to use your Employer benefit dollars to automatically fund your {accountTypesString}?</p>
					}
					{(organization && organization.plan_token === 'payroll-deduction' && accountTypesString) &&
						<p>Would you like to have a payroll deduction to your {accountTypesString}?</p>
					}
					{organization.organization_contribution_setting_match_description &&
						<span className={'details'}
						      dangerouslySetInnerHTML={{__html: organization.organization_contribution_setting_match_description.replace(/\$?[0-9\.]+%?/g, '<strong>$&</strong>')}}/>
					}
				</div>

				<div className={'buttons' }>
					<button onClick={() => {
						nextHandler()
					}} className={'yes '+ (evoshareEligible?'large':'')}><span>Yes</span></button>
					{!evoshareEligible && <button className={'no'} onClick={noClicked}><span>No</span></button>}
				</div>
				{ evoshareEligible &&
					<div onClick={()=>{redirectHandler('evoshare')}} className={'evoshare-no-button clickable'}>
						<span>No, take me to the Cash Back Program</span>
					</div>
				}
			</div>
			}
			{allOffersDenied &&
				<div className={'slide welcome-slide'}>
					<div>
						<img className={'thrive-logo'} src={'/images/logo-no-text.svg'} alt={'thrive'} />
						<p>
							Sorry
							to see you go! If you’d like to Thrive in the future just go to <a className={'standard-link purple'} href={'https://www.Thrivematching.com'}>www.Thrivematching.com</a> and click “Register” to begin.
						</p>
					</div>

				</div>
			}
		</>
	)

}

import React, {useState,useEffect} from 'react';
import {useLocation} from "react-router";
import Popup from "./popups/popup";
import { CSSTransition } from 'react-transition-group';

export default function ProviderSelector({options,changeHandler,inputDisabled, isDisplayOnly,clickable}){

    const location = useLocation();
    const [showOptions,setShowOptions] = useState(false);
    const [selectedOption,setSelectedOption] = useState(null);
    const [allOptions,setAllOptions] = useState([]);
    const [updated,setUpdated] = useState('');
    const clickHandler = (e) =>{
        if(inputDisabled){
            return;
        }
        if(e.target.hasAttribute('data-index')){
            let selected = allOptions[parseInt(e.target.getAttribute('data-index'))];
            setSelectedOption(selected);
            if(changeHandler) {
                changeHandler(selected.value);
            }
            setShowOptions(false);
            setUpdated(Math.random());
        }else{
            setShowOptions(!showOptions);
        }
    };
    const closeOptions = () =>{
        setShowOptions(false)
    }

    useEffect(()=>{
        let selected = null;
        if(options.length<1){
            return;
        }
        setAllOptions(options.map(option=>{
            if(!option.hasOwnProperty('selected')) {
                option.selected = false;
            }else if(option.selected){
                selected = option;
            }
            return option;
        }));
        if(!selectedOption) {
            if (!selected) {
                selected = options[0];
            }
            setSelectedOption(selected);
        }
        setUpdated(Math.random());
    },[options]);

    return (
        <>

            {!!selectedOption &&

                    <div className={'single-selector ' + (inputDisabled?'disabled ':' ') + (isDisplayOnly?'display-only ':' ') + (clickable?'clickable':'')} onClick={(e)=>clickHandler(e)}>
                        <span className={'single '+(selectedOption.logo?'with-logo':'')} >{selectedOption.logo &&<img src={selectedOption.logo} alt={''} /> } {selectedOption.label}</span>
                        <CSSTransition in={showOptions &&  location.state.layout !== 'mobileMax'}
                                       timeout={100}
                                       classNames="options-slide-down"
                                       unmountOnExit >
                            <div key={'optionlist'} className='option-list single providers'>
                                {allOptions.length>0 && allOptions.map((option, index) => {
                                    return (
                                        <span key={option.label+option.value}
                                              data-index={index}
                                              className={(selectedOption.logo?'with-logo ':'')+(option.value === selectedOption.value? 'selected ' : '')}
                                        >
                                            {selectedOption.logo && <img src={option.logo} alt={''} /> }
                                            {option.label}
                                        </span>
                                    )
                                })}
                            </div>
                        </CSSTransition>
                        {(showOptions && location.state.layout === 'mobileMax') &&
                            <Popup closeHandler={closeOptions} simple={true}>
                                <div className='option-list single providers'>
                                    {allOptions.length > 0 && allOptions.map((option, index) => {
                                        return (
                                            <span key={option.label + option.value}
                                                  data-index={index}
                                                  className={(selectedOption.logo?'with-logo ':'')+(option.value === selectedOption.value ? 'selected ' : '')}
                                            >
                                               {selectedOption.logo && <img src={option.logo} alt={''} /> }
                                               {option.label}
                                            </span>
                                        )
                                    })}
                                </div>
                            </Popup>
                        }
                    </div>
            }
            {showOptions && <div onClick={(e)=>{clickHandler(e)}} className='click-catcher'/>}
        </>
    )
}

import React from 'react';

export default function AuthHeader(){

	return (
		<header className={'full-width simple signup-header'}>
			<div className={'max-width-container header-holder'}>
				<img className={'header-logo'} src={'/images/logo.svg'} />

			</div>
		</header>
	)
}


import React, {useEffect, useState} from 'react';
import Popup from "./popup";
import {Colors} from "../../_constants";
import {formatDollarInputValue} from "../../_helpers";
import NumberFormat from "react-number-format";

export default function PayoffCalculatorPopup({closeHandler}){

	const [principal,setPrincipal] = useState('');
	const [interest,setInterest] = useState('');
	const [normalPayment,setNormalPayment] = useState('');
	const [thrivePayment,setThrivePayment] = useState('');
	const [normalPayoffTime,setNormalPayoffTime] = useState('');
	const [thrivePayoffTime,setThrivePayoffTime] = useState('');
	const showLogo = false;

	const formatPayoffTimeString = (numberOfPayments)=>{
		let years = Math.floor(numberOfPayments/12);
		let months = Math.ceil(numberOfPayments % 12);
		years = years>0?(years===1?years+' Year':years+' Years'):'';
		months = months>0?(months===1?months+' Month':months+' Months'):'';
		return years + ' ' + months;
	}
	const calculatePayoffTime =(paymentAmount)=>{
		let strippedPrincipal = parseFloat(principal.replace(/\D/g,''));
		paymentAmount = parseFloat(paymentAmount);
		let interestDecimal = parseFloat(interest)/100;

		const amortizedInterest = (
			Math.pow(
				(1+((interestDecimal)/Math.pow(Math.E,interest*(1/12))))
				,(Math.pow(Math.E,interest*(1/12))/12)
			)
			-1);

		if(paymentAmount<=amortizedInterest*strippedPrincipal){
			return 'Payment amount too low';
		}
		let numberOfPayments = subtractPayment(0,strippedPrincipal,amortizedInterest,paymentAmount);

		return formatPayoffTimeString(numberOfPayments);
	};
	const subtractPayment = (numberOfPayments,remainingLoanAmount,interest, paymentAmount)=>{
			remainingLoanAmount += interest*remainingLoanAmount;
			remainingLoanAmount -= paymentAmount;
			numberOfPayments++;
			if(remainingLoanAmount>0){
				return subtractPayment(numberOfPayments,remainingLoanAmount,interest,paymentAmount);
			}
			return numberOfPayments;
	};

	useEffect(()=>{


		if(parseFloat(principal)>0 && parseFloat(interest)>0 && parseFloat(normalPayment)>0){
			setNormalPayoffTime(calculatePayoffTime(normalPayment));
		}
		if(parseFloat(principal)>0 && parseFloat(interest)>0 && parseFloat(thrivePayment)>0){
			setThrivePayoffTime(calculatePayoffTime(thrivePayment));
		}
	},[principal,interest,thrivePayment,normalPayment]);
	return (
		<Popup closeHandler={closeHandler}>
			<div className={'popup-content payoff-calculator'}>

				<h2 style={{color:Colors.blue}} className={'title'}>{showLogo && <img src={'/images/thrive-contribution.svg'} alt={''} />}<br />Loan Payoff Calculator</h2>
				<div className={'payoff-loan-row'}>
					<div className={'title'}>
						<img src={'/images/student-cap.svg'} alt={''} />
						<span>Student Loan</span>
					</div>
					<div>
						<div className={'contribution-columns'}>
							<label>
								Principal
								<div className={'input-holder dollar flipped'}>
									<div className={'input'}>
										<span>$</span>
										<NumberFormat value={principal}
										              thousandSeparator={true}
										              decimalScale={0}
										              onValueChange={(values) => {
											              const {formattedValue, value} = values;
											              setPrincipal(value);
										              }}
										/>
									</div>
								</div>
							</label>
							<label>
								Interest Rate
								<div className={'input-holder dollar'}>
									<div className={'input'}>
										<NumberFormat value={interest}
										              thousandSeparator={true}
										              decimalScale={2}
										              maxLength={3}
										              onValueChange={(values) => {
											              const {formattedValue, value} = values;
											              setInterest(value);
										              }}
										/>
										<span>%</span>
									</div>
								</div>
							</label>
						</div>
					</div>
				</div>

				<div className={'payoff-payments-holder'}>
					<div className={'without-thrive'}>
						<div className={'payment-row'}>
							<div className={'title'}>
								<h2>Monthly Payment Without Thrive</h2>
								<span>Payment Per Month</span>
							</div>
							<label>
								<div className={'input-holder dollar flipped'}>
									<div className={'input'}>
										<span>$</span>
										<NumberFormat value={normalPayment}
										              thousandSeparator={true}
										              decimalScale={0}
										              onValueChange={(values) => {
											              const {formattedValue, value} = values;
											              setNormalPayment(value);
										              }}
										/>
									</div>
								</div>
							</label>
						</div>
						<div className={'payoff-time'}>
							<img src={'/images/calendar-blue.svg'} alt={''} />
							<h1>{normalPayoffTime || 'Enter payment amount'}</h1>
							<span>Time to Pay Off Loan</span>

						</div>
					</div>
					<div className={'with-thrive'}>
						<div className={'payment-row'}>
							<div className={'title'}>
								<h2>Monthly Payment With Thrive</h2>
								<span>Payment Per Month</span>
							</div>
							<label>
								<div className={'input-holder dollar flipped'}>
									<div className={'input'}>
										<span>$</span>
										<NumberFormat value={thrivePayment}
										              thousandSeparator={true}
										              decimalScale={0}
										              onValueChange={(values) => {
											              const {formattedValue, value} = values;
											              setThrivePayment(value);
										              }}
										/>
									</div>
								</div>
							</label>

						</div>
						<div className={'payoff-time'}>
							<img src={'/images/calendar-green.svg'} alt={''} />
							<h1>{thrivePayoffTime || 'Enter payment amount'}</h1>
							<span>Time to Pay Off Loan</span>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	)
}

import React, {useState,useEffect} from 'react';
import Popup from "../../components/popups/popup";
import "react-datepicker/dist/react-datepicker.css";
import {useLocation} from "react-router";
import SingleSelector from "../../components/singleSelector";
import {Colors, organizationAnnouncementsUrl, organizationUsersUrl} from "../../_constants";
import MultiAutoComplete from "../../components/multiAutoComplete";
import {CSSTransition} from "react-transition-group";
import axios from "axios";
import {useStoreActions, useStoreState} from "easy-peasy";
export default function MessagePopup({closeHandler,message, users,roles}){
    const location = useLocation();
    const [curMessage,setCurMessage] = useState(message?JSON.parse(JSON.stringify(message)):{
        announcement_subject:'',
        category:'',
        announcement_audience:'all',
        recipient_user_ids:[],
        announcement_message:''
    });
    const [selectedCategory,setSelectedCategory] = useState(null);
    const [sendToAll,setSendToAll] = useState(curMessage? curMessage.announcement_audience==='all':true);
    const [messageUpdated,setMessageUpdated] = useState('');
    const [filteredUsers,setFilteredUsers] = useState(users);
    const [recipientIds,setRecipientIds] = useState([]);
    const [categories,setCategories] = useState([{label:'All',value:null,selected:message?!message.role_id:true} ,...roles.map(role=>{return {label:role.role_title,value:role.role_id,selected:message?message.role_id===role.role_id:false}})]);
    const activeOrganization = useStoreState(state=>state.user.activeOrganization);
    const setAlert = useStoreActions(actions=>actions.alertMessage.setMessage);
    const recipients = [
        {label:'All',value:'all',selected:message? message.announcement_audience==='all':true},
        {label:'Selected',value:'selected',selected:message? message.announcement_audience==='selected':false}
    ];

    const updateMessage = (key,value) =>{
        curMessage[key] = value;
        setCurMessage(curMessage);
        setMessageUpdated(Math.random());
    };

    const updateCategory = (category) =>{
        updateMessage('role_id',category?category.role_id: null);
        setSelectedCategory(category);
    };
    const updateRecipientType = (value) =>{
        updateMessage('announcement_audience',value);
        setSendToAll(value==='all');
    };

    const updateRecipientIds = (users) =>{
        setRecipientIds(users.map(user=>user.user_id));
    };

    const sendMessage = (e) =>{
        e.preventDefault();
        if(!sendToAll){
            curMessage.recipient_user_ids = recipientIds;
        }else{
            curMessage.recipient_user_ids = null ;
        }
        curMessage.role_id = selectedCategory;
        let idString = curMessage.announcement_id?'/'+curMessage.announcement_id:'';
        axios.post(organizationAnnouncementsUrl(activeOrganization['organization_id'])+idString,{announcement:curMessage}).then(response=>{
            if(!!response.data.announcement){
                axios.post(organizationAnnouncementsUrl(activeOrganization['organization_id'])+'/send/'+response.data.announcement.announcement_id).then(res=>{
                    if(!!response.data.announcement){
                        closeHandler(res.data.announcement);
                    }
                    setAlert({message:res.data.meta.message})
                }).catch(e=>{});
                updateMessage('announcement_timestamp_sent',new Date());
            }
            setAlert({message:response.data.meta.message})
        }).catch(e=>{})
    };
    const saveMessage = (e) =>{
        e.preventDefault();
        if(!sendToAll){
            curMessage.recipient_user_ids = recipientIds;
        }else{
            curMessage.recipient_user_ids = null ;
        }
        curMessage.role_id = selectedCategory;
        let idString = curMessage.announcement_id?'/'+curMessage.announcement_id:'';
        axios.post(organizationAnnouncementsUrl(activeOrganization['organization_id'])+idString,{announcement:curMessage}).then(response=>{
            if(!!response.data.announcement){
                closeHandler(response.data.announcement);
            }
        setAlert({message:response.data.meta.message})
        }).catch(e=>{})
    };
    const filterUsers = () =>{
        if(!selectedCategory){
            setFilteredUsers(users);
            return;
        }

        setFilteredUsers(users.filter(user=>{
            return user.roles.filter(role=>role.role_id===selectedCategory).length>0
        }))
    };
    useEffect(()=>{
        filterUsers();
    },[selectedCategory])

    return(
        <Popup closeHandler={closeHandler} tabbed={false}>
            <div className={'employee-settings popup-content'}>
                <div  className={'popup-form message'}>
                    <label>
                        Category
                        <SingleSelector inputDisabled={!!curMessage.announcement_timestamp_sent} options={categories} changeHandler={updateCategory}/>
                    </label>

                    <label>
                        Recipients
                        <SingleSelector inputDisabled={!!curMessage.announcement_timestamp_sent} options={recipients} changeHandler={updateRecipientType}/>
                    </label>
                    <CSSTransition
                        in={!sendToAll}
                        timeout={{
                            appear: 500,
                            enter: 500,
                            exit: 100,
                        }}
                        classNames="slide-down"
                        unmountOnExit
                    >
                        <label className={'full-row'}>
                            {!!curMessage.announcement_timestamp_sent?'Recipients':'Contacts'}
                            <MultiAutoComplete
                                updateHandler={updateRecipientIds}
                                options={filteredUsers}
                                preselectedOptions={message?message.recipients.map(user=>user.user_id):[]}
                                labelSelector={'user_display_name'}
                                valueSelector={'user_email'}
                                category={selectedCategory}
                                disabled={!!curMessage.announcement_timestamp_sent}
                            />
                        </label>
                    </CSSTransition>
                    <label className={'full-row'}>
                        Message Subject
                        <input
                            disabled={!!curMessage.announcement_timestamp_sent}
                            value={curMessage.announcement_subject}
                            onChange={(e) => {
                                updateMessage('announcement_subject', e.target.value)
                            }}/>
                    </label>

                    <label className={'full-row'}>
                        Message Contents
                        <textarea disabled={!!curMessage.announcement_timestamp_sent} onChange={(e) => {updateMessage('announcement_message', e.target.value)}} value={curMessage?curMessage.announcement_message.replace(/<br\s*[\/]?>/gi, ""):''} />
                    </label>

                    {!curMessage.announcement_timestamp_sent &&
                        <div className={'full-row message-buttons'}>
                            <button
                                    className={'simple-rounded-button hollow blue small'} onClick={(e) => {
                                saveMessage(e)
                            }}><span>Save As Draft</span></button>
                            <button className={'simple-rounded-button small purple'} onClick={(e) => {
                                sendMessage(e)
                            }}><span>Post Message</span></button>
                        </div>
                    }
                </div>
            </div>
        </Popup>
    )
}

import React, {useEffect, useState} from 'react';
import Popup from "./popup";

export default function UploadErrorPopup({closeHandler, errors, showIgnoreErrorOption,submitHandler}){


    return (
        <Popup closeHandler={closeHandler} bodyClass={'evoshare-result'} >
            <div className={'popup-content evoshare-result-popup document-details'} >
                <div>
                    <h2>Upload Errors</h2>
                    <ul>
                        {errors.map((error,index)=>
                            <li key={index}>{error}</li>
                        )}
                    </ul>
                    {showIgnoreErrorOption &&
                    <div className={'resubmit'}>
                        <button className={'simple-rounded-button'} onClick={() => {
                            submitHandler()
                        }}><span>Accept Errors and Re-submit</span>
                        </button>
                        <p>*By accepting the errors and resubmitting the Payroll File, all employees left off Payroll File will be Deactivated and their Elections will be terminated</p>
                    </div>
                    }
                </div>
            </div>
        </Popup>
    )
}

import React from 'react';

export default function AdminSignupSteps({curStep}){

	return (
		<div className={'full-width signup-steps'}>
			<div className={'max-width-container signup-steps tablet-up'}>
				<div className={'step ' + (curStep>0?'complete':curStep===0?'active':'')}>
					<span className={curStep>0?'complete':curStep===0?'active':''}>1</span>
					Welcome
				</div>
				<div className={'step ' + (curStep>1?'complete':curStep===1?'active':'')}>
					<span className={curStep>1?'complete':curStep===1?'active':''}>2</span>
					Confirm Account
				</div>
			</div>
			<div className={'max-width-container signup-steps mobile'}>
				{
					{
						0:<div className={'mobile-step'}><strong>1 / 2</strong>Welcome</div>,
						1:<div className={'mobile-step'}><strong>2 / 2</strong>Confirm Account</div>,
					}[curStep]
				}

			</div>
		</div>
	)
}

import React, {useState,useEffect} from 'react';
import {useLocation} from "react-router";
import Popup from "./popups/popup";
import {CSSTransition} from "react-transition-group";


export default function FilterButton({options,changeHandler, label='Filter',fullWidth=false,squared=false}){
    const location = useLocation();
    const [allOptions,setAllOptions] = useState([]);
    const [showOptions,setShowOptions] = useState(false);

    useEffect(()=>{
        setAllOptions(options.map(option=>{
            if(!option.hasOwnProperty('selected')) {
                option.selected = false;
            }
            return option;
        }));
    },[options]);
    const closeOptions = () =>{
        setShowOptions(false)
    };
    const clickHandler = (e) =>{
        if(e.target.hasAttribute('data-index')){
            let newSelection = e.target.getAttribute('data-index');
            let options = allOptions.map((option,index)=>{
                if(index.toString() === newSelection){
                    option.selected = !option.selected;
                }
                return option;
            });
            setAllOptions(options);
            changeHandler(options.filter(option=>option.selected));
        }else{
            setShowOptions(!showOptions);
        }
    };

    return (
        <>

            {showOptions && <div onClick={(e)=>{clickHandler(e)}} className='click-catcher'/>}
            <div className={'filter-button ' + (showOptions?'active ':'') + (fullWidth?'full ':'')+ (squared?'squared ':'')} onClick={(e)=>{clickHandler(e)}}>
                <span>{label}</span>

                <CSSTransition in={showOptions &&  location.state.layout !== 'mobileMax'}
                               timeout={100}
                               classNames="options-slide-down"
                               unmountOnExit >
                    <div className='option-list'>
                        {allOptions.length>0 && allOptions.map((option, index) => {
                            return (
                                <span key={option.label+option.value}
                                      data-index={index}
                                      className={option.selected? 'selected' : ''}
                                >
                                    {option.label}
                                </span>
                            )
                        })}
                    </div>
                </CSSTransition>
                {(showOptions && location.state.layout === 'mobileMax') &&
                <Popup closeHandler={closeOptions} simple={true}>
                    <div className='option-list'>
                        {allOptions.length>0 && allOptions.map((option, index) => {
                            return (
                                <span key={option.label+option.value}
                                      data-index={index}
                                      className={option.selected? 'selected' : ''}
                                >
                                {option.label}
                            </span>
                            )
                        })}
                    </div>
                </Popup>
                }
            </div>
        </>
    )
}

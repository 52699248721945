import React, {useEffect, useState} from 'react';
import FilterButton from "../../components/filterButton";
import MessagePopup from "./messagePopup";
import MessageList from "../../components/lists/messageList";
import {CSSTransition} from "react-transition-group";
import MessageListPlaceholder from "../../components/placeholders/messageListPlaceholder";
import axios from "axios";
import {
    organizationAnnouncementsUrl,
    organizationUsersUrl, rolesUrl
} from "../../_constants";
import {useStoreState} from "easy-peasy";
import InfiniteScroll from "react-infinite-scroller";

export default function Messaging(){

    const [currentPage,setCurrentPage] = useState(1);
    const [totalPages,setTotalPages] = useState(1);
    const [isScrollLoading,setIsScrollLoading] = useState(false);

    const [filterOptions,setFilterOptions] = useState([{label:'Sent',value:1,action:'sent'},{label:'Draft',value:0,action:'sent'}]);
    const [selectedFilters,setSelectedFilters] = useState([]);
    const [users,setUsers] = useState([]);
    const [messages,setMessages] = useState([]);
    const [searchTerm,setSearchTerm] = useState('');
    const [showPopup,setShowPopup] = useState(false);
    const [selectedMessage,setSelectedMessage] = useState(null);
    const [loading,setLoading] = useState(true);
    const activeOrganization = useStoreState(state => state.user.activeOrganization);
    const [roles,setRoles] = useState([]);

    useEffect(()=>{
        let cancelToken = axios.CancelToken.source();
        axios.get(organizationAnnouncementsUrl(activeOrganization['organization_id']),{cancelToken:cancelToken.token}).then(response=>{
            setMessages(response.data.announcements);
            setCurrentPage(response.data.meta.page);
            setTotalPages(response.data.meta.pages);
            setLoading(false);
        }).catch(e=>{});

        axios.get(organizationUsersUrl(activeOrganization['organization_id']) + '?roles[]=employer-admin&roles[]=hr&roles[]=employee&roles[]=employer-contact',{cancelToken:cancelToken.token}).then(response=>{
            setUsers(response.data.users);
        }).catch(e=>{});

        axios.get(rolesUrl,{cancelToken:cancelToken.token}).then(response=>{
            setRoles(response.data.roles);
        }).catch(e=>{});
        return ()=>{
            cancelToken.cancel('');
        }
    },[]);

    const viewMessage = (message) =>{
        setSelectedMessage(message);
        setShowPopup(true);
    };

    const newMessage = () =>{
        setSelectedMessage(null);
        setShowPopup(true);
    };

    const clearSearchAndFilters = () =>{
        setSearchTerm('');
        setSelectedFilters([]);
        setFilterOptions(filterOptions.map(filter=>{
            filter.selected = false;
            return filter;
        }))
    };

    const scrollLoad = (page) =>{
        setIsScrollLoading(true);
        let queryString = '?page='+page;
        if(selectedFilters.length===1){
            queryString = '&sent='+selectedFilters[0].value;
        }
        if(searchTerm.trim().length>0){
            queryString += '&search=' + searchTerm.trim();
        }
        setCurrentPage(page);
        axios.get(organizationAnnouncementsUrl(activeOrganization['organization_id']) + queryString).then(response=>{
            setMessages([...messages,...response.data.announcements]);
            setLoading(false);

            setTotalPages(response.data.meta.pages);
            setTimeout(()=>{setIsScrollLoading(false);},0)
        }).catch(e=>{});
    }

    useEffect(()=>{
        if(loading){
            return;
        }
        let queryString = '';
        if(selectedFilters.length===1){
            queryString = '?sent='+selectedFilters[0].value;
        }
        if(searchTerm.trim().length>0){
            queryString += queryString.length>0?'&':'?';
            queryString += 'search=' + searchTerm.trim();
        }
        let cancelToken = axios.CancelToken.source();
        axios.get(organizationAnnouncementsUrl(activeOrganization['organization_id'])+queryString,{cancelToken:cancelToken.token}).then(response=>{
            setMessages(response.data.announcements);
            setCurrentPage(response.data.meta.page);
            setTotalPages(response.data.meta.pages);
            setLoading(false);
        }).catch(e=>{});
        return ()=>{
            cancelToken.cancel('');
        }


    },[searchTerm,selectedFilters]);

    const closePopup = (response)=>{
        if(response && !loading){
            let queryString = '';
            if(selectedFilters.length===1){
                queryString = '?sent='+selectedFilters[0].value;
            }
            if(searchTerm.trim().length>0){
                queryString += queryString.length>0?'&':'?';
                queryString += 'search=' + searchTerm.trim();
            }
            axios.get(organizationAnnouncementsUrl(activeOrganization['organization_id'])+queryString).then(response=>{
                setMessages(response.data.announcements);
                setLoading(false);
            }).catch(e=>{});
        }
        setShowPopup(false);
    };


    return (
        <>
            {showPopup &&
                <MessagePopup
                    message={selectedMessage}
                    roles={roles}
                    closeHandler={closePopup}
                    users={users}
                />
            }
            <div className={'full-width page'}>
                <div className={'header'}>
                    <div>
                        <h1 className={'heading'} >
                            Message Eligible Employees
                        </h1>
                        <p className={'sub-heading'}>
                            Send a message to the employees on your Eligibility File
                        </p>
                    </div>
                    <div className={'button-holder'}>
                        <button onClick={()=>{newMessage()}} className={'simple-rounded-button hollow white approved-only'}><span className={'add'} /><span>Create New Message</span></button>
                    </div>
                </div>
                <div className={'list-section'}>
                    <div className={'body'}>
                        <div className={'search-filter-container'}>
                            <input type={'text'} placeholder={'Search'} value={searchTerm} onChange={(e)=>{setSearchTerm(e.target.value)}} />
                            <FilterButton options={filterOptions} changeHandler={setSelectedFilters} />
                        </div>

                        <CSSTransition
                            in={loading}
                            timeout={{
                                appear: 500,
                                enter: 300,
                                exit: 0,
                            }}
                            classNames="fade"
                            unmountOnExit
                        >
                            <MessageListPlaceholder />
                        </CSSTransition>
                        <CSSTransition
                            in={!loading}
                            timeout={{
                                enter: 300,
                                exit: 0,
                            }}
                            classNames="fade"
                            unmountOnExit
                        >
                            <InfiniteScroll
                                pageStart={1}
                                loadMore={isScrollLoading?null:scrollLoad}
                                initialLoad={false}
                                hasMore={(currentPage<totalPages)}
                                loader={<div className="loader" key={0}>Loading ...</div>}
                            >
                                <MessageList messages={messages} viewHandler={viewMessage} clearHandler={clearSearchAndFilters} />
                            </InfiniteScroll>
                        </CSSTransition>
                    </div>
                </div>
            </div>
        </>
    )
}

import React from 'react'
import {useLocation} from "react-router";
import {Colors} from "../../_constants";

export default function UserListPlaceholder(){
    const location = useLocation();
    const users = [1,2,3];
    return (
        <div className={'user-list dashboard-list'}>
            {location.state.layout!=='mobileMax' &&
            <div className={'table-row file-row header'}>
                <span>Name</span>
                <span>Email</span>
                <span>Access</span>
                <span>Details</span>
            </div>
            }
            <div className={'placeholder-holder'}>
            {location.state.layout!=='mobileMax' && users.map((user,index)=>{
                return (
                    <div key={index} className={'table-row  file-row '}>
                        <span className={'placeholder'} />
                        <span className={'placeholder'} />
                        <span className={'placeholder'} />
                        <span className={'placeholder'} />
                    </div>
                )
            })}
            {(location.state.layout==='mobileMax')  && users.map((user,index)=>{
                return (
                    <div key={index} className={'table-row  file-row tablet'}>
                        <div>
                            <span className={'title'}>Name</span>
                            <span className={'placeholder'} />
                        </div>
                        <div>
                            <span className={'title'}>Email</span>
                            <span className={'placeholder'} />
                        </div>
                        <div>
                            <span className={'title'}>access</span>
                            <span className={'placeholder'} />
                        </div>
                        <div>
                            <span className={'placeholder'} />
                        </div>
                    </div>
                )
            })}
            </div>
        </div>
    )

}



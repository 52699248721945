import React from 'react';

import AuthHeader from "./authHeader";
import AuthFooter from "./authFooter";

export default function AuthPage({children}){

	return (
		<>
			<AuthHeader/>
			<div className={'full-width page auth'}>
				{children}
			</div>
			<AuthFooter/>
		</>
	)
}
